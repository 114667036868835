"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMerchantNames = exports.getYears = exports.checkBulkInsertTransactions = exports.bulkInsertTransactions = exports.bulkUpdateTransactions = exports.searchByString = exports.ungroupTransactionGroup = exports.updateTransactionGroup = exports.getTransactionGroup = exports.createTransactionGroup = exports.clearAllByMonth = exports.createFromRecurring = exports.unlinkRecurring = exports.linkRecurring = exports.deleteTransactions = exports.deleteTransaction = exports.updateTransaction = exports.ungroupTransactions = exports.unsplitTransactions = exports.splitTransaction = exports.getTransactionSplitDetails = exports.getTransactionsMultiple = exports.getTransactions = exports.createTransaction = exports.getTransactionsPage = void 0;
const common_1 = require("./common");
const getTransactionsPage = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)('snapshot/transactions_page', {});
});
exports.getTransactionsPage = getTransactionsPage;
/**
 *
 *
 */
const createTransaction = (body, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions`, { transaction: body, opts });
});
exports.createTransaction = createTransaction;
/**
 *
 *
 */
// Handle pagination in here
const getTransactions = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    const allTx = [];
    let offset = 0;
    let results = yield (0, common_1.get)(`transactions`, Object.assign(Object.assign({}, opts), { paginate: true }));
    allTx.push(...results.transactions);
    while (results.has_more) {
        offset += results.limit;
        results = yield (0, common_1.get)(`transactions`, Object.assign(Object.assign({}, opts), { paginate: true, offset }));
        allTx.push(...results.transactions);
    }
    return allTx;
});
exports.getTransactions = getTransactions;
const getTransactionsMultiple = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/multiple`, opts);
});
exports.getTransactionsMultiple = getTransactionsMultiple;
/**
 *
 *
 */
const getTransactionSplitDetails = (parent_id, opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`transactions/split/${parent_id}`, opts);
});
exports.getTransactionSplitDetails = getTransactionSplitDetails;
/**
 *
 *
 */
const splitTransaction = (transaction_id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/split/${transaction_id}`, body);
});
exports.splitTransaction = splitTransaction;
/**
 *
 *
 */
const unsplitTransactions = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/split/bulk_unsplit`, body);
});
exports.unsplitTransactions = unsplitTransactions;
/**
 *
 *
 */
const ungroupTransactions = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/group/bulk_ungroup`, body);
});
exports.ungroupTransactions = ungroupTransactions;
/**
 *
 *
 */
const updateTransaction = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/${id}`, body);
});
exports.updateTransaction = updateTransaction;
/**
 *
 *
 */
const deleteTransaction = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`transactions/${id}`, body);
});
exports.deleteTransaction = deleteTransaction;
/**
 *
 *
 */
const deleteTransactions = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/bulk_delete`, body);
});
exports.deleteTransactions = deleteTransactions;
/**
 *
 *
 */
const linkRecurring = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/${opts.id}/recurring/${opts.recurring_id}`, {});
});
exports.linkRecurring = linkRecurring;
/**
 *
 *
 */
const unlinkRecurring = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`transactions/${opts.id}/recurring`, {
        account_id: opts.account_id,
    });
});
exports.unlinkRecurring = unlinkRecurring;
/**
 *
 *
 */
const createFromRecurring = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/recurring`, opts);
});
exports.createFromRecurring = createFromRecurring;
/**
 *
 *
 */
const clearAllByMonth = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/clear`, opts);
});
exports.clearAllByMonth = clearAllByMonth;
/**
 *
 *
 */
const createTransactionGroup = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/group`, opts);
});
exports.createTransactionGroup = createTransactionGroup;
/**
 *
 *
 */
const getTransactionGroup = (transaction_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`transactions/group/${transaction_id}`, {});
});
exports.getTransactionGroup = getTransactionGroup;
/**
 *
 *
 */
const updateTransactionGroup = (transaction_id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/group/${transaction_id}`, body);
});
exports.updateTransactionGroup = updateTransactionGroup;
/**
 *
 *
 */
const ungroupTransactionGroup = (transaction_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`transactions/group/${transaction_id}`, {});
});
exports.ungroupTransactionGroup = ungroupTransactionGroup;
/**
 *
 *
 */
const searchByString = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/search/string`, opts);
});
exports.searchByString = searchByString;
/**
 *
 *
 */
const bulkUpdateTransactions = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/bulk_update`, opts);
});
exports.bulkUpdateTransactions = bulkUpdateTransactions;
/**
 *
 *
 */
const bulkInsertTransactions = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/bulk_insert`, opts);
});
exports.bulkInsertTransactions = bulkInsertTransactions;
/**
 *
 *
 */
const checkBulkInsertTransactions = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/bulk_insert/check`, opts);
});
exports.checkBulkInsertTransactions = checkBulkInsertTransactions;
/**
 *
 *
 */
const getYears = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`transactions/years`, {});
});
exports.getYears = getYears;
const getMerchantNames = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (opts = {}) {
    return yield (0, common_1.get)('transactions/merchant_names', opts);
});
exports.getMerchantNames = getMerchantNames;
