"use strict";
/**
 *  EditCustomBudget.tsx
 *  Custom budgets add modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const custom_budgets_1 = require("@/actions/custom_budgets");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const user_1 = require("@/actions/user");
const CustomPeriod_1 = require("./CustomPeriod");
const RolloverStartDate_1 = require("./RolloverStartDate");
const EditCustomBudget = ({ useModal, utils, data, setVisibility, switchView, setDisableOnClickOutside, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    const [budgetPeriod, setBudgetPeriod] = (0, react_1.useState)(_user.budgetPeriodSettings.quantity == 1 &&
        _user.budgetPeriodSettings.granularity == 'month' &&
        _user.budgetPeriodSettings.anchor_date == '2024-01-01'
        ? 'default'
        : 'custom'); // or custom
    const [budgetCadenceQuantity, setBudgetCadenceQuantity] = (0, react_1.useState)(_user.budgetPeriodSettings.quantity);
    const [budgetCadenceGranularity, setBudgetCadenceGranularity] = (0, react_1.useState)(_user.budgetPeriodSettings.granularity);
    const [budgetAnchorDate, setBudgetAnchorDate] = (0, react_1.useState)(_user.budgetPeriodSettings.anchor_date);
    const [budgetIncomeOption, setBudgetIncomeOption] = (0, react_1.useState)(_user.budgetPeriodSettings.budget_income_option);
    const [budgetHideNoActivity, setBudgetHideNoActivity] = (0, react_1.useState)(_user.budgetPeriodSettings.hide_no_activity);
    const [showConfirmDeleteHistory, setShowConfirmDeleteHistory] = (0, react_1.useState)(false);
    const [budgetRolloverStartDate, setBudgetRolloverStartDate] = (0, react_1.useState)(_user.budgetPeriodSettings.budget_rollover_start_date);
    const [isError, setIsError] = (0, react_1.useState)(false);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setButtonIsLoading(true);
        const categoryUpdateObj = {};
        // Did budget period change?
        if (budgetCadenceQuantity !== _user.budgetPeriodSettings.quantity ||
            budgetCadenceGranularity !== _user.budgetPeriodSettings.granularity ||
            budgetAnchorDate !== _user.budgetPeriodSettings.anchor_date) {
            // Align all categories
            categoryUpdateObj['quantityValue'] = budgetCadenceQuantity;
            categoryUpdateObj['granularityValue'] = budgetCadenceGranularity;
            categoryUpdateObj['anchorDate'] = budgetAnchorDate;
        }
        if (!!budgetRolloverStartDate) {
            // Synced to all, so let's update the individual category rollover start date for good measure
            categoryUpdateObj['rollover_start_date'] = budgetRolloverStartDate;
        }
        if (Object.keys(categoryUpdateObj).length > 0) {
            yield (0, custom_budgets_1.createCustomBudget)([
                ..._categories.flattened
                    .filter(o => !o.exclude_from_budget)
                    .map(o => {
                    return Object.assign({ filterType: 'category', filterValue: o.id }, categoryUpdateObj);
                }),
            ]);
        }
        // Update budget settings
        yield (0, user_1.updateAccountPreferences)({
            budget_period_quantity: budgetCadenceQuantity,
            budget_period_granularity: budgetCadenceGranularity,
            budget_period_anchor_date: budgetAnchorDate,
            budget_income_option: budgetIncomeOption,
            budget_hide_no_activity: budgetHideNoActivity,
            budget_rollover_start_date: budgetRolloverStartDate,
        });
        yield _user.fetchAccountSettings();
        setButtonIsLoading(false);
        setVisibility(false);
        utils._showToast({
            message: 'Budget settings successfully updated!',
            type: 'success',
        });
    });
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "Edit Budget Settings"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, []);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", loading: buttonIsLoading, disabled: 
                    // Rollover start date
                    (isError && !!budgetRolloverStartDate) ||
                        !budgetCadenceQuantity ||
                        // Budget period changed
                        (_user.budgetPeriodSettings.granularity ==
                            budgetCadenceGranularity &&
                            _user.budgetPeriodSettings.quantity == budgetCadenceQuantity &&
                            _user.budgetPeriodSettings.anchor_date == budgetAnchorDate &&
                            _user.budgetPeriodSettings.budget_income_option ==
                                budgetIncomeOption &&
                            _user.budgetPeriodSettings.hide_no_activity ==
                                budgetHideNoActivity &&
                            _user.budgetPeriodSettings.budget_rollover_start_date ==
                                budgetRolloverStartDate), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        // Did budget period change?
                        if (budgetCadenceQuantity !== _user.budgetPeriodSettings.quantity ||
                            budgetCadenceGranularity !==
                                _user.budgetPeriodSettings.granularity ||
                            budgetAnchorDate !== _user.budgetPeriodSettings.anchor_date) {
                            setDisableOnClickOutside(true);
                            setShowConfirmDeleteHistory(true);
                            return;
                        }
                        submit();
                    }) }, "Save Changes"))),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [
        buttonIsLoading,
        budgetCadenceQuantity,
        budgetCadenceGranularity,
        budgetAnchorDate,
        budgetIncomeOption,
        budgetHideNoActivity,
        budgetRolloverStartDate,
        isError,
    ]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail mb-05rem" },
                React.createElement("h3", null, "Set Budget Period"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, disabled: false, selection: true, className: "category-dropdown p-category-modal", value: budgetPeriod, placeholder: "Select type", onChange: (e, { value }) => {
                                        setBudgetPeriod(value);
                                        if (value == 'default') {
                                            setBudgetCadenceQuantity(1);
                                            setBudgetCadenceGranularity('month');
                                            setBudgetAnchorDate('2024-01-01');
                                        }
                                    }, options: [
                                        {
                                            key: 'default',
                                            value: 'default',
                                            text: 'By calendar month (default)',
                                        },
                                        {
                                            key: 'custom',
                                            value: 'custom',
                                            text: 'By custom period',
                                        },
                                    ] })))),
                    budgetPeriod === 'custom' && (React.createElement(CustomPeriod_1.default, { budgetCadenceQuantity: budgetCadenceQuantity, setBudgetCadenceQuantity: setBudgetCadenceQuantity, budgetCadenceGranularity: budgetCadenceGranularity, setBudgetCadenceGranularity: setBudgetCadenceGranularity, budgetAnchorDate: budgetAnchorDate, setBudgetAnchorDate: setBudgetAnchorDate }))))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail mb-05rem" },
                React.createElement("h3", null, "Rollover Date Setting"),
                React.createElement("div", { className: "transaction-detail" },
                    isError && budgetRolloverStartDate && (React.createElement(semantic_ui_react_1.Message, { error: true }, "Your rollover start date is out of sync with your overall budget. Update your rollover start date to continue!")),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !!budgetRolloverStartDate, onChange: (e, { checked }) => {
                                        if (checked) {
                                            setBudgetRolloverStartDate(data.periodStart);
                                        }
                                        else {
                                            setBudgetRolloverStartDate(null);
                                        }
                                        setDisableOnClickOutside(true);
                                    }, label: 'Sync rollover start dates (recommended)' }),
                                !!budgetRolloverStartDate && (React.createElement(RolloverStartDate_1.default, { rolloverStartDate: budgetRolloverStartDate, setRolloverStartDate: setBudgetRolloverStartDate, anchorDateOverride: budgetAnchorDate, granularityOverride: budgetCadenceGranularity, quantityOverride: budgetCadenceQuantity, setIsError: setIsError })))))))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail mb-05rem" },
                React.createElement("h3", null, "Other Settings"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: budgetHideNoActivity, onChange: (e, { checked }) => {
                                        setDisableOnClickOutside(true);
                                        setBudgetHideNoActivity(checked);
                                    }, label: 'Hide categories with no activity and no budget' }))),
                        React.createElement("label", null, "Base income on"),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, disabled: false, selection: true, className: "category-dropdown p-category-modal", value: budgetIncomeOption, placeholder: "Select type", onChange: (e, { value }) => {
                                        setDisableOnClickOutside(true);
                                        setBudgetIncomeOption(value);
                                    }, options: [
                                        {
                                            key: 'budgeted',
                                            value: 'budgeted',
                                            text: 'Budgeted Income',
                                        },
                                        {
                                            key: 'actvitiy',
                                            value: 'actvitiy',
                                            text: 'Actual Income Activity',
                                        },
                                        {
                                            key: 'max',
                                            value: 'max',
                                            text: 'Larger of Budgeted and Actual Income Activity',
                                        },
                                    ] }))))))),
        showConfirmDeleteHistory && (React.createElement(semantic_ui_react_1.Modal, { open: !!showConfirmDeleteHistory, closeOnEscape: false, closeOnDocumentClick: false, closeOnDimmerClick: false, size: "mini" },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement("p", null,
                            "You are about to the change the period for your overall budget to every ",
                            budgetCadenceQuantity,
                            " ",
                            budgetCadenceGranularity,
                            budgetCadenceQuantity > 1 ? 's' : '',
                            "."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Upon changing the budgeting period, all historical budgeted values will be deleted!"))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, onClick: () => {
                        setShowConfirmDeleteHistory(false);
                    }, negative: true }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { loading: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setButtonIsLoading(true);
                        yield submit();
                        setButtonIsLoading(false);
                        setShowConfirmDeleteHistory(false);
                    }) }, "Confirm"))))));
};
exports.default = EditCustomBudget;
