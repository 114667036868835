"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const qs = require("query-string");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_1 = require("react-router-dom");
const _ = require("lodash");
const format_1 = require("@helpers/format");
const UserProvider_1 = require("@providers/UserProvider");
const support_1 = require("@actions/support");
const Moment = require("moment");
const EditableDate_1 = require("@components/elements/EditableDate");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const SelectLineItem_1 = require("../global/SelectLineItem");
const plaid_1 = require("@/actions/plaid");
var STEPS;
(function (STEPS) {
    STEPS[STEPS["SELECT_ACCOUNT_OR_CONNECTION"] = 1] = "SELECT_ACCOUNT_OR_CONNECTION";
    STEPS[STEPS["SELECT_ISSUE"] = 2] = "SELECT_ISSUE";
    STEPS[STEPS["MISSING_TRANSACTION"] = 3] = "MISSING_TRANSACTION";
    STEPS[STEPS["INCORRECT_TRANSACTION"] = 4] = "INCORRECT_TRANSACTION";
    STEPS[STEPS["INCORRECT_ACCOUNT"] = 5] = "INCORRECT_ACCOUNT";
    STEPS[STEPS["CONNECTION_ISSUE"] = 6] = "CONNECTION_ISSUE";
    STEPS[STEPS["ADDITIONAL_INFORMATION"] = 7] = "ADDITIONAL_INFORMATION";
    STEPS[STEPS["SELECT_SESSION"] = 8] = "SELECT_SESSION";
    STEPS[STEPS["CONFIRMATION"] = 9] = "CONFIRMATION";
})(STEPS || (STEPS = {}));
const SyncingSupportModal = ({}) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [show, setShow] = (0, react_1.useState)(null);
    const [showVerifyEmail, setShowVerifyEmail] = (0, react_1.useState)(false);
    //
    const [step, setStep] = (0, react_1.useState)(STEPS.SELECT_ACCOUNT_OR_CONNECTION);
    const [accountDropdownItems, setAccountDropdownItems] = (0, react_1.useState)([]);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [error, setError] = (0, react_1.useState)(null);
    // Step 1
    const [type, setType] = (0, react_1.useState)(null); // new or existing
    const [selectedAccount, setSelectedAccount] = (0, react_1.useState)(null);
    const [selectedTransaction, setSelectedTransaction] = (0, react_1.useState)(null);
    // Step 2
    const [linkErrors, setLinkErrors] = (0, react_1.useState)(null);
    const [session, setSession] = (0, react_1.useState)(null); // new
    const [issue, setIssue] = (0, react_1.useState)(null); // existing
    // Step 3
    const [connectionIssue, setConnectionIssue] = (0, react_1.useState)(null);
    const [accountIssue, setAccountIssue] = (0, react_1.useState)(null);
    // Further details
    const [details, setDetails] = (0, react_1.useState)('');
    const [date, setDate] = (0, react_1.useState)(null);
    const [payee, setPayee] = (0, react_1.useState)('');
    const [amount, setAmount] = (0, react_1.useState)('');
    const [startDate, setStartDate] = (0, react_1.useState)(null);
    const [endDate, setEndDate] = (0, react_1.useState)(null);
    const [files, setFiles] = (0, react_1.useState)([]);
    const onClose = () => {
        setType(null);
        setSelectedAccount(null);
        setSelectedTransaction(null);
        setIssue(null);
        setSession(null);
        setConnectionIssue(null);
        setAccountIssue(null);
        setDetails('');
        setDate(null);
        setPayee('');
        setAmount('');
        setStartDate(null);
        setEndDate(null);
        setFiles([]);
        setStep(STEPS.SELECT_ACCOUNT_OR_CONNECTION);
        const query = qs.parse(history.location.search);
        delete query.sync_support;
        delete query.sync_account;
        delete query.sync_transaction;
        history.replace({
            search: qs.stringify(query),
        });
        setShow(false);
        setShowVerifyEmail(false);
    };
    (0, react_1.useEffect)(() => {
        const parse = qs.parse(history.location.search);
        const SUPPORT_TRIGGERED = !!parse.sync_support || !!parse.sync_transaction;
        if (SUPPORT_TRIGGERED) {
            if (_user.verifiedEmail) {
                let _step = STEPS.SELECT_ACCOUNT_OR_CONNECTION;
                if (!!parse.sync_account) {
                    setSelectedAccount(_assets.plaidAccounts.find(o => o.id == Number(parse.sync_account)) || null);
                    setType('existing');
                    _step = STEPS.SELECT_ISSUE;
                }
                if (!!parse.sync_transaction) {
                    setSelectedTransaction(parse.sync_transaction);
                    _step = STEPS.INCORRECT_TRANSACTION;
                }
                setStep(_step);
                setShow(true);
            }
            else {
                setShowVerifyEmail(true);
            }
        }
    }, [history.location.search]);
    const getPlaidErrors = () => __awaiter(void 0, void 0, void 0, function* () {
        const errors = yield (0, plaid_1.getErrors)();
        setLinkErrors(errors === null || errors === void 0 ? void 0 : errors.filter(o => 
        // has an error and is a link action
        // (o.error_type || o.error_code || o.error_message) &&
        o.action == 'link'));
    });
    const IS_BETA = window.location.href.indexOf('beta.lunchmoney') >= 0;
    const SKIP_TRANSACTIONS_ENABLED = (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
        React.createElement("p", { className: "text" }, "Transactions are currently disabled for this account. To enable transactions, uncheck the 'Do not import transactions' option under 'Advanced Settings', and then 'Trigger Refresh' to fetch transactions.")));
    const TRANSACTIONS_NOT_SUPPORTED_FOR_INVESTMENTS = (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
        React.createElement(semantic_ui_react_1.Message.Header, null,
            React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle" }),
            " Transactions not supported for investment or loan accounts"),
        React.createElement("p", { className: "text" }, "Importing individual transactions for brokerage/investment/loan accounts is not yet supported. In most cases, we are able to get the latest balance for these accounts.")));
    const TRANSACTIONS_NOT_SUPPORTED_FOR_LOANS = (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
        React.createElement(semantic_ui_react_1.Message.Header, null,
            React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle" }),
            " Transactions not supported for loan accounts"),
        React.createElement("p", { className: "text" }, "Importing individual transactions for loan accounts is not yet supported. In most cases, we are able to get the latest balance for these accounts.")));
    const INVESTMENT_TRANSACTIONS_SUPPORTED_BUT_NOT_ENABLED = (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
        React.createElement(semantic_ui_react_1.Message.Header, null,
            React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle" }),
            " Investment transactions supported but not yet enabled"),
        React.createElement("p", { className: "text" }, "To enable investment transactions, click on the 'Enable Investment Transactions' button under 'Advanced Settings'.")));
    const INVESTMENT_TRANSACTIONS_NOT_SUPPORTED = (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
        React.createElement(semantic_ui_react_1.Message.Header, null,
            React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle" }),
            " Investment transactions not supported for this account"),
        React.createElement("p", { className: "text" }, "Investment transactions are not supported for this account under your current connection, but may be supported if you create a new connection. Make sure you select 'I want to sync an investment account' when linking a new account. You can then merge the new account with this one.")));
    const ACCOUNT_IN_RELINK_MODE = (React.createElement(semantic_ui_react_1.Message, { info: true },
        "This account is currently in ",
        React.createElement("b", null, "relink mode"),
        ". Please reconnect your account before continuing. If you're having trouble, select \"I am having trouble relinking my account\"."));
    const USER_PRESENT_REQUIRED = (React.createElement(semantic_ui_react_1.Message, { info: true },
        React.createElement("p", { className: "text" }, "Perpetual one-time passcode (POTP) is a setting that requires consumers of certain financial institutions to enter a one-time passcode at every login attempt."),
        React.createElement("p", { className: "text" },
            "You have POTP enabled for your accounts at",
            ' ', selectedAccount === null || selectedAccount === void 0 ? void 0 :
            selectedAccount.institution_name,
            ". You will need to manually trigger an update every time you wish to fetch fresh data."),
        ' ',
        React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                history.push(`/accounts`);
                onClose();
            } }, "Relink to fetch new data"),
        React.createElement(semantic_ui_react_1.Button, { className: "mt-05rem", fluid: true, basic: true, size: "small", color: "orange", onClick: () => {
                setStep(STEPS.MISSING_TRANSACTION);
            } }, "I am still missing transactions")));
    (0, react_1.useEffect)(() => {
        // Build options for Plaid Accounts
        const _options = [];
        const byConnection = _.groupBy(_assets.plaidAccounts.filter(o => o.status !== 'deactivated' && o.status !== 'deleted'), 'item_id');
        Object.keys(byConnection).map(accessToken => {
            const accounts = byConnection[accessToken];
            _options.push(React.createElement(semantic_ui_react_1.Dropdown.Header, { key: accessToken, content: `${accounts[0].institution_name} (${accounts.length} accounts)` }));
            accounts.map(account => {
                _options.push(React.createElement(semantic_ui_react_1.Dropdown.Item, { key: account.id, value: account, text: `${account.display_name} (${account.status})`, onClick: () => {
                        setSelectedAccount(account);
                    } }));
            });
        });
        setAccountDropdownItems(_options);
    }, []);
    const uploadForm = (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
        React.createElement(semantic_ui_react_1.Form.Field, null,
            React.createElement("label", null, "Upload files (optional, max 10 images, 2MB each)"),
            React.createElement(semantic_ui_react_1.Form.Input, { fluid: true, multiple: true, type: "file", name: "file", onChange: e => {
                    setError(null);
                    const _files = [...e.target.files];
                    if (_files.length > 10) {
                        setError(`You tried to upload ${_files.length} files! Please upload 10 or less files.`);
                        e.target.value = null;
                        return;
                    }
                    _files.forEach(file => {
                        if (file.size > 1000000) {
                            setError(`Please upload files that do not exceed 1MB!`);
                            e.target.value = null;
                            return;
                        }
                    });
                    setFiles(_files);
                } }),
            error && React.createElement("span", { className: "color--red mt-05rem" }, error))));
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Modal, { open: showVerifyEmail, onClose: () => {
                onClose();
            }, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Please verify your email address"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                "We use email to handle all support requests. In order to submit a bug or feedback, please verify your email address.",
                ' '),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowVerifyEmail(false);
                        history.push('/profile');
                    } }, "Go to profile"))),
        React.createElement(semantic_ui_react_1.Modal, { dimmer: "inverted", open: show, closeOnEscape: false, closeOnDimmerClick: false, size: 'tiny', onClose: () => {
                onClose();
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Bank syncing support",
                selectedAccount && step > STEPS.SELECT_ACCOUNT_OR_CONNECTION
                    ? `: ${selectedAccount.display_name}`
                    : ''),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                step == STEPS.SELECT_ACCOUNT_OR_CONNECTION && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Please select one of the following:"),
                            React.createElement(SelectLineItem_1.default, { keyValue: 'existing', selectedValue: type, disabled: accountDropdownItems.length == 0, displayOnSelect: React.createElement("div", { className: "mt-1rem" },
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "selection troubleshoot-dropdown", placeholder: "Select account", fluid: true, text: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.display_name, value: selectedAccount },
                                        React.createElement(semantic_ui_react_1.Dropdown.Menu, null, accountDropdownItems)),
                                    (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.status) == 'inactive' && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-05rem" },
                                        "This account is currently ",
                                        React.createElement("b", null, "inactive"),
                                        ". Please reactivate your account and allow it to re-sync with your bank before continuing."))), label: 'I am having trouble with an existing connection.', onClick: value => {
                                    setType(value);
                                } }),
                            React.createElement(SelectLineItem_1.default, { keyValue: 'new', selectedValue: type, label: 'I am having trouble with a new connection.', onClick: value => {
                                    setType(value);
                                } }))))),
                step == STEPS.SELECT_ISSUE && (React.createElement("div", null,
                    React.createElement("p", null,
                        "Please select the issue you are having with",
                        ' ', selectedAccount === null || selectedAccount === void 0 ? void 0 :
                        selectedAccount.display_name,
                        ":"),
                    [
                        {
                            value: 'missing-transactions',
                            text: 'I am missing transactions.',
                            displayOnSelect: ['investment', 'brokerage', 'loan'].indexOf(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) > -1 && !IS_BETA ? (TRANSACTIONS_NOT_SUPPORTED_FOR_INVESTMENTS) : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.skip_transactions) ? (SKIP_TRANSACTIONS_ENABLED) : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'loan' ? (TRANSACTIONS_NOT_SUPPORTED_FOR_LOANS) : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'investment' &&
                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.available_products) || '').indexOf('investments') > -1 &&
                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.billed_products) || '').indexOf('investments') == -1 ? (INVESTMENT_TRANSACTIONS_SUPPORTED_BUT_NOT_ENABLED) : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'investment' &&
                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.available_products) || '').indexOf('investments') == -1 &&
                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.billed_products) || '').indexOf('investments') == -1 ? (INVESTMENT_TRANSACTIONS_NOT_SUPPORTED) : selectedAccount.status == 'relink' ? (ACCOUNT_IN_RELINK_MODE) : selectedAccount.update_type ==
                                'user_present_required' ? (USER_PRESENT_REQUIRED) : (React.createElement(React.Fragment, null)),
                        },
                        {
                            value: 'incorrect-transaction',
                            text: 'My transaction data is incorrect.',
                            displayOnSelect: ['investment', 'brokerage', 'loan'].indexOf(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) > -1 && !IS_BETA
                                ? TRANSACTIONS_NOT_SUPPORTED_FOR_INVESTMENTS
                                : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.skip_transactions)
                                    ? SKIP_TRANSACTIONS_ENABLED
                                    : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'loan'
                                        ? TRANSACTIONS_NOT_SUPPORTED_FOR_LOANS
                                        : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'investment' &&
                                            ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.available_products) || '').indexOf('investments') > -1 &&
                                            ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.billed_products) || '').indexOf('investments') == -1
                                            ? INVESTMENT_TRANSACTIONS_SUPPORTED_BUT_NOT_ENABLED
                                            : (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'investment' &&
                                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.available_products) || '').indexOf('investments') == -1 &&
                                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.billed_products) || '').indexOf('investments') == -1
                                                ? INVESTMENT_TRANSACTIONS_NOT_SUPPORTED
                                                : selectedAccount.status == 'relink'
                                                    ? ACCOUNT_IN_RELINK_MODE
                                                    : null,
                        },
                        {
                            value: 'incorrect-account',
                            text: 'My account data (such as balance) is incorrect.',
                        },
                        {
                            value: 'connection',
                            text: 'I am having trouble with this connection.',
                        },
                        {
                            value: 'something-else',
                            text: 'Something else is wrong.',
                        },
                    ].map(option => (React.createElement(SelectLineItem_1.default, { keyValue: option.value, selectedValue: issue, displayOnSelect: option.displayOnSelect || null, label: option.text, onClick: value => {
                            setIssue(value);
                        } }))))),
                step == STEPS.MISSING_TRANSACTION && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-05rem" }, "Just missing one or two transactions?"),
                        React.createElement(semantic_ui_react_1.Message.Content, null, "You can now manually add transactions to synced accounts! To access this feature, enable \"Allow modifications to transactions\" under Advanced Settings.")),
                    React.createElement("p", null, "Please provide details of one sample missing posted transaction as you see it on your bank's website."),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Date of missing transaction"),
                            React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', firstValue: null, location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                    setDate(Moment(date).format('YYYY-MM-DD'));
                                }), maxDate: new Date() }))),
                    date &&
                        (Moment().diff(Moment(date), 'days') < 3 ? (React.createElement(semantic_ui_react_1.Message, { fluid: true, info: true, className: "mb-05rem" },
                            React.createElement("p", { className: "text" }, "This date is within the last 3 days. Please allow at least 1-3 business days for transactions to appear in your account before creating a support ticket."))) : (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Merchant of missing transaction"),
                                    React.createElement(semantic_ui_react_1.Form.Input, { className: "mt-05rem mb-05rem", value: payee, onChange: (e, { value }) => {
                                            setPayee(value);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Amount of missing transaction"),
                                    React.createElement(semantic_ui_react_1.Form.Input, { className: "mb-05rem", value: amount, onChange: (e, { value }) => {
                                            setAmount(value);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Message, null,
                                React.createElement("p", null,
                                    "Are you missing several transactions from",
                                    ' ',
                                    selectedAccount.display_name,
                                    "? There is no need to create multiple tickets\u2013 simply indicate the date range from which transactions are missing below."),
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "Specify date range for missing transactions (optional)"),
                                        React.createElement("div", { className: "display--flex" },
                                            React.createElement("div", { className: "mr-05rem" },
                                                React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', firstValue: null, placeholder: 'Start date', location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                                        setStartDate(Moment(date).format('YYYY-MM-DD'));
                                                    }) })),
                                            React.createElement("div", { className: "ml-05rem" },
                                                React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', firstValue: null, placeholder: 'End date', location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                                        setEndDate(Moment(date).format('YYYY-MM-DD'));
                                                    }) })))))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Provide any additional details (optional)"),
                                    React.createElement(semantic_ui_react_1.TextArea, { rows: 2, onChange: (e, { value }) => {
                                            setDetails(value);
                                        } })))))))),
                step == STEPS.INCORRECT_TRANSACTION && (React.createElement(semantic_ui_react_1.Form, null,
                    selectedTransaction ? (React.createElement("span", null,
                        "Use this form to report faulty data in the selected posted transaction (",
                        React.createElement("span", { className: "monospace" }, selectedTransaction),
                        "), such as incorrect amount, incorrect notation or incorrect merchant name.")) : (React.createElement("span", null, "Use this form to report any faulty data in posted transactions, such as incorrect amount, incorrect notation or incorrect merchant name. Please reference specific transactions where possible.")),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.TextArea, { rows: 2, placeholder: 'Please describe the issue with this transaction', autoFocus: true, onChange: (e, { value }) => {
                                    setDetails(value);
                                } }))),
                    React.createElement("p", null, "If applicable, please upload screenshots from your bank statement showing the correct transaction details."),
                    uploadForm)),
                step == STEPS.INCORRECT_ACCOUNT && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Which element of the account is incorrect?"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "troubleshoot-dropdown", placeholder: "Select an element", fluid: true, selection: true, value: accountIssue, onChange: (e, { value }) => {
                                    setAccountIssue(value);
                                }, options: ['balance', 'type', 'subtype', 'name'].map(o => {
                                    return {
                                        key: o,
                                        value: o,
                                        text: (0, format_1.capitalize)(o),
                                    };
                                }) }))),
                    accountIssue == 'balance' &&
                        Moment().diff(Moment(selectedAccount.balance_last_update), 'days') < 3 && (React.createElement(semantic_ui_react_1.Message, { fluid: true, info: true, className: "mb-05rem" },
                        React.createElement("p", { className: "text" },
                            "Your balance was last updated",
                            ' ',
                            Moment(selectedAccount.balance_last_update).fromNow(),
                            " on",
                            ' ',
                            Moment(selectedAccount.balance_last_update).format(_user.getFormat('month_day_year')),
                            "."),
                        React.createElement("p", null, "Please allow at least 1-3 business day for account balances to update to the correct amount."))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mt-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "What should the correct value be? Please provide additional details if possible."),
                            React.createElement(semantic_ui_react_1.TextArea, { rows: 2, onChange: (e, { value }) => {
                                    setDetails(value);
                                } }))))),
                step == STEPS.ADDITIONAL_INFORMATION && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Please provide a brief description of the issue you are having."),
                            React.createElement(semantic_ui_react_1.TextArea, { rows: 2, autoFocus: true, onChange: (e, { value }) => {
                                    setDetails(value);
                                } }))))),
                step == STEPS.CONNECTION_ISSUE && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Select a connection issue"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "troubleshoot-dropdown", placeholder: "Select a connection issue", fluid: true, selection: true, value: connectionIssue, onChange: (e, { value }) => {
                                    setConnectionIssue(value);
                                }, options: [
                                    {
                                        key: 'mfa',
                                        value: 'Multi-factor authentication issues',
                                        text: 'Multi-factor authentication issues',
                                    },
                                    {
                                        key: 'credential',
                                        value: 'Invalid credential errors',
                                        text: 'Invalid credential errors',
                                    },
                                    {
                                        key: 'server',
                                        value: 'Persistent institution or server errors',
                                        text: 'Persistent institution or server errors',
                                    },
                                    {
                                        key: 'oauth',
                                        value: 'OAuth institution linking issues',
                                        text: 'OAuth institution linking issues',
                                    },
                                ] }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Description of the issue"),
                            React.createElement(semantic_ui_react_1.TextArea, { placeholder: 'Please provide additional details.', rows: 3, onChange: (e, { value }) => {
                                    setDetails(value);
                                } }))),
                    uploadForm)),
                step == STEPS.SELECT_SESSION &&
                    (linkErrors == null ? (React.createElement("p", null,
                        ' ',
                        React.createElement(semantic_ui_react_1.Icon, { name: "spinner", loading: true }),
                        "Please wait while we load Plaid error logs...")) : (linkErrors === null || linkErrors === void 0 ? void 0 : linkErrors.length) == 0 ? (React.createElement(semantic_ui_react_1.Message, { info: true },
                        "We couldn't locate logs for any connection attempts that resulted in an error within the last 5 days. Please attempt to reconnect once more as we need to be able to reference a session in order to investigate the issue.",
                        ' ')) : (React.createElement(React.Fragment, null,
                        React.createElement("p", null, "Select the associated recent connection attempt to be investigated further:"), linkErrors === null || linkErrors === void 0 ? void 0 :
                        linkErrors.map(error => (React.createElement(SelectLineItem_1.default, { keyValue: error.link_session_id, selectedValue: session === null || session === void 0 ? void 0 : session.link_session_id, label: `${Moment(error.date).format(_user.getFormat('month_day_year') + ' h:mm a')}: ${(0, format_1.capitalize)(error.action)} to ${error.institution_name}${error.error_code ? ` (${error.error_code})` : ''}`, displayOnSelect: React.createElement(semantic_ui_react_1.Message, null,
                                !!error.error_message && (React.createElement("p", { className: "text" },
                                    "The following error message was returned during this",
                                    ' ',
                                    error.action,
                                    " session: ",
                                    error.error_message)),
                                React.createElement("p", { className: "text" },
                                    "The following events were recorded during this",
                                    ' ',
                                    error.action,
                                    " session:"),
                                React.createElement("ol", null, error.events.map((event, index) => {
                                    return React.createElement("li", { key: index }, event.event);
                                }))), onClick: value => {
                                setSession({
                                    link_session_id: value,
                                    institution_name: error.institution_name,
                                    institution_external_id: error.institution_external_id,
                                });
                            } })))))),
                step == STEPS.CONFIRMATION && (React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-1rem" }, "Your request for support was successfully submitted!"),
                    React.createElement(semantic_ui_react_1.Message.Content, null, "We will follow up within a few days via email.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
                React.createElement("div", null, step !== STEPS.CONFIRMATION && (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, onClick: () => {
                        onClose();
                    }, negative: true, basic: true }, "Cancel"))),
                React.createElement("div", null,
                    step == STEPS.CONFIRMATION && (React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                            onClose();
                        } }, "Close")),
                    !selectedTransaction &&
                        step !== STEPS.CONFIRMATION &&
                        ((step > STEPS.SELECT_ACCOUNT_OR_CONNECTION &&
                            step !== STEPS.SELECT_ISSUE) ||
                            (step == STEPS.SELECT_ISSUE &&
                                !qs.parse(history.location.search).sync_account)) && (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            if (step == STEPS.SELECT_ISSUE) {
                                setStep(STEPS.SELECT_ACCOUNT_OR_CONNECTION);
                            }
                            else if (step == STEPS.MISSING_TRANSACTION ||
                                step == STEPS.INCORRECT_ACCOUNT ||
                                (!selectedTransaction &&
                                    step == STEPS.INCORRECT_TRANSACTION) ||
                                step == STEPS.ADDITIONAL_INFORMATION) {
                                setStep(STEPS.SELECT_ISSUE);
                            }
                            else if (step == STEPS.SELECT_SESSION) {
                                setStep(STEPS.SELECT_ACCOUNT_OR_CONNECTION);
                            }
                            else if (step == STEPS.CONNECTION_ISSUE) {
                                if (type == 'existing') {
                                    setStep(STEPS.SELECT_ISSUE);
                                }
                                else {
                                    setStep(STEPS.SELECT_SESSION);
                                }
                            }
                        }), loading: isLoading, disabled: false, labelPosition: "left", icon: "angle left", content: "Back" })),
                    step !== STEPS.CONFIRMATION && (React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            if (step == STEPS.SELECT_ACCOUNT_OR_CONNECTION) {
                                if (type == 'new') {
                                    getPlaidErrors();
                                    setStep(STEPS.SELECT_SESSION);
                                }
                                else {
                                    setStep(STEPS.SELECT_ISSUE);
                                }
                            }
                            else if (step == STEPS.SELECT_ISSUE &&
                                issue == 'missing-transactions') {
                                setStep(STEPS.MISSING_TRANSACTION);
                            }
                            else if (step == STEPS.SELECT_ISSUE &&
                                issue == 'incorrect-transaction') {
                                setStep(STEPS.INCORRECT_TRANSACTION);
                            }
                            else if (step == STEPS.SELECT_ISSUE &&
                                issue == 'incorrect-account') {
                                setStep(STEPS.INCORRECT_ACCOUNT);
                            }
                            else if (step == STEPS.SELECT_ISSUE &&
                                issue == 'connection') {
                                setStep(STEPS.CONNECTION_ISSUE);
                            }
                            else if (step == STEPS.SELECT_ISSUE &&
                                issue == 'something-else') {
                                setStep(STEPS.ADDITIONAL_INFORMATION);
                            }
                            else if (step == STEPS.SELECT_SESSION) {
                                setStep(STEPS.CONNECTION_ISSUE);
                            }
                            else {
                                setIsLoading(true);
                                // We are submitting
                                const data = new FormData();
                                // Issue name
                                data.append('issue', issue == 'connection' || type == 'new'
                                    ? 'Authentication and connectivity'
                                    : issue == 'missing-transactions'
                                        ? 'Missing data'
                                        : issue == 'incorrect-transaction' ||
                                            issue == 'incorrect-account'
                                            ? 'Faulty data'
                                            : 'Bank syncing');
                                // Identifiers
                                if (step == STEPS.MISSING_TRANSACTION ||
                                    step == STEPS.INCORRECT_TRANSACTION ||
                                    step == STEPS.INCORRECT_ACCOUNT ||
                                    (step == STEPS.CONNECTION_ISSUE && type == 'existing') ||
                                    step == STEPS.ADDITIONAL_INFORMATION) {
                                    data.append('plaid_account_id', selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id);
                                    if (selectedTransaction) {
                                        data.append('transaction_id', selectedTransaction);
                                    }
                                    if (step == STEPS.CONNECTION_ISSUE &&
                                        type == 'existing') {
                                        data.append('identifier_type', 'access_token');
                                        data.append('identifier', selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.access_token);
                                    }
                                    else {
                                        data.append('identifier_type', 'account_id');
                                        data.append('identifier', selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.external_id);
                                    }
                                    data.append('institution_name', selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.institution_name);
                                    data.append('institution_external_id', selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.institution_external_id);
                                    if (step == STEPS.INCORRECT_ACCOUNT) {
                                        data.append('account_issue', accountIssue);
                                    }
                                }
                                else if (step == STEPS.CONNECTION_ISSUE &&
                                    type == 'new') {
                                    data.append('identifier_type', 'link_session_id');
                                    data.append('identifier', session.link_session_id);
                                    data.append('institution_name', session.institution_name);
                                    data.append('institution_external_id', session.institution_external_id);
                                }
                                if (step == STEPS.MISSING_TRANSACTION) {
                                    data.append('issue_type', 'Missing transaction data');
                                    data.append('date', date);
                                    data.append('payee', payee);
                                    data.append('amount', amount);
                                    if (startDate) {
                                        data.append('start_date', startDate);
                                    }
                                    if (endDate) {
                                        data.append('end_date', endDate);
                                    }
                                    data.append('details', details);
                                }
                                else if (step == STEPS.CONNECTION_ISSUE) {
                                    data.append('issue_type', connectionIssue);
                                    data.append('details', details);
                                }
                                else if (step == STEPS.INCORRECT_TRANSACTION) {
                                    data.append('issue_type', 'Incorrect transaction data');
                                    data.append('details', details);
                                }
                                else if (step == STEPS.INCORRECT_ACCOUNT) {
                                    data.append('issue_type', 'Incorrect account data');
                                    data.append('details', details);
                                }
                                else if (step == STEPS.ADDITIONAL_INFORMATION) {
                                    data.append('issue_type', 'Not specified');
                                    data.append('details', details);
                                }
                                if ((files === null || files === void 0 ? void 0 : files.length) > 0) {
                                    files.forEach(file => {
                                        data.append('file', file);
                                    });
                                }
                                data.append('url', window.location.href);
                                const results = yield (0, support_1.sendSyncingSupportEmail)(data);
                                if (!results.error) {
                                    setStep(STEPS.CONFIRMATION);
                                    setIsLoading(false);
                                }
                            }
                        }), loading: isLoading, disabled: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.status) == 'inactive' ||
                            //
                            (step == STEPS.SELECT_ACCOUNT_OR_CONNECTION &&
                                (type == null ||
                                    (type == 'existing' && selectedAccount == null))) ||
                            //
                            (step == STEPS.SELECT_ISSUE && issue == null) ||
                            //
                            (step == STEPS.SELECT_ISSUE &&
                                issue == 'missing-transactions' &&
                                ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'loan' ||
                                    (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.update_type) ==
                                        'user_present_required')) ||
                            //
                            (step == STEPS.SELECT_ISSUE &&
                                issue == 'incorrect-transaction' &&
                                (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.type) == 'loan') ||
                            //
                            (step == STEPS.SELECT_SESSION && session == null) ||
                            //
                            (step == STEPS.INCORRECT_TRANSACTION &&
                                (details === null || details === void 0 ? void 0 : details.length) == 0) ||
                            //
                            (step == STEPS.INCORRECT_ACCOUNT &&
                                (accountIssue == null || (details === null || details === void 0 ? void 0 : details.length) == 0)) ||
                            //
                            (step == STEPS.ADDITIONAL_INFORMATION &&
                                (details === null || details === void 0 ? void 0 : details.length) == 0) ||
                            //
                            (step == STEPS.MISSING_TRANSACTION &&
                                ((date === null || date === void 0 ? void 0 : date.length) == null ||
                                    (payee === null || payee === void 0 ? void 0 : payee.length) == 0 ||
                                    (amount === null || amount === void 0 ? void 0 : amount.length) == 0)) ||
                            //
                            (step == STEPS.CONNECTION_ISSUE &&
                                (connectionIssue == null || (details === null || details === void 0 ? void 0 : details.length) == 0)), labelPosition: "right", icon: step == STEPS.CONNECTION_ISSUE ||
                            step == STEPS.ADDITIONAL_INFORMATION ||
                            step == STEPS.INCORRECT_TRANSACTION ||
                            step == STEPS.INCORRECT_ACCOUNT ||
                            step == STEPS.MISSING_TRANSACTION
                            ? 'checkmark'
                            : 'angle right', content: step == STEPS.CONNECTION_ISSUE ||
                            step == STEPS.ADDITIONAL_INFORMATION ||
                            step == STEPS.INCORRECT_TRANSACTION ||
                            step == STEPS.INCORRECT_ACCOUNT ||
                            step == STEPS.MISSING_TRANSACTION
                            ? 'Submit'
                            : 'Next' })))))));
};
exports.default = SyncingSupportModal;
