"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const Moment = require("moment");
const qs = require("query-string");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const Papa = require("papaparse");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const EditableCurrency_1 = require("@components/elements/EditableCurrency");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const assets_1 = require("@/actions/assets");
const react_drag_drop_files_1 = require("react-drag-drop-files");
const transaction_file_1 = require("@/actions/transaction_file");
const Upload = ({ setCurrentConfig, setAccount, _showToast, rawData, setRawData, goNext, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [uploadedFiles, setUploadedFiles] = (0, react_1.useState)([]);
    const [savedConfig, setSavedConfig] = (0, react_1.useState)(null);
    const [matchedConfig, setMatchedConfig] = (0, react_1.useState)(null);
    const [useMatchedConfig, setUseMatchedConfig] = (0, react_1.useState)(false);
    const [selection, setSelection] = (0, react_1.useState)(null);
    const [multiple, setMultiple] = (0, react_1.useState)(false);
    const [buttonLoading, setButtonLoading] = (0, react_1.useState)(false);
    const [showSettings, setShowSettings] = (0, react_1.useState)(false);
    const [importConfigs, setImportConfigs] = (0, react_1.useState)([]);
    const [selectedAccount, setSelectedAccount] = (0, react_1.useState)(null);
    const [showParseError, setShowParseError] = (0, react_1.useState)(null);
    const [showParseWarnings, setShowParseWarnings] = (0, react_1.useState)(null);
    const [viewRawData, setViewRawData] = (0, react_1.useState)(false);
    const [waitingForPDFs, setWaitingForPDFs] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setAccount(selectedAccount);
    }, [selectedAccount]);
    (0, react_1.useEffect)(() => {
        setCurrentConfig(matchedConfig);
    }, [matchedConfig]);
    const resetMatchedConfig = (override = {}) => {
        setMatchedConfig(Object.assign({ _currency: _user.primaryCurrency, _skip_duplicates: true, _apply_category_rules: true, _set_reviewed: true }, override));
    };
    // First, fetch the existing import configs
    (0, react_1.useEffect)(() => {
        const fetchConfigs = () => __awaiter(void 0, void 0, void 0, function* () {
            const results = yield (0, assets_1.getImportConfigs)();
            setImportConfigs(results);
        });
        fetchConfigs();
        resetMatchedConfig();
    }, []);
    // Next, see if there are any pre-selected accounts
    (0, react_1.useEffect)(() => {
        const parse = qs.parse(history.location.search);
        if (parse.asset_id) {
            handleAccountSelection({
                id: parseInt(parse.asset_id),
                source: 'manual',
            });
            setSelection('new');
        }
        else if (parse.plaid_account_id) {
            handleAccountSelection({
                id: parseInt(parse.plaid_account_id),
                source: 'plaid',
            });
            setSelection('new');
        }
    }, [importConfigs]);
    const handleAccountSelection = value => {
        setUploadedFiles([]);
        let _currency = _user.primaryCurrency;
        if (value.source == 'plaid') {
            const account = _assets.getPlaidAccount(value.id);
            _currency = account.currency_override || account.currency;
        }
        else if (value.source == 'manual') {
            const asset = _assets.getAsset(value.id);
            _currency = asset.currency;
        }
        // Does it have a saved config?
        const _hasSavedConfig = importConfigs.find(o => {
            return ((value.source == 'manual' && o.asset_id == value.id) ||
                (value.source == 'plaid' && o.plaid_account_id == value.id));
        });
        if (_hasSavedConfig) {
            setSavedConfig(_hasSavedConfig);
            setMatchedConfig(Object.assign({ id: _hasSavedConfig.id, _currency }, _hasSavedConfig));
            setUseMatchedConfig(true);
        }
        else {
            resetMatchedConfig({ _currency });
            setUseMatchedConfig(false);
        }
        setMultiple(false);
        setSelectedAccount(value);
    };
    (0, react_1.useEffect)(() => {
        if (!uploadedFiles || uploadedFiles.length == 0) {
            setRawData(null);
            setShowParseError(null);
            setShowParseWarnings(null);
            return;
        }
        if (uploadedFiles.length > 20) {
            setShowParseError(`Please upload less than 20 files. You currently have ${uploadedFiles.length} files selected.`);
            return;
        }
        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            if (file.size > 3000000) {
                setShowParseError(`Please upload a file that doesn't exceed 3Mb. ${uploadedFiles.length > 1 ? 'One of your files' : 'Your file size'} is currently ${(file.size / 1000).toFixed(0)}kb.`);
                return;
            }
        }
        if (uploadedFiles.length > 0) {
            _process();
        }
    }, [uploadedFiles, useMatchedConfig]);
    const _process = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (processPdfs = false) {
        setShowParseWarnings(null);
        setShowParseError(null);
        const results = yield processFiles(processPdfs);
        setButtonLoading(false);
        if (results === null || results === void 0 ? void 0 : results.error) {
            setShowParseError(results.error);
            return false;
        }
        else if (results === null || results === void 0 ? void 0 : results.warnings) {
            setShowParseWarnings(results.warnings);
            return false;
        }
        else {
            setShowParseWarnings(null);
            setShowParseError(null);
            return true;
        }
    });
    const processFiles = (...args_2) => __awaiter(void 0, [...args_2], void 0, function* (processPdfs = false) {
        var _a;
        let allData = [];
        const warnings = [];
        /**
         * Initial parsing of the file
         */
        const pdfs = uploadedFiles.filter(file => {
            return file.type == 'application/pdf';
        });
        const csvs = uploadedFiles.filter(file => {
            return file.type == 'text/csv' || file.type == 'application/vnd.ms-excel';
        });
        if (pdfs.length > 0 && csvs.length > 0) {
            setButtonLoading(false);
            return {
                error: 'You can only either upload CSVs or PDFs because all files must be in the same format.',
            };
        }
        if (pdfs.length == 0 && csvs.length == 0) {
            // No files
            return;
        }
        if (pdfs.length > 0) {
            if (!processPdfs)
                return;
            setButtonLoading(true);
            /**
             * PROCESS PDFS SERVER-SIDE
             */
            const data = new FormData();
            uploadedFiles.forEach(file => {
                data.append('file', file);
            });
            let processed = false;
            let results = null;
            results = (_a = (yield (0, transaction_file_1.uploadPDF)(data))) === null || _a === void 0 ? void 0 : _a.data;
            if (results.processing) {
                setWaitingForPDFs(true);
                while (!processed) {
                    // asynchronously wait 10 seconds and get status
                    yield new Promise(resolve => setTimeout(resolve, 10000));
                    const status = yield (0, transaction_file_1.getPDFStatus)({ uuids: results.uuids });
                    if (status === null || status === void 0 ? void 0 : status.data) {
                        setWaitingForPDFs(false);
                        results = status.data;
                        processed = true;
                    }
                }
            }
            // Return all of a user's files so we don't have to append it
            allData = results || [];
        }
        else if (csvs.length > 0) {
            setButtonLoading(true);
            /**
             * PROCESS CSVS CLIENT-SIDE
             */
            const allPromises = [];
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                allPromises.push(new Promise((resolve, reject) => {
                    Papa.parse(file, Object.assign(Object.assign(Object.assign({}, (!!(matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._delimiter)
                        ? { delimiter: matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._delimiter }
                        : {})), (!!(matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._encoding)
                        ? { encoding: (matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._encoding) || 'utf8' }
                        : {})), { skipEmptyLines: true, complete: function (results) {
                            resolve(results.data);
                        } }));
                }));
            }
            const results = yield Promise.all(allPromises);
            try {
                allData = _.union(...results);
            }
            catch (e) {
                setButtonLoading(false);
                return { error: e.message };
            }
        }
        if (allData.length == 0) {
            setButtonLoading(false);
            return {
                error: 'Error parsing your file(s). No data detected. For further assistance, please send your PDF to support@lunchmoney.app',
            };
        }
        // Quick check 1– does each results have at least 3 columns?
        if (allData.filter(row => {
            return row.length >= 3;
        }).length == 0) {
            return {
                error: 'Error parsing your file(s). We need to detect at least 3 columns.',
            };
        }
        // Quick check 2– do all the rows have the same number of columns?
        if (uploadedFiles.length > 1) {
            const firstLength = allData[0].length;
            if (allData.filter(o => {
                return o.length != firstLength;
            }).length > 0) {
                warnings.push('Your uploaded files do not appear to have the same number of columns. If you are uploading multiple CSVs, they must be in the same column format. Otherwise, the import tool will not work properly.');
            }
        }
        setRawData(allData.slice(0, 10000));
        try {
            attemptIndexing(allData.slice(0, 10000));
        }
        catch (e) {
            return { error: e.message };
        }
        if (allData.length > 10000) {
            warnings.push('We can only import up to 10,000 transactions at a time! You may continue to upload, but we will only process the first 10,000 rows.');
        }
        if ((warnings === null || warnings === void 0 ? void 0 : warnings.length) > 0) {
            return { warnings };
        }
        setButtonLoading(false);
        return null;
    });
    const attemptIndexing = data => {
        var _a, _b, _c, _d, _e, _f;
        const testForCorruption = (dateIndex, amountIndex = null, inflowIndex = null, outflowIndex = null) => {
            const corruptLinesDate = [];
            const corruptLinesAmount = [];
            data.forEach(datum => {
                if (!Moment(datum[dateIndex], (matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._date_format) || null).isValid()) {
                    corruptLinesDate.push(datum);
                }
                else if ((amountIndex !== null && isNaN((0, format_1.parseNumber)(datum[amountIndex]))) ||
                    (inflowIndex !== null &&
                        outflowIndex !== null &&
                        isNaN((0, format_1.parseNumber)(datum[inflowIndex])) &&
                        isNaN((0, format_1.parseNumber)(datum[outflowIndex])))) {
                    corruptLinesAmount.push(datum);
                }
            });
            if (corruptLinesDate.length == data.length) {
                throw new Error(`Error parsing your CSV file(s). The date is expected at column ${dateIndex} but we found invalid date data there.`);
            }
            else if (corruptLinesDate.length == data.length) {
                throw new Error(`Error parsing your CSV file(s). The amount is expected at column ${amountIndex ||
                    inflowIndex ||
                    outflowIndex} but we found invalid amount data there.`);
            }
        };
        if (data.length > 0) {
            if (selection == 'mint') {
                testForCorruption(0, 3);
                resetMatchedConfig({
                    _currency: matchedConfig._currency,
                    _indices: [
                        {
                            index: 0,
                            column: 'date',
                        },
                        { index: 1, column: 'payee' },
                        { index: 2, column: 'original_name' },
                        { index: 3, column: 'amount' },
                        { index: 4, column: 'type' },
                        { index: 5, column: 'category' },
                        { index: 6, column: 'account' },
                        { index: 7, column: 'tags' },
                        { index: 8, column: 'notes' },
                    ],
                });
            }
            else if (useMatchedConfig && (matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._indices)) {
                // Quick check to make sure the dates and amounts line up
                const dateIndex = (_a = matchedConfig._indices.find(o => o.column == 'date')) === null || _a === void 0 ? void 0 : _a.index;
                let amountIndex = (_b = matchedConfig._indices.find(o => o.column == 'amount')) === null || _b === void 0 ? void 0 : _b.index;
                const inflowIndex = (_c = matchedConfig._indices.find(o => o.column == 'inflow')) === null || _c === void 0 ? void 0 : _c.index;
                const outflowIndex = (_d = matchedConfig._indices.find(o => o.column == 'outflow')) === null || _d === void 0 ? void 0 : _d.index;
                if (typeof dateIndex == 'undefined') {
                    throw new Error('Error parsing your CSV file(s) according to your saved configuration. The date column appears to be missing.');
                }
                if (typeof amountIndex == 'undefined') {
                    amountIndex = null;
                    if (typeof inflowIndex == 'undefined' ||
                        typeof outflowIndex == 'undefined') {
                        throw new Error('Error parsing your CSV file(s) according to your saved configuration. The amount column appears to be missing.');
                    }
                }
                testForCorruption(dateIndex, amountIndex, inflowIndex, outflowIndex);
                // Matched Config is already set
            }
            else {
                // Attempt to set indices automatically
                const autoIndices = [];
                const header = (0, format_1.simplifyArr)(data[0]);
                // Getting the date
                if (header.indexOf('date') > -1) {
                    autoIndices.push({
                        index: header.indexOf('date'),
                        column: 'date',
                    });
                }
                // Getting the payee (or description)
                if (header.indexOf('payee') > -1) {
                    autoIndices.push({
                        index: header.indexOf('payee'),
                        column: 'payee',
                    });
                }
                else if (header.indexOf('description') > -1) {
                    autoIndices.push({
                        index: header.indexOf('description'),
                        column: 'payee',
                    });
                }
                // Getting the amount
                if (header.indexOf('debit/credit') > -1) {
                    autoIndices.push({
                        index: header.indexOf('debit/credit'),
                        column: 'type',
                    });
                    // Look for the amount column
                    const match = header.find(o => {
                        return (o === null || o === void 0 ? void 0 : o.toLowerCase().indexOf('amount')) > -1;
                    });
                    if (match) {
                        autoIndices.push({
                            index: header.indexOf(match),
                            column: 'amount',
                        });
                    }
                }
                else if (header.indexOf('amount') > -1) {
                    autoIndices.push({
                        index: header.indexOf('amount'),
                        column: 'amount',
                    });
                }
                if (header.indexOf('transaction type') > -1) {
                    autoIndices.push({
                        index: header.indexOf('transaction type'),
                        column: 'type',
                    });
                }
                else if (header.indexOf('amount type') > -1) {
                    autoIndices.push({
                        index: header.indexOf('amount type'),
                        column: 'type',
                    });
                }
                if (header.indexOf('debit') > -1 && header.indexOf('credit') > -1) {
                    autoIndices.push({
                        index: header.indexOf('debit'),
                        column: 'outflow',
                    });
                    autoIndices.push({
                        index: header.indexOf('credit'),
                        column: 'inflow',
                    });
                }
                else if (header.indexOf('inflow') > -1 &&
                    header.indexOf('outflow') > -1) {
                    autoIndices.push({
                        index: header.indexOf('outflow'),
                        column: 'outflow',
                    });
                    autoIndices.push({
                        index: header.indexOf('inflow'),
                        column: 'inflow',
                    });
                }
                // Getting the notes (optional)
                if (header.indexOf('notes') > -1) {
                    autoIndices.push({
                        index: header.indexOf('notes'),
                        column: 'notes',
                    });
                }
                // Getting the category (optional)
                if (header.indexOf('category') > -1) {
                    autoIndices.push({
                        index: header.indexOf('category'),
                        column: 'category',
                    });
                }
                // Getting the currency (optional)
                if (header.indexOf('currency') > -1) {
                    autoIndices.push({
                        index: header.indexOf('currency'),
                        column: 'currency',
                    });
                }
                // Getting the tags (optional)
                if (header.indexOf('tags') > -1) {
                    autoIndices.push({
                        index: header.indexOf('tags'),
                        column: 'tags',
                    });
                }
                // Getting the account (optional)
                if (header.indexOf('account') > -1 && multiple) {
                    autoIndices.push({
                        index: header.indexOf('account'),
                        column: 'account',
                    });
                }
                // Quick check to make sure...
                const dateIndex = (_e = autoIndices.find(o => o.column == 'date')) === null || _e === void 0 ? void 0 : _e.index;
                const amountIndex = (_f = autoIndices.find(o => o.column == 'amount')) === null || _f === void 0 ? void 0 : _f.index;
                if (dateIndex && amountIndex) {
                    testForCorruption(dateIndex, amountIndex);
                }
                setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _indices: autoIndices }));
            }
            setButtonLoading(false);
        }
        else {
            _showToast({
                message: `We couldn't detect any data from the file you uploaded. Please try another file or contact support@lunchmoney.app for further assistance.`,
                type: 'error',
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, "What would you like to do?"),
        React.createElement("div", { className: "display--flex flex--wrap" },
            React.createElement("div", { className: `selection-container ${selection == 'new' ? 'selected' : ''}`, onClick: () => {
                    setSelection('new');
                    // If there are no assets or accounts, then just default to multiple
                    if (_assets.getAssignableAccounts().length == 0) {
                        setMultiple(true);
                        setSelectedAccount(null);
                    }
                } },
                React.createElement("div", { className: "selection-content" },
                    React.createElement("span", { className: "csv-icon" }),
                    React.createElement("h3", null, "Upload a new file (CSV or PDF)"))),
            React.createElement("div", { className: `selection-container ${selection == 'mint' ? 'selected' : ''}`, onClick: () => {
                    setSelection('mint');
                } },
                React.createElement("div", { className: "selection-content" },
                    React.createElement("span", { className: "mint-logo" }),
                    React.createElement("h3", null, "Migrate from Mint")))),
        React.createElement(semantic_ui_react_1.Modal, { size: "small", open: selection !== null },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Upload your files"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                selection == 'new' && (React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null,
                                React.createElement("div", null,
                                    "Which account should we deposit these transactions into?",
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                        React.createElement("p", null, "You may import transactions into manually-managed (e.g. unlinked) accounts or synced accounts with the appropriate setting ('Allow modifications' must be on).")))),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: `category-dropdown feedback-dropdown p-category-modal`, selection: true, value: multiple
                                    ? 'multiple'
                                    : selectedAccount
                                        ? `${selectedAccount.source}_${selectedAccount.id}`
                                        : null, fluid: true, placeholder: "Make a selection", options: [
                                    {
                                        key: 'multiple',
                                        value: 'multiple',
                                        icon: 'copy outline',
                                        text: 'Upload to multiple accounts, default Cash account or create one later',
                                        onClick: () => {
                                            resetMatchedConfig();
                                            setSavedConfig(null);
                                            setUseMatchedConfig(false);
                                            setMultiple(true);
                                            setSelectedAccount(null);
                                        },
                                    },
                                    ..._assets
                                        .getAssignableAccounts()
                                        .map(o => {
                                        return Object.assign(Object.assign({ key: `${o.source}_${o.id}`, value: `${o.source}_${o.id}`, text: `${o.display_name} (${(0, format_1.capitalize)(o.type)})` }, (importConfigs.find(config => o.source == 'manual'
                                            ? config.asset_id == o.id
                                            : config.plaid_account_id == o.id)
                                            ? {
                                                label: 'Saved config',
                                            }
                                            : {})), { onClick: (e, { value }) => {
                                                if (value) {
                                                    handleAccountSelection({
                                                        id: o.id,
                                                        source: o.source,
                                                        display_name: o.display_name,
                                                    });
                                                }
                                            } });
                                    })
                                        .sort((a, b) => {
                                        if (a['label'] && !b['label']) {
                                            return -1;
                                        }
                                        else if (!a['label'] && b['label']) {
                                            return 1;
                                        }
                                        else if (a['label'] && b['label']) {
                                            return a['label'].localeCompare(b['label']);
                                        }
                                        else {
                                            return a.text.localeCompare(b.text);
                                        }
                                    }),
                                ] }))),
                    multiple && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mb-1rem" },
                        React.createElement("p", null,
                            "To upload to multiple accounts, ensure your CSV has a dedicated column with the ",
                            React.createElement("b", null, "account name"),
                            " in each row."))),
                    savedConfig && (React.createElement(semantic_ui_react_1.Form.Group, null,
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { className: "ml-1rem mr-05rem", toggle: true, checked: useMatchedConfig, label: "Use saved configuration", onChange: (e, { checked }) => {
                                    setUseMatchedConfig(checked);
                                    if (checked) {
                                        setMatchedConfig(savedConfig);
                                    }
                                    else {
                                        resetMatchedConfig();
                                    }
                                } }),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "info circle" }) }, "Using a saved configuration means you will skip through matching columns and confirming the date & amount notations are correct. Saved configurations are unique by account, so uploading a file from a different bank may result in no transactions being uploaded. Disable this option to go through the full set-up\u2013 you will have the option to override this configuration.")))),
                    (multiple || selectedAccount) && (React.createElement(semantic_ui_react_1.Accordion, { className: "mb-1rem" },
                        React.createElement(semantic_ui_react_1.Accordion.Title, { index: 0, active: showSettings, onClick: () => {
                                setShowSettings(!showSettings);
                            } },
                            React.createElement("span", { className: "label-style" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                                "Additional settings")),
                        React.createElement(semantic_ui_react_1.Accordion.Content, { active: showSettings },
                            (multiple || selectedAccount || selection == 'mint') && (React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "ml-1rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._set_reviewed, onChange: (e, { checked }) => {
                                            setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _set_reviewed: checked }));
                                        }, label: "Set transactions as reviewed" }),
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                        React.createElement("p", null, "Toggle this on if you would your imported transactions to start in a 'Reviewed' state"))))),
                            (!!selectedAccount || multiple) && (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "ml-1rem" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null,
                                            React.createElement("div", null,
                                                "Override the default currency",
                                                React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) }, "We also support importing a CSV with a currency column to denote different currencies by transaction. This currency will be the default to fall back on if there is none specified."))),
                                        React.createElement(EditableCurrency_1.default, { useSupported: true, firstValue: matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._currency, onSave: (value) => {
                                                if (value) {
                                                    setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _currency: value }));
                                                }
                                            } }))))),
                            React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "ml-1rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Set delimiter"),
                                    React.createElement(semantic_ui_react_1.Input, { value: (matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._delimiter) || '', onChange: (e, { value }) => {
                                            setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _delimiter: value }));
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "ml-1rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null,
                                        "Set encoding",
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "The encoding to use when opening local files. If specified, it must be a value supported by the FileReader API. If left blank, UTF-8 will be used.")),
                                    React.createElement(semantic_ui_react_1.Input, { value: (matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._encoding) || '', onChange: (e, { value }) => {
                                            setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _encoding: value }));
                                        } })))))))),
                selection == 'mint' && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "This is a special workflow for importing your transactions from Mint. You'll need an officially exported file of your transactions.",
                        ' '),
                    React.createElement(semantic_ui_react_1.Button, { className: "mb-05rem", size: "mini", icon: true, labelPosition: 'left', onClick: () => {
                            window.open('https://support.lunchmoney.app/migrating-from-mint');
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                        "View documentation on migrating from Mint"),
                    React.createElement(semantic_ui_react_1.Button, { className: "mb-1rem", size: "mini", basic: true, icon: true, color: "orange", labelPosition: 'left', onClick: () => {
                            window.open('https://mint.intuit.com/support/en-us/help-article/bank-transactions/download-transactions/L4VNv2fMR_US_en_US');
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                        "View instructions on how to get export file from Mint"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null,
                                    React.createElement("div", null,
                                        "What currency should we assign to these transactions?",
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }) }, "To see more currencies, go to the Settings page and add to 'Supported Currencies'."))),
                                React.createElement(EditableCurrency_1.default, { useSupported: true, firstValue: matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._currency, onSave: (value) => {
                                        if (value) {
                                            setMatchedConfig(Object.assign(Object.assign({}, matchedConfig), { _currency: value }));
                                        }
                                    } })))))),
                ((selection == 'new' && (multiple || !!selectedAccount)) ||
                    selection == 'mint') && (React.createElement(React.Fragment, null,
                    React.createElement(react_drag_drop_files_1.FileUploader, { multiple: true, handleChange: files => {
                            setUploadedFiles([...uploadedFiles, ...files]);
                        }, name: "file", types: ['csv', 'pdf'] },
                        React.createElement("div", { className: "drop-area" },
                            ' ',
                            React.createElement("p", null,
                                "Click to choose files ",
                                React.createElement("br", null),
                                "or drag-and-drop files here",
                                React.createElement("br", null),
                                React.createElement("br", null),
                                "CSV and PDFs supported. The total number of rows must be less than 10,000."))),
                    uploadedFiles.length > 0 && (React.createElement("div", { className: "file-list" }, uploadedFiles.map((file, index) => {
                        return (React.createElement("div", { key: `file-${index}`, className: "file-item" },
                            React.createElement(semantic_ui_react_1.Icon, { className: "clickable", name: "close", onClick: () => {
                                    const newFiles = [...uploadedFiles];
                                    newFiles.splice(index, 1);
                                    setUploadedFiles(newFiles);
                                } }),
                            React.createElement("span", null,
                                file.name,
                                " (",
                                (file.size / 1000).toFixed(0),
                                "kb)")));
                    }))))),
                selection == 'mint' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement(semantic_ui_react_1.Accordion, null,
                        React.createElement(semantic_ui_react_1.Accordion.Title, { index: 0, active: showSettings, onClick: () => {
                                setShowSettings(!showSettings);
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "info circle" }),
                            ' ',
                            React.createElement("b", null, "Important note regarding exporting labels/tags from Mint")),
                        React.createElement(semantic_ui_react_1.Accordion.Content, { active: showSettings },
                            React.createElement("p", null, "When exporting files from Mint, please note that tags are separated by spaces. However, Mint also permits spaces within individual tags, which can complicate accurate parsing of your data."),
                            React.createElement("p", null,
                                "This CSV import tool assumes multiple tags are delimited by a comma (','). If your transactions involve multiple tags, we strongly advise using a tool like",
                                ' ',
                                React.createElement("a", { href: "https://sheets.new", target: "_blank", className: "link clickable" }, "Google Sheets"),
                                ' ',
                                "to manually insert commas between each tag to ensure accurate data interpretation in Lunch Money."))))),
                showParseError && (React.createElement(semantic_ui_react_1.Message, { error: true },
                    React.createElement("p", null,
                        React.createElement("b", null, showParseError)),
                    useMatchedConfig ? (React.createElement("p", null, "Please double-check your CSV file or uncheck 'Use Saved Configuration' to continue.")) : (React.createElement("p", null, "Please re-check your uploaded files as well as the delimiter and encoding used (under Additional Settings).")))),
                showParseWarnings && (React.createElement(semantic_ui_react_1.Message, { warning: true }, showParseWarnings.map((o, index) => (React.createElement("p", null,
                    React.createElement(semantic_ui_react_1.Icon, { key: `warning-${index}`, name: "warning circle" }),
                    " ",
                    o))))),
                waitingForPDFs && (React.createElement(semantic_ui_react_1.Message, { info: true }, "We need a bit of time to process your PDF file(s)... this could take up to a minute or two! Please don't leave this page."))),
            React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
                React.createElement("div", null, (rawData === null || rawData === void 0 ? void 0 : rawData.length) > 0 && (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                        setViewRawData(true);
                    } }, "View Raw Data"))),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", onClick: () => {
                            setSelectedAccount(null);
                            setSelection(null);
                            setMultiple(false);
                            resetMatchedConfig();
                            setUseMatchedConfig(false);
                            setShowSettings(false);
                            setUploadedFiles([]);
                            setRawData(null);
                            setButtonLoading(false);
                        } },
                        "Close ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "x" })),
                    React.createElement(semantic_ui_react_1.Button, { icon: true, loading: buttonLoading, disabled: buttonLoading ||
                            !!showParseError ||
                            uploadedFiles.length == 0 ||
                            uploadedFiles.length > 20 ||
                            !(matchedConfig === null || matchedConfig === void 0 ? void 0 : matchedConfig._currency), labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            // Reprocess file in case there are any new configs.
                            const results = yield _process(true);
                            if (results) {
                                goNext({
                                    selection,
                                    useMatchedConfig,
                                    selectedAccount,
                                });
                                setSelection(null);
                            }
                            else {
                                // Has errors. Stay on page
                            }
                        }) }, (rawData === null || rawData === void 0 ? void 0 : rawData.length) ? (React.createElement(React.Fragment, null,
                        "Process ", rawData === null || rawData === void 0 ? void 0 :
                        rawData.length,
                        " rows ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))) : (React.createElement(React.Fragment, null,
                        "Upload ",
                        React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))))))),
        React.createElement(semantic_ui_react_1.Modal, { size: "large", open: viewRawData },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Uploaded raw data"),
            React.createElement(semantic_ui_react_1.Modal.Content, { scrolling: true }, (rawData === null || rawData === void 0 ? void 0 : rawData.length) > 0 && (React.createElement("div", null, rawData.map((row, index) => {
                return (React.createElement("p", { className: "monospace mb-0", key: `row-${index}` }, row.join(',')));
            })))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setViewRawData(false);
                    }) }, "Close")))));
};
exports.default = Upload;
