"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("../elements/EditableDate");
const budget_1 = require("@/helpers/budget");
const UserProvider_1 = require("@/providers/UserProvider");
const RolloverStartDate = ({ rolloverStartDate, setRolloverStartDate, setIsError, anchorDateOverride = null, granularityOverride = null, quantityOverride = null, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [isValid, setIsValid] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        setIsValid(isValidDate(rolloverStartDate));
    }, [
        anchorDateOverride,
        granularityOverride,
        quantityOverride,
        rolloverStartDate,
    ]);
    (0, react_1.useEffect)(() => {
        setIsError(!isValid);
    }, [isValid]);
    const isValidDate = date => {
        return (0, budget_1.isRolloverDateValid)({
            date,
            anchorDate: anchorDateOverride || _user.budgetPeriodSettings.anchor_date,
            granularityValue: granularityOverride || _user.budgetPeriodSettings.granularity,
            quantityValue: quantityOverride || _user.budgetPeriodSettings.quantity,
        });
    };
    return (React.createElement("div", { className: "transaction-detail mt-05rem mb-0" },
        React.createElement("label", null,
            "Rollover starts from",
            React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "ml-05rem" }) },
                React.createElement("p", null, "Specify a date from which the rollover calculation will begin. For instance, if you pick the beginning of the current month, budgets and transaction activity prior to this month will not be involved in the calculation of the envelope value."),
                React.createElement("p", null, "Reset your rollover calculation at any time by resetting this date."),
                React.createElement("p", null, "Note: Rollover start dates must be aligned with the overall budget anchor date!"))),
        React.createElement(semantic_ui_react_1.Form, null,
            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0" },
                React.createElement(semantic_ui_react_1.Form.Field, null,
                    React.createElement(EditableDate_1.default
                    //   showError={!expense.billing_date}
                    , { 
                        //   showError={!expense.billing_date}
                        yearPicker: true, identifier: "recurring-billing-date-2", firstValue: rolloverStartDate, state: 'Editing', location: 'modal', format: 'month_day_year', showError: !isValid, onSave: anchor_date => {
                            setRolloverStartDate(anchor_date);
                        }, filterDate: date => {
                            return isValidDate(date);
                        } })))),
        !isValid && (React.createElement(semantic_ui_react_1.Message, { error: true, className: "mb-1rem" }, "The rollover start date must match the current category's budgeting period."))));
};
exports.default = RolloverStartDate;
