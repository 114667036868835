"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const SelectLineItem_1 = require("../global/SelectLineItem");
const CustomPeriod_1 = require("./CustomPeriod");
const user_1 = require("@/actions/user");
const custom_budgets_1 = require("@/actions/custom_budgets");
const budget_1 = require("@/helpers/budget");
const Onboarding = ({ periodStart, closeOnboarding }) => {
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    // Form State
    const [formBudget, setFormBudget] = (0, react_1.useState)(_user.budgetPeriodSettings.quantity == 1 &&
        _user.budgetPeriodSettings.granularity == 'month' &&
        _user.budgetPeriodSettings.anchor_date == '2024-01-01' // default in the database
        ? 'default'
        : 'custom');
    const [budgetCadenceQuantity, setBudgetCadenceQuantity] = (0, react_1.useState)(_user.budgetPeriodSettings.quantity);
    const [budgetCadenceGranularity, setBudgetCadenceGranularity] = (0, react_1.useState)(_user.budgetPeriodSettings.granularity);
    const [budgetAnchorDate, setBudgetAnchorDate] = (0, react_1.useState)(_user.budgetPeriodSettings.anchor_date == '2024-01-01'
        ? Moment()
            .startOf('month')
            .format('YYYY-MM-DD') // reset it to align with this period
        : _user.budgetPeriodSettings.anchor_date);
    // Form State: Leftover Money
    const [budgetRolloverOption, setBudgetRolloverOption] = (0, react_1.useState)(null);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setButtonIsLoading(true);
        const closestValidRolloverDate = (0, budget_1.getClosestValidRolloverDate)({
            date: periodStart,
            anchorDate: budgetAnchorDate,
            granularityValue: budgetCadenceGranularity,
            quantityValue: budgetCadenceQuantity,
        });
        // Did budget period change?
        // Align all categories
        if (formBudget == 'custom') {
            yield (0, custom_budgets_1.createCustomBudget)(_categories.flattened
                .filter(o => !o.exclude_from_budget)
                .map(o => {
                return Object.assign(Object.assign({ filterType: 'category', filterValue: o.id }, (budgetCadenceQuantity !==
                    _user.budgetPeriodSettings.quantity ||
                    budgetCadenceGranularity !==
                        _user.budgetPeriodSettings.granularity ||
                    budgetAnchorDate !== _user.budgetPeriodSettings.anchor_date
                    ? {
                        quantityValue: budgetCadenceQuantity,
                        granularityValue: budgetCadenceGranularity,
                        anchorDate: budgetAnchorDate,
                    }
                    : {})), { rolloverOption: budgetRolloverOption == 'same category' && o.is_income
                        ? 'available funds' // Income categories should always rollover to available funds
                        : budgetRolloverOption, rolloverStartDate: closestValidRolloverDate });
            }));
        }
        // Update budget settings
        yield (0, user_1.updateAccountPreferences)({
            budget_period_quantity: budgetCadenceQuantity,
            budget_period_granularity: budgetCadenceGranularity,
            budget_period_anchor_date: budgetAnchorDate,
            budget_rollover_start_date: closestValidRolloverDate,
            budget_rollover_pool_start_date: closestValidRolloverDate,
        });
        yield _user.fetchAccountSettings();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Modal, { open: true, closeOnEscape: false, closeOnDocumentClick: false, closeOnDimmerClick: false, size: "small" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Let's set up your budget!"),
            React.createElement(semantic_ui_react_1.Modal.Content, { id: "budget-onboarding" },
                React.createElement("div", { className: "content" },
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("h3", null, "When should your budget start fresh?"),
                                React.createElement(SelectLineItem_1.default, { keyValue: 'default', selectedValue: formBudget, disabled: false, label: 'Every calendar month (e.g., the 1st of each month).', onClick: value => {
                                        setBudgetCadenceQuantity(1);
                                        setBudgetCadenceGranularity('month');
                                        setBudgetAnchorDate(Moment()
                                            .startOf('month')
                                            .format('YYYY-MM-DD'));
                                        setFormBudget(value);
                                    } }),
                                React.createElement(SelectLineItem_1.default, { keyValue: 'custom', selectedValue: formBudget, label: 'Every paycheck (align your budget with your income schedule).', displayOnSelect: React.createElement("div", { className: "mt-1rem" },
                                        React.createElement(semantic_ui_react_1.Message, { info: true }, "If you have existing budgets, they will get overwritten with this change!"),
                                        React.createElement(CustomPeriod_1.default, { budgetCadenceQuantity: budgetCadenceQuantity, setBudgetCadenceQuantity: setBudgetCadenceQuantity, budgetCadenceGranularity: budgetCadenceGranularity, setBudgetCadenceGranularity: setBudgetCadenceGranularity, budgetAnchorDate: budgetAnchorDate, setBudgetAnchorDate: setBudgetAnchorDate })), onClick: value => {
                                        setFormBudget(value);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("h3", null, "What should happen to leftover money in a category at the end of the period?"),
                                React.createElement("p", null, "Don\u2019t worry, you can change this for specific categories later!"),
                                React.createElement(SelectLineItem_1.default, { keyValue: 'available funds', selectedValue: budgetRolloverOption, label: 'Move it back to the general pool to plan your new budget from scratch.', displayOnSelect: !!budgetRolloverOption && (React.createElement(semantic_ui_react_1.Message, { info: true },
                                        "Rollover behavior will start this current period (",
                                        Moment(periodStart).format(_user.getFormat('month_day_year')),
                                        "). Historical transactions will not affect rollover amounts unless you update the rollover start date.")), onClick: value => {
                                        setBudgetRolloverOption(value);
                                    } }),
                                React.createElement(SelectLineItem_1.default, { keyValue: 'same category', selectedValue: budgetRolloverOption, label: 'Keep it in the same category so it’s still available next period.', displayOnSelect: !!budgetRolloverOption && (React.createElement(semantic_ui_react_1.Message, { info: true },
                                        "Rollover behavior will begin from",
                                        ' ',
                                        (0, budget_1.getClosestValidRolloverDate)({
                                            date: periodStart,
                                            anchorDate: budgetAnchorDate,
                                            granularityValue: budgetCadenceGranularity,
                                            quantityValue: budgetCadenceQuantity,
                                            formatOverride: _user.getFormat('month_day_year'),
                                        }),
                                        ". Transactions prior to this date will not affect rollover amounts unless you update the rollover start date.")), onClick: value => {
                                        setBudgetRolloverOption(value);
                                    } }),
                                React.createElement(SelectLineItem_1.default, { keyValue: null, selectedValue: budgetRolloverOption, label: 'Reset the category to zero– no rollover, track spending only.', onClick: value => {
                                        setBudgetRolloverOption(value);
                                    } })))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement("div", { className: "flex--space-between" },
                    React.createElement("span", { className: "link clickable ml-1rem", onClick: () => {
                            closeOnboarding();
                        } }, "Just take me to my budget"),
                    React.createElement(semantic_ui_react_1.Button, { disabled: false, loading: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            submit();
                            closeOnboarding();
                        }) }, "Confirm"))))));
};
exports.default = Onboarding;
