"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTransactions = exports.generateBudgets = void 0;
const Moment = require("moment");
const getRandom = (min, max, decimal) => {
    return (Math.random() * (max - min) + min).toFixed(decimal);
};
const generateBudgets = ({ categories, currency }) => {
    const budgets = [];
    budgets.push({
        category_id: categories.current['Coffee Shops'][0].id,
        amount: 80,
        currency: currency,
        start_date: Moment()
            .startOf('month')
            .subtract(3, 'month')
            .format('YYYY-MM-DD'),
    });
    return budgets;
};
exports.generateBudgets = generateBudgets;
const generateTransactions = ({ categories, assets, recurring, tags, currency, }) => {
    const transactions = [];
    const getBase = () => {
        return {
            status: 'cleared',
            recurring_id: null,
            category_id: null,
            asset_id: assets.current[getRandom(1, 3, 0) > 2 ? 'credit' : 'credit-2'],
            date: Moment().format('YYYY-MM-DD'),
            payee: 'Payee',
            original_name: 'Payee',
            amount: 40,
            notes: null,
            tags: [],
            currency: getRandom(1, 5, 0) > 4 ? 'cad' : currency,
        };
    };
    // Alcohol and bars
    // cspell:ignore Lockhart
    transactions.push(...['Mulberry Bar', 'The Lockhart', 'Yard Sale Bar'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Alcohol, Bars'][0].id, amount: getRandom(8, 13, 2), payee: payee, tags: [tags.current['Date Night']], notes: index == 0
                ? 'Happy hour'
                : index == 1
                    ? 'After work drinks'
                    : 'Catching up with Dave', original_name: payee, date: Moment()
                .subtract(index, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Coffee shops
    // cspell:ignore Manu
    transactions.push(...['Starbucks', 'Dreamers Coffee', 'Manu Coffee'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Coffee Shops'][0].id, amount: getRandom(3, 6, 2), payee: payee, original_name: payee, notes: index == 0
                ? 'Hazelnut latte'
                : index == 1
                    ? 'Hot chocolate'
                    : 'Matcha tea', date: Moment()
                .subtract(index, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Ridesharing
    transactions.push(...['Uber', 'Lyft', 'Lyft'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Ridesharing'][0].id, amount: getRandom(6, 17, 2), tags: index == 0 ? [tags.current['Date Night']] : [], payee: payee, notes: index == 0 ? 'To the restaurant' : index == 1 ? 'To home' : 'To work', original_name: payee, date: Moment()
                .subtract(index, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Groceries
    transactions.push(...[
        'Whole Foods',
        'Safeway',
        'Highland Farms',
        "Farmer's Market",
        'Whole Foods',
        'Safeway',
        // 'Highland Farms',
        // "Farmer's Market",
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Groceries'][0].id, amount: getRandom(12, 46, 2), payee: payee, notes: index == 0
                ? 'Milk, fruit, cereal'
                : index == 1
                    ? 'Baking ingredients'
                    : "Jenny's Potluck", original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Groceries, uncleared
    transactions.push(...['Highland Farms', "Farmer's Market"].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { status: 'uncleared', category_id: categories.current['Groceries'][0].id, amount: getRandom(7, 20, 2), payee: payee, notes: null, original_name: payee, date: Moment()
                .date(getRandom(26, 28, 0))
                .format('YYYY-MM-DD') });
    }));
    // Food Delivery
    // cspell:ignore Foodpanda, Bierhall
    transactions.push(...[
        'Delivery Hero',
        'Foodpanda',
        'Uber Eats',
        // 'Foodpanda',
        // 'Delivery Hero',
        // 'Uber Eats',
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Food Delivery'][0].id, amount: getRandom(15, 30, 2), payee: payee, notes: index == 0
                ? 'Pho Vietnam'
                : index == 1
                    ? 'The Golden Turtle'
                    : "Otto's Bierhall", original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Home
    transactions.push(...['Monthly rent', 'Monthly rent', 'Monthly rent'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { currency, status: 'cleared', category_id: categories.current['Home, Auto'][0].id, asset_id: assets.current['cash'], amount: 850, payee: payee, recurring_id: recurring.current['rent'], original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(1)
                .format('YYYY-MM-DD') });
    }));
    transactions.push(...['Car insurance', 'Car insurance', 'Car insurance'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { currency, status: 'cleared', category_id: categories.current['Home, Auto'][0].id, asset_id: assets.current['credit'], amount: 145, payee: payee, recurring_id: recurring.current['insurance'], original_name: payee, date: Moment()
                .subtract(index * 28, 'days')
                .format('YYYY-MM-DD') });
    }));
    // Restaurants
    // cspell:ignore Nunu, Mayur, Izakaya, Canis, Tondou
    transactions.push(...[
        'Lao Thai',
        'Nunu Ethiopian Fusion',
        'Pho Vietnam',
        'Mayur Indian Kitchen',
        "Joe's Stone Crab",
        'Canis',
        'Palace Diner',
        'The Golden Turtle',
        'Guu Izakaya',
        'Tondou Ramen',
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Restaurants'][0].id, amount: getRandom(10, 40, 2), payee: payee, tags: getRandom(0, 2, 0) == 2 ? [tags.current['Date Night']] : [], original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Shopping
    // cspell:ignore Unalome, Seashirt, ecologyst, Dagne, Wildcraft
    transactions.push(...[
        'Salt & Stone',
        'Tinker Watches',
        'Unalome',
        'Helm Boots',
        'The Seashirt',
        'ecologyst',
        'Dagne Dover',
        'Wildcraft',
        'Good as Gold',
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Shopping'][0].id, amount: getRandom(30, 40, 2), tags: getRandom(0, 2, 0) == 2 ? [tags.current['Penny']] : [], payee: payee, notes: index == 0
                ? 'Hat for winter'
                : index == 2
                    ? 'Swimming gear'
                    : index == 3
                        ? 'Camping boots'
                        : index == 5
                            ? 'Reusable straws'
                            : index == 8
                                ? 'Earrings'
                                : null, original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Income
    transactions.push(...['Direct Deposit', 'Direct Deposit', 'Direct Deposit'].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Income'][0].id, asset_id: assets.current['cash'], amount: -4500, payee: payee, original_name: payee, status: 'cleared', recurring_id: recurring.current['income'], date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 25, 0))
                .format('YYYY-MM-DD') });
    }));
    // Payment, Transfer
    transactions.push(...[
        'Credit Card Payment',
        'Credit Card Payment',
        'Credit Card Payment',
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Payment, Transfer'][0].id, asset_id: assets.current['credit'], currency, amount: index == 0 ? -710.45 : index == 1 ? -609.45 : -771.4, payee: payee, original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 4, 0))
                .format('YYYY-MM-DD') });
    }));
    transactions.push(...[
        'Transfer to Credit Card',
        'Transfer to Credit Card',
        'Transfer to Credit Card',
    ].map((payee, index) => {
        return Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Payment, Transfer'][0].id, asset_id: assets.current['cash'], amount: index == 0 ? 710.45 : index == 1 ? 609.45 : 771.4, currency, payee: payee, original_name: payee, date: Moment()
                .subtract(index % 3, 'months')
                .date(getRandom(1, 6, 0))
                .format('YYYY-MM-DD') });
    }));
    // Split transaction
    transactions.push(Object.assign(Object.assign({}, getBase()), { category_id: categories.current['Home, Auto'][0].id, asset_id: assets.current[getRandom(1, 2, 0) == 2 ? 'credit' : 'credit-2'], amount: 52.3, payee: 'Amazon', original_name: 'Amazon', date: Moment()
            .date(getRandom(1, 25, 0))
            .format('YYYY-MM-DD'), split: [
            {
                payee: 'Amazon',
                amount: 18.99,
                notes: 'New shower curtains',
                category_id: categories.current['Home, Auto'][0].id,
            },
            {
                payee: 'Amazon',
                amount: 33.31,
                notes: 'Candles and coloring book',
                category_id: categories.current['Shopping'][0].id,
            },
        ] }));
    return transactions;
};
exports.generateTransactions = generateTransactions;
