"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableCadence_1 = require("@components/elements/EditableCadence");
const EditableString_1 = require("@components/elements/EditableString");
const dates_1 = require("@helpers/dates");
const react_1 = require("react");
const UserProvider_1 = require("@providers/UserProvider");
const TransactionDetails = ({ expense, setExpense }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [showOptional, setShowOptional] = (0, react_1.useState)(expense.description ||
        expense.start_date ||
        expense.end_date ||
        expense.asset_id ||
        expense.plaid_account_id);
    return (React.createElement(React.Fragment, null,
        !_user.settings['viewed_recurring_details'] && (React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "A recurring item is a transaction you expect to occur on a regular basis. This can be an expense or income."),
            React.createElement("p", null, "A transaction can be linked to a recurring item, representing that month's payment for the recurring item."),
            React.createElement("p", null,
                React.createElement("a", { href: "https://support.lunchmoney.app/finances/recurring-expenses", target: "_blank", className: "link clickable" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "external" }),
                    "Learn more about recurring items")),
            React.createElement("p", null,
                React.createElement("span", { className: "link clickable", onClick: () => {
                        _user.updateSetting('viewed_recurring_details', true);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }),
                    " Got it! Don't show me this again.")))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Recurring Item Details"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Merchant Name",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Pick something that you'll recognize. Transactions linked to this recurring item will be renamed to this field.")),
                React.createElement(EditableString_1.default, { state: 'Editing', autoFocus: true, location: 'modal', identifier: 'recurring-payee', firstValue: expense.payee, placeholder: "Netflix", shouldSaveOnChange: true, onSave: payee => {
                        setExpense(Object.assign(Object.assign({}, expense), { payee }));
                    } })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Estimated Billing date",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "When do you expect to get billed for this recurring item? Since you may get billed anytime within a range of a couple of days, Lunch Money will automatically search 2 days before and after the date you select.")),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(EditableDate_1.default, { identifier: "recurring-billing-date", firstValue: expense.billing_date, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: billing_date => {
                            setExpense(Object.assign(Object.assign({}, expense), { billing_date }));
                        } }))),
            _user.settings['allow_recurring_categories'] && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Transaction Category",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "What category does this recurring item belong to? If you prefer recurring items to not be counted towards any category and instead be calculated separately in totals, you can adjust this in the Settings.")),
                React.createElement(EditableCategory_1.default, { identifier: `recurring-category`, firstValue: expense.category_id, state: 'Editing', showError: (expense.recurring_id || expense.transaction_id) &&
                        !expense.category_id, location: 'modal', onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                        setExpense(Object.assign(Object.assign({}, expense), { category_id }));
                    }) }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Repeating Cadence",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "How often does this recurring item repeat?")),
                React.createElement(EditableCadence_1.default, { state: 'Editing', recurringItem: expense, onSave: obj => {
                        setExpense(Object.assign(Object.assign({}, expense), obj));
                    } }))),
        !showOptional && (React.createElement("div", null,
            React.createElement("p", { className: "link clickable", onClick: () => {
                    setShowOptional(!showOptional);
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: showOptional ? 'caret down' : 'caret right' }),
                " View optional properties"))),
        showOptional && (React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", null, "Optional Properties"),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Quick Description",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Optionally provide a short description for this recurring expense. This will show up as the notes for transactions linked to this recurring item.")),
                React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', placeholder: "Video streaming", identifier: 'recurring-description', firstValue: expense.description, shouldSaveOnChange: true, onSave: description => {
                        setExpense(Object.assign(Object.assign({}, expense), { description }));
                    } })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Paid from account",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Which bank account do you use to pay for this recurring item? If this is set, then Lunch Money will only look for occurrences within transactions from this account.")),
                React.createElement(EditableAsset_1.default, { assignableOnly: false, defaultSelection: Object.assign({}, (expense.asset_id
                        ? { id: expense.asset_id, source: 'manual' }
                        : expense.plaid_account_id
                            ? { id: expense.plaid_account_id, source: 'plaid' }
                            : { id: -1, source: null })), onSave: obj => {
                        setExpense(Object.assign(Object.assign(Object.assign({}, expense), { 
                            // Reset both
                            asset_id: null, plaid_account_id: null }), {
                            [obj.source == 'plaid' ? 'plaid_account_id' : 'asset_id']: obj.id > 0 ? obj.id : null,
                        }));
                    } })),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "What month does this recurring item start?",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Specify a start date for your recurring item. For example, if you started a Netflix subscription this month, set the start date and it will only start counting in your recurring items from that day!")),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(EditableDate_1.default, { identifier: "recurring-active-period", firstValue: expense.start_date, state: 'Editing', monthPicker: true, location: 'modal', format: 'month_year', onSave: start_date => {
                            setExpense(Object.assign(Object.assign({}, expense), { start_date }));
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "What month will this recurring item end?",
                    ' ',
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Specify an end date for your recurring item. For example, if you cancelled a Netflix subscription this month, set the end date and it will stop counting in your recurring items after that day!")),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(EditableDate_1.default, { identifier: "recurring-active-period", firstValue: expense.end_date, state: 'Editing', monthPicker: true, jumpToEnd: true, location: 'modal', format: 'month_year', onSave: end_date => {
                            setExpense(Object.assign(Object.assign({}, expense), { end_date: end_date ? (0, dates_1.getThisMonthEnd)(end_date) : null }));
                        } })))))));
};
exports.default = TransactionDetails;
