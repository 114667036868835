"use strict";
/**
 *  TagStatus.tsx
 *  Progress bar for a category in the budget summary card
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const format_1 = require("@helpers/format");
const TransactionTag_1 = require("@components/elements/TransactionTag");
const TagStatus = ({ tag, total, currency, flipSign }) => {
    return (React.createElement("div", { className: "card-content-wrapper" },
        React.createElement("div", { className: "card-content" },
            React.createElement("span", { className: `card-text ellipsis` },
                tag.id !== -1 && (React.createElement(TransactionTag_1.default, { key: `${tag.id}-tag-summary`, transactionId: null, tag: tag, circleOnly: true })),
                React.createElement(react_router_dom_1.Link, { to: {
                        search: tag.id === -1 ? '?untagged=true' : `?tag=${tag.id}`,
                    }, className: `link clickable` }, tag.name)),
            React.createElement("span", { className: `card-number ${flipSign && total < 0 ? 'color--green' : 'plain'} no-wrap` }, (0, format_1.toPrice)(flipSign ? -1 * total : total, currency)))));
};
exports.default = TagStatus;
