"use strict";
/**
 *  EditCategory.tsx
 *  Modal to edit category details
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Moment = require("moment");
const categories_1 = require("@actions/categories");
const react_1 = require("react");
const EditableString_1 = require("@components/elements/EditableString");
// Types
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const UserProvider_1 = require("@providers/UserProvider");
const EditableDate_1 = require("@/components/elements/EditableDate");
const EditCategory = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [categoryName, setCategoryName] = (0, react_1.useState)(null);
    const [categoryDescription, setCategoryDescription] = (0, react_1.useState)(null);
    const [categoryIsIncome, setCategoryIsIncome] = (0, react_1.useState)(false);
    const [categoryGroup, setCategoryGroup] = (0, react_1.useState)(null);
    const [categoryExcludeFromTotals, setCategoryExcludeFromTotals] = (0, react_1.useState)(false);
    const [categoryExcludeFromBudget, setCategoryExcludeFromBudget] = (0, react_1.useState)(false);
    const [categoryArchived, setCategoryArchived] = (0, react_1.useState)(false);
    const [categoryArchivedOn, setCategoryArchivedOn] = (0, react_1.useState)(null);
    const [categoryId, setCategoryId] = (0, react_1.useState)(null);
    const [forceDeleteCategoryId, setForceDeleteCategoryId] = (0, react_1.useState)(null);
    const [deleteIsLoading, setDeleteIsLoading] = (0, react_1.useState)(false);
    const [showSave, setShowSave] = (0, react_1.useState)(false);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        setCategoryName((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.name);
        setCategoryDescription((_b = data === null || data === void 0 ? void 0 : data.category) === null || _b === void 0 ? void 0 : _b.description);
        setCategoryGroup((_c = data === null || data === void 0 ? void 0 : data.category) === null || _c === void 0 ? void 0 : _c.group_id);
        setCategoryIsIncome((_d = data === null || data === void 0 ? void 0 : data.category) === null || _d === void 0 ? void 0 : _d.is_income);
        setCategoryExcludeFromTotals((_e = data === null || data === void 0 ? void 0 : data.category) === null || _e === void 0 ? void 0 : _e.exclude_from_totals);
        setCategoryExcludeFromBudget((_f = data === null || data === void 0 ? void 0 : data.category) === null || _f === void 0 ? void 0 : _f.exclude_from_budget);
        setCategoryArchived(!!((_g = data === null || data === void 0 ? void 0 : data.category) === null || _g === void 0 ? void 0 : _g.archived));
        setCategoryArchivedOn(!!((_h = data === null || data === void 0 ? void 0 : data.category) === null || _h === void 0 ? void 0 : _h.archived)
            ? Moment((_j = data === null || data === void 0 ? void 0 : data.category) === null || _j === void 0 ? void 0 : _j.archived_on).format('YYYY-MM-DD')
            : null);
        setCategoryId((_k = data === null || data === void 0 ? void 0 : data.category) === null || _k === void 0 ? void 0 : _k.id);
        setDisableOnClickOutside(false);
    }, [data]);
    // Setup
    (0, react_1.useEffect)(() => {
        var _a;
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" },
                    "Edit Category ",
                    ((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.is_group) && 'Group'))),
            middleButton: showSave ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")) : null,
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
            });
        };
    }, [data, showSave]);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (showSave) {
                        const results = yield utils._process(categories_1.updateCategory)(categoryId, {
                            name: categoryName,
                            description: categoryDescription,
                            is_income: categoryIsIncome,
                            exclude_from_totals: categoryExcludeFromTotals,
                            exclude_from_budget: categoryExcludeFromBudget,
                            archived: categoryArchived,
                            archived_on: categoryArchivedOn,
                            group_id: categoryGroup,
                        });
                        if (!results.error) {
                            yield _categories.fetchCategories();
                            setVisibility(false);
                            utils._showToast({
                                message: 'Successfully edited category',
                                type: 'success',
                            });
                        }
                    }
                    else {
                        setVisibility(false);
                    }
                }) }, showSave ? 'Save' : 'Close')),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [
        categoryName,
        categoryGroup,
        categoryDescription,
        categoryIsIncome,
        categoryExcludeFromTotals,
        categoryExcludeFromBudget,
        categoryArchived,
        categoryArchivedOn,
        categoryId,
        showSave,
    ]);
    const _forceDeleteCategory = () => __awaiter(void 0, void 0, void 0, function* () {
        setDeleteIsLoading(true);
        const response = yield utils._process(categories_1.deleteCategory)(forceDeleteCategoryId, {});
        if (!response.error) {
            _categories.remove(forceDeleteCategoryId);
            setVisibility(false);
            // Show success message
            utils._showToast({
                message: 'Successfully deleted category',
                type: 'success',
            });
        }
        setForceDeleteCategoryId(null);
        setDeleteIsLoading(false);
    });
    return (React.createElement("div", { className: "g-categories-setup" },
        React.createElement("div", { id: "transactions-modal-detail-view" },
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null,
                    "Category ",
                    ((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.is_group) && 'Group',
                    " Details"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null,
                        "Category ",
                        ((_b = data === null || data === void 0 ? void 0 : data.category) === null || _b === void 0 ? void 0 : _b.is_group) && 'Group',
                        " Name"),
                    ' ',
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'category-group=name', firstValue: categoryName, placeholder: "e.g. Food", shouldSaveOnChange: true, onSave: name => {
                            setCategoryName(name);
                            setDisableOnClickOutside(true);
                            setShowSave(true);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null,
                        "Category ",
                        ((_c = data === null || data === void 0 ? void 0 : data.category) === null || _c === void 0 ? void 0 : _c.is_group) && 'Group',
                        " description"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.TextArea, { rows: 2, value: categoryDescription || '', onChange: (e, { value }) => {
                                        setCategoryDescription(value);
                                        setDisableOnClickOutside(true);
                                        setShowSave(true);
                                    } }))))),
                ((_d = data === null || data === void 0 ? void 0 : data.category) === null || _d === void 0 ? void 0 : _d.is_group) ? (React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mb-1rem", size: "small", color: "yellow", onClick: () => {
                        switchView('ADD_CATEGORY_GROUP');
                    } }, "Modify categories in group")) : (React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Category Group"),
                    React.createElement(semantic_ui_react_1.Dropdown, { value: categoryGroup, fluid: true, className: `category-dropdown p-category-modal`, placeholder: 'No category group', text: categoryGroup ? _categories.getName(categoryGroup) : null, search: true, selection: true, options: [
                            { key: 'none', text: 'No category group', value: null },
                            ..._categories.all
                                .filter(o => o.is_group)
                                .map(o => {
                                return {
                                    key: o.id,
                                    text: o.name,
                                    value: o.id,
                                };
                            }),
                        ], onChange: (e, obj) => __awaiter(void 0, void 0, void 0, function* () {
                            setDisableOnClickOutside(true);
                            setShowSave(true);
                            setCategoryGroup(obj.value);
                        }) }))),
                categoryArchived && (React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Archived On"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableDate_1.default, { identifier: "category-archive-date", firstValue: categoryArchivedOn, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: date => {
                                        setDisableOnClickOutside(true);
                                        setCategoryArchivedOn(date);
                                        setShowSave(true);
                                    } })))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Created on"),
                    React.createElement("p", { className: "monospace" }, Moment((_e = data === null || data === void 0 ? void 0 : data.category) === null || _e === void 0 ? void 0 : _e.created_at).format(_user.getMonthDayYearFormat())))),
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null,
                    "Category ",
                    ((_f = data === null || data === void 0 ? void 0 : data.category) === null || _f === void 0 ? void 0 : _f.is_group) && 'Group',
                    " properties"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryIsIncome, disabled: !!((_g = data === null || data === void 0 ? void 0 : data.category) === null || _g === void 0 ? void 0 : _g.group_id), onChange: (e, { checked }) => {
                                    setCategoryIsIncome(checked);
                                    setDisableOnClickOutside(true);
                                    setShowSave(true);
                                }, label: "Treat as income" }),
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" },
                                React.createElement("p", null, "Check this to treat this category as income. Transactions in these categories will be totaled to represent your income throughout Lunch Money, such as in monthly summaries and the Overview page's charts."),
                                React.createElement("p", null,
                                    React.createElement("b", null, "Examples:"),
                                    " income from work, interest income, side project income, bank bonuses, etc.",
                                    ' '),
                                !!((_h = data === null || data === void 0 ? void 0 : data.category) === null || _h === void 0 ? void 0 : _h.group_id) && (React.createElement("p", null, "These settings are inherited from the category group and cannot be changed individually."))))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryExcludeFromBudget, disabled: !!((_j = data === null || data === void 0 ? void 0 : data.category) === null || _j === void 0 ? void 0 : _j.group_id), onChange: (e, { checked }) => {
                                    setCategoryExcludeFromBudget(checked);
                                    setDisableOnClickOutside(true);
                                    setShowSave(true);
                                }, label: "Exclude from budget" }),
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" },
                                React.createElement("p", null, "Check this to exclude this category from the budget. This means that it will not show up as a category in the Budget view."),
                                React.createElement("p", null,
                                    React.createElement("b", null, "Examples:"),
                                    " emergencies, medical bills, reimbursements, etc."),
                                !!((_k = data === null || data === void 0 ? void 0 : data.category) === null || _k === void 0 ? void 0 : _k.group_id) && (React.createElement("p", null, "These settings are inherited from the category group and cannot be changed individually."))))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryExcludeFromTotals, disabled: !!((_l = data === null || data === void 0 ? void 0 : data.category) === null || _l === void 0 ? void 0 : _l.group_id), onChange: (e, { checked }) => {
                                    setCategoryExcludeFromTotals(checked);
                                    setDisableOnClickOutside(true);
                                    setShowSave(true);
                                }, label: "Exclude from totals" }),
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" },
                                React.createElement("p", null, "Enable this to exclude this category from any expenses or income totals in summaries. This means that transactions in these categories will not be reflected in the total spending or income for that month."),
                                React.createElement("p", null,
                                    React.createElement("b", null, "Examples:"),
                                    " reimbursements, cash withdrawals, payments and transfers, etc."),
                                !!((_m = data === null || data === void 0 ? void 0 : data.category) === null || _m === void 0 ? void 0 : _m.group_id) && (React.createElement("p", null, "These settings are inherited from the category group and cannot be changed individually."))))))),
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null, "Other actions"),
                React.createElement("div", { className: "detail-buttons mb-1rem" },
                    React.createElement(semantic_ui_react_1.Popup, { position: 'top center', inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-05rem", color: "yellow", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                const results = yield utils._process(categories_1.updateCategory)(categoryId, {
                                    archived: !categoryArchived,
                                });
                                if (!results.error) {
                                    yield _categories.fetchCategories();
                                    setVisibility(false);
                                    utils._showToast({
                                        message: 'Successfully archived category',
                                        type: 'success',
                                    });
                                }
                            }) },
                            categoryArchived ? 'Unarchive' : 'Archive',
                            ' ',
                            React.createElement(semantic_ui_react_1.Icon, { name: "question circle" })) },
                        React.createElement("p", null, "Archived categories will not show up when creating or updating transactions, in budgets, or in the overview spending summary. Archived categories will still show up as an option for filtering, trends, stats and analytics."),
                        React.createElement("p", null, "Archiving a category group will also archive all its children categories.")),
                    ((_o = data === null || data === void 0 ? void 0 : data.category) === null || _o === void 0 ? void 0 : _o.is_group) ? (React.createElement(React.Fragment, null)) : (React.createElement(semantic_ui_react_1.Popup, { position: 'top center', inverted: true, mouseEnterDelay: 350, size: "small", trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mb-05rem", size: "small", color: "red", onClick: () => {
                                switchView('MERGE_AND_DELETE');
                            } },
                            "Merge and delete ",
                            React.createElement(semantic_ui_react_1.Icon, { name: "question circle" })) }, "Merge this category with another existing category and then delete this category")),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, color: 'red', loading: deleteIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setDeleteIsLoading(true);
                            // Always show confirmation dialog
                            // const response: any = await deleteCategory(categoryId)
                            // if (response.hasOwnProperty('data')) {
                            // if (response.data == false) {
                            setDisableOnClickOutside(true);
                            setForceDeleteCategoryId(categoryId);
                            setDeleteIsLoading(false);
                            // } else {
                            //   setVisibility(false)
                            //   _categories.remove(categoryId)
                            //   // Show success message
                            //   utils._showToast({
                            //     message: 'Successfully deleted category',
                            //     type: 'success',
                            //   })
                            // }
                            // } else {
                            //   utils._showToast({
                            //     message: response.error.message,
                            //     type: 'error',
                            //   })
                            // }
                        }) }, "Delete")))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!forceDeleteCategoryId, closeOnEscape: false, closeOnDimmerClick: false, onClose: () => {
                setDeleteIsLoading(false);
                setForceDeleteCategoryId(null);
            }, size: "small" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Are you sure you want to delete this category and all associated items?"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        "In order to delete this category, we'll need to un-categorize associated transactions and delete all budgets and category rules associated.",
                        ' ',
                        React.createElement("b", null, "This is a nuclear option and cannot be reversed.")),
                    React.createElement("p", null,
                        "You may also choose to ",
                        React.createElement("b", null, "archive this category"),
                        " or",
                        ' ',
                        React.createElement("b", null, "merge this category into another"),
                        " instead. Archived categories will not show up when creating or updating transactions, in budgets, or in the overview spending summary. Archived categories will still show up as an option for filtering, trends, stats and analytics."))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: deleteIsLoading, onClick: () => {
                        setDeleteIsLoading(false);
                        setForceDeleteCategoryId(null);
                    }, negative: true }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { loading: deleteIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setDeleteIsLoading(false);
                        setForceDeleteCategoryId(null);
                        const results = yield utils._process(categories_1.updateCategory)(categoryId, {
                            archived: true,
                        });
                        if (!results.error) {
                            yield _categories.fetchCategories();
                            setVisibility(false);
                            utils._showToast({
                                message: 'Successfully archived category',
                                type: 'success',
                            });
                        }
                    }) }, "Archive instead"),
                React.createElement(semantic_ui_react_1.Button, { loading: deleteIsLoading, onClick: _forceDeleteCategory }, "Delete this category")))));
};
exports.default = EditCategory;
