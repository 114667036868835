"use strict";
/**
 *  EditTag.tsx
 *  Modal to edit tag details
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Moment = require("moment");
const tags_1 = require("@actions/tags");
const react_1 = require("react");
const EditableString_1 = require("@components/elements/EditableString");
// Types
const UserProvider_1 = require("@providers/UserProvider");
const TagsProvider_1 = require("@/providers/TagsProvider");
const EditTag = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [tagName, setTagName] = (0, react_1.useState)(null);
    const [tagDescription, setTagDescription] = (0, react_1.useState)(null);
    const [tagArchived, setTagArchived] = (0, react_1.useState)(false);
    const [tagId, setTagId] = (0, react_1.useState)(null);
    const [numAffectedRules, setNumAffectedRules] = (0, react_1.useState)(null);
    const [numAffectedTx, setNumAffectedTx] = (0, react_1.useState)(null);
    const [showDialog, setShowDialog] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [showSave, setShowSave] = (0, react_1.useState)(false);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        setTagName((_a = data === null || data === void 0 ? void 0 : data.tag) === null || _a === void 0 ? void 0 : _a.name);
        setTagDescription((_b = data === null || data === void 0 ? void 0 : data.tag) === null || _b === void 0 ? void 0 : _b.description);
        setTagArchived(!!((_c = data === null || data === void 0 ? void 0 : data.tag) === null || _c === void 0 ? void 0 : _c.archived));
        setTagId((_d = data === null || data === void 0 ? void 0 : data.tag) === null || _d === void 0 ? void 0 : _d.id);
    }, [data]);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "Edit Tag"))),
            middleButton: showSave ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")) : null,
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
            });
        };
    }, [data, showSave]);
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { disabled: isLoading, loading: isLoading, color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (showSave) {
                        setIsLoading(true);
                        const results = yield utils._process(tags_1.updateTag)(tagId, {
                            name: tagName,
                            description: tagDescription,
                            archived: tagArchived,
                        });
                        if (!results.error) {
                            yield _tags.fetchTags();
                            setVisibility(false);
                            utils._showToast({
                                message: 'Successfully edited tag',
                                type: 'success',
                            });
                        }
                        setIsLoading(false);
                    }
                    else {
                        setVisibility(false);
                    }
                }) }, showSave ? 'Save' : 'Close')),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [tagName, tagDescription, tagArchived, tagId, showSave, isLoading]);
    const removeTag = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const result = yield (0, tags_1.deleteTag)(tagId);
        setIsLoading(false);
        if (result.error) {
            if (result.error.data) {
                const transactions = result.error.data.transactions;
                const rules = result.error.data.rules;
                setNumAffectedTx(transactions);
                setNumAffectedRules(rules);
                setShowDialog(true);
                setDisableOnClickOutside(true);
            }
        }
        else {
            // Remove from tags array
            _tags.removeTag(tagId);
            utils._showToast({ message: 'Successfully deleted tag', type: 'success' });
            setVisibility(false);
        }
    });
    const forceRemoveTag = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const result = yield (0, tags_1.forceDeleteTag)(tagId);
        setIsLoading(false);
        if (!result.error) {
            setShowDialog(false);
            // Remove from tags array
            _tags.removeTag(tagId);
            utils._showToast({ message: 'Successfully deleted tag', type: 'success' });
            setVisibility(false);
        }
    });
    return (React.createElement("div", { className: "g-categories-setup" },
        React.createElement("div", { id: "transactions-modal-detail-view" },
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null, "Tag details"),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Tag Name"),
                    ' ',
                    React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'tag-group=name', firstValue: tagName, placeholder: "e.g. Food", shouldSaveOnChange: true, onSave: name => {
                            setTagName(name);
                            setDisableOnClickOutside(true);
                            setShowSave(true);
                        } })),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Tag description"),
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.TextArea, { rows: 2, value: tagDescription || '', onChange: (e, { value }) => {
                                        setTagDescription(value);
                                        setDisableOnClickOutside(true);
                                        setShowSave(true);
                                    } }))))),
                React.createElement("div", { className: "transaction-detail" },
                    React.createElement("label", null, "Created on"),
                    React.createElement("p", { className: "monospace" }, Moment((_a = data === null || data === void 0 ? void 0 : data.tag) === null || _a === void 0 ? void 0 : _a.created_at).format(_user.getMonthDayYearFormat())))),
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null, "Tag properties"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: tagArchived, onChange: (e, { checked }) => {
                                    setTagArchived(checked);
                                    setDisableOnClickOutside(true);
                                    setShowSave(true);
                                }, label: "Archived" }),
                            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", className: "ml-05rem", color: "grey" }), inverted: true, size: "small" },
                                React.createElement("p", null, "Archived tags will not show up when creating or updating transactions. Archived tags will still show up as an option for filtering, trends, stats and analytics.")))))),
            React.createElement("div", { className: "transaction-details" },
                React.createElement("h3", null, "Other actions"),
                React.createElement("div", { className: "detail-buttons mb-1rem" },
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, color: 'red', loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            removeTag();
                        }) }, "Delete")))),
        React.createElement(semantic_ui_react_1.Confirm, { open: !!(showDialog && (numAffectedRules || numAffectedTx)), onConfirm: () => {
                forceRemoveTag();
            }, confirmButton: 'Yes, I still want to delete this tag', onCancel: () => {
                setShowDialog(false);
            }, cancelButton: 'Cancel', header: 'Are you sure you want to delete this tag?', content: React.createElement("div", { className: "content" },
                React.createElement("p", null,
                    "In order to delete this tag, we'll need to modify all transactions and rules associated.",
                    ' ',
                    React.createElement("b", null, "This is a nuclear option and cannot be reversed.")),
                React.createElement("p", null,
                    "Deleting this tag will:",
                    !!numAffectedTx && numAffectedTx > 0 && (React.createElement("li", null,
                        "untag ",
                        numAffectedTx,
                        " transaction",
                        numAffectedTx > 1 ? 's' : '')),
                    !!numAffectedRules && numAffectedRules > 0 && (React.createElement("li", null,
                        "modify/remove ",
                        numAffectedRules,
                        " rule",
                        numAffectedRules > 1 ? 's' : '')))) })));
};
exports.default = EditTag;
