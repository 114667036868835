"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableString_1 = require("@components/elements/EditableString");
const TransactionTag_1 = require("@components/elements/TransactionTag");
const TagRow = ({ tag, update, del, showArchived, onOpenModal, openTable }) => {
    return (React.createElement(semantic_ui_react_1.Table.Row, null,
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                onOpenModal(tag);
            } },
            React.createElement(EditableString_1.default, { identifier: `tag-${tag.id}`, location: 'inline', firstValue: tag.name, autoFocus: true, emptyText: 'Add a name', shouldSaveOnBlur: true, shouldSaveOnEnter: true, shouldSelectOnFocus: true, state: 'ReadOnly', onSave: name => {
                    update(tag.id, { name });
                } })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable", onClick: () => {
                onOpenModal(tag);
            } },
            React.createElement(EditableString_1.default, { identifier: `tag-description-${tag.id}`, firstValue: tag.description, location: 'inline', autoFocus: true, blankIfNull: true, shouldSaveOnBlur: true, emptyText: 'Add a description', shouldSaveOnEnter: true, shouldSelectOnFocus: true, state: 'ReadOnly', onSave: description => {
                    update(tag.id, { description });
                } })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
            React.createElement(TransactionTag_1.default, { key: `${tag.id}-tag-summary`, transactionId: null, tag: tag, circleOnly: true })),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                openTable(tag);
            } },
            React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "search plus", className: "color--grey clickable dark-grey-on-hover" }), size: "tiny", content: "View transactions (pop-up)", inverted: true })),
        showArchived && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                    onOpenModal(tag);
                } }, tag.archived && React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" })))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: 'clickable center-align', onClick: () => {
                onOpenModal(tag);
            } },
            React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey`, onClick: () => {
                    onOpenModal(tag);
                } }))));
};
exports.default = TagRow;
