"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  MerchantNames.tsx
 */
const React = require("react");
const react_1 = require("react");
const transactions_1 = require("@actions/transactions");
// Semantic UI
const Loader_1 = require("@components/global/Loader");
const qs = require("query-string");
const stringSimilarity = require("string-similarity");
const Sticky_1 = require("@components/elements/Sticky");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const _ = require("lodash");
const Moment = require("moment");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const UserProvider_1 = require("@/providers/UserProvider");
const MerchantNames = ({ _process, _showToast }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [allNames, setAllNames] = (0, react_1.useState)([]);
    const [selectedNames, setSelectedNames] = (0, react_1.useState)([]);
    const [visibleNames, setVisibleNames] = (0, react_1.useState)([]);
    const [newName, setNewName] = (0, react_1.useState)('');
    const [skip, setSkip] = (0, react_1.useState)(0);
    const [filter, setFilter] = (0, react_1.useState)('');
    const [limit, setLimit] = (0, react_1.useState)(50);
    const [showSimilarOnly, setShowSimilarOnly] = (0, react_1.useState)(true);
    const shiftClicked = (0, react_1.useRef)(false);
    const lastSelectedIndex = (0, react_1.useRef)(null);
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(true);
    const [showConfirm, setShowConfirm] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isFetching, setIsFetching] = (0, react_1.useState)(false);
    const [earliestDate, setEarliestDate] = (0, react_1.useState)(null);
    const handleKeyDown = event => {
        if (event.keyCode == 16) {
            shiftClicked.current = true;
        }
    };
    const handleKeyUp = event => {
        if (event.keyCode == 16) {
            shiftClicked.current = false;
        }
    };
    (0, react_1.useEffect)(() => {
        fetchData();
        // Page title
        document.title = 'Merchant Names - Lunch Money';
        document.addEventListener('keydown', handleKeyDown, false);
        document.addEventListener('keyup', handleKeyUp, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
            document.removeEventListener('keyup', handleKeyUp, false);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        let prev = null;
        const _visibleNames = [];
        allNames.forEach(o => {
            if (filter && o.payee.toLowerCase().indexOf(filter.toLowerCase()) == -1) {
                // return null
            }
            else if (showSimilarOnly) {
                if (prev) {
                    const similarity = stringSimilarity.compareTwoStrings(o.payee.toLowerCase().trim(), prev.payee.toLowerCase().trim());
                    if (similarity > 0.5) {
                        _visibleNames.push(prev);
                        _visibleNames.push(o);
                    }
                }
                prev = o;
            }
            else {
                _visibleNames.push(o);
            }
        });
        setVisibleNames(_.uniqBy(_visibleNames, 'index'));
        setSelectedNames(allNames.filter(o => o.selected));
    }, [allNames, showSimilarOnly, filter]);
    (0, react_1.useEffect)(() => {
        if (skip > visibleNames.length) {
            setSkip(Math.floor(visibleNames.length / limit) * limit);
        }
    }, [visibleNames, skip, limit]);
    (0, react_1.useEffect)(() => {
        lastSelectedIndex.current == null;
    }, [skip, limit]);
    (0, react_1.useEffect)(() => {
        fetchData();
    }, [earliestDate]);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsFetching(true);
        const results = yield (0, transactions_1.getMerchantNames)({
            start_date: earliestDate,
        });
        setAllNames(results.map((o, index) => {
            return Object.assign(Object.assign({}, o), { index });
        }));
        setIsLoading(false);
        setIsFetching(false);
    });
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        const allIds = _.flatten(selectedNames.map(tx => tx.ids));
        setShowConfirm(false);
        const results = yield _process(transactions_1.bulkUpdateTransactions)({
            transactionIds: allIds,
            updateObj: { payee: newName },
        });
        if (!results.error) {
            _showToast({
                message: `Successfully updated payee name for ${allIds.length} transactions to ${newName}.`,
                type: 'success',
            });
            setNewName('');
            yield fetchData();
        }
    });
    const clear = () => {
        setAllNames(allNames.map(o => {
            return Object.assign(Object.assign({}, o), { selected: false });
        }));
    };
    // cspell:ignore dont
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-tags" },
        React.createElement(ContainerHeader_1.default, { title: `Merchant Names (${visibleNames.length !== allNames.length
                ? `${visibleNames.length}/`
                : ''}${allNames.length})` }),
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "Welcome to the new Merchant Names page!"),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement("p", null, "Use this page to quickly view and clean up your merchant names. Select 1 or more merchant names and update them in bulk using the sidebar on the right."))),
        React.createElement("div", { className: "header-buttons flex--center-align" },
            React.createElement(semantic_ui_react_1.Button, { className: "ml-1rem display-on-mobile narrow", basic: !showSidebar, color: "orange", icon: true, onClick: () => {
                    setShowSidebar(!showSidebar);
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' }))),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: "left", id: "sticky-counterpart" },
                React.createElement("div", { className: "table-container" },
                    React.createElement(semantic_ui_react_1.Table, { fixed: true, unstackable: true, selectable: true, sortable: true, celled: true, className: "p-tags-table", style: { minWidth: '650px' } },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '50px' } }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-hover" }, "Merchant Name"),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-hover center-align table-cell-amount" }, "Transactions"),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-hover center-align table-cell-category" }, "Date Range"))),
                        React.createElement(semantic_ui_react_1.Table.Body, null,
                            isLoading && (React.createElement(Loader_1.default, { colSpan: 7, singleRow: true, text: "Fetching new transactions.." })),
                            !isLoading &&
                                (allNames.length === 0 ||
                                    (filter && visibleNames.length == 0)) && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: "7", className: "center-align" },
                                    React.createElement("div", { className: "empty-mascot" }),
                                    allNames.length == 0 ? (React.createElement(React.Fragment, null,
                                        React.createElement("p", null, "You have no transactions yet!"),
                                        React.createElement("p", null, "As you create transactions, merchants names will appear here."),
                                        React.createElement("p", null,
                                            "Get started at",
                                            ' ',
                                            React.createElement(react_router_dom_1.Link, { to: "/transactions" }, "the Transactions page")))) : (React.createElement(React.Fragment, null,
                                        React.createElement("p", null, "No merchant names match your filter."),
                                        React.createElement("p", { className: "link clickable", onClick: () => {
                                                setFilter('');
                                            } }, "Clear Filter")))))),
                            !isLoading &&
                                visibleNames
                                    .slice(skip, skip + limit)
                                    .map((merchant, index) => {
                                    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "transaction-row padded-row clickable", key: `merchant-key-${merchant.index}`, onClick: () => {
                                            if (isFetching) {
                                                return;
                                            }
                                            document.getSelection().removeAllRanges();
                                            let shouldSelectAll = false;
                                            let txsInRange = [];
                                            if ((shiftClicked === null || shiftClicked === void 0 ? void 0 : shiftClicked.current) &&
                                                lastSelectedIndex.current !== null) {
                                                // Is there a chance this is a deselect?
                                                // Only if the selected index is now unselected
                                                txsInRange = visibleNames
                                                    .slice(skip, skip + limit)
                                                    .slice(Math.min(index, lastSelectedIndex.current), Math.max(index + 1, lastSelectedIndex.current + 1));
                                                let modifiedIndex;
                                                if (index > lastSelectedIndex.current) {
                                                    modifiedIndex = index;
                                                }
                                                else {
                                                    modifiedIndex = index;
                                                }
                                                // If every single one is selected, then deselect
                                                const txsToTest = visibleNames
                                                    .slice(skip, skip + limit)
                                                    .slice(Math.min(modifiedIndex, lastSelectedIndex.current), Math.max(modifiedIndex, lastSelectedIndex.current));
                                                txsToTest.forEach(o => {
                                                    // If there is a single unselected, then we need to select all
                                                    if (!selectedNames.find(n => n.index == o.index)) {
                                                        shouldSelectAll = true;
                                                    }
                                                });
                                            }
                                            lastSelectedIndex.current = index;
                                            setAllNames(allNames.map((name, i) => {
                                                if (name.index == merchant.index) {
                                                    return Object.assign(Object.assign({}, name), { selected: !name.selected });
                                                }
                                                else if (shiftClicked.current) {
                                                    if (txsInRange.find(o => o.index == name.index)) {
                                                        return Object.assign(Object.assign({}, name), { selected: shouldSelectAll });
                                                    }
                                                }
                                                return name;
                                            }));
                                        } },
                                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                                            React.createElement("span", { className: `bulk-checkbox ${merchant.selected ? 'checked' : 'empty'}` })),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                        React.createElement(semantic_ui_react_1.Table.Cell, null, merchant.payee),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", mouseEnterDelay: 500, trigger: React.createElement(react_router_dom_1.Link, { to: {
                                                        pathname: `/transactions`,
                                                        search: qs.stringify({
                                                            start_date: merchant.min_date,
                                                            end_date: merchant.max_date,
                                                            payee_exact: merchant.payee,
                                                            time: 'custom',
                                                            match: 'all',
                                                        }),
                                                    }, className: "link clickable font--bold" }, merchant.count) }, "View transactions")),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" },
                                            merchant.count > 1 &&
                                                merchant.min_date !== merchant.max_date
                                                ? `${Moment(merchant.min_date).format(_user.getMonthDayYearFormat())} - `
                                                : '',
                                            Moment(merchant.max_date).format(_user.getMonthDayYearFormat()))));
                                }))),
                    React.createElement("div", { className: "pagination-container flex--space-between" },
                        React.createElement("div", { className: "pagination-amount" }, [25, 50, 100].map(option => {
                            return (React.createElement("a", { key: `limit-option-${option}`, onClick: () => {
                                    setLimit(option);
                                    setSkip(0);
                                }, className: `choice ${limit == option ? 'active' : ''}` }, option));
                        })),
                        Math.ceil(visibleNames.length / limit) > 1 && (React.createElement(semantic_ui_react_1.Pagination, { activePage: Math.floor(skip / limit) + 1, onPageChange: (e, { activePage }) => {
                                lastSelectedIndex.current = null;
                                setSkip((activePage - 1) * limit);
                            }, totalPages: Math.ceil(visibleNames.length / limit) }))))),
            React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile narrow'}` },
                React.createElement(Sticky_1.default, { reset: skip || limit ? new Date().getTime() : null, content: React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Card, null,
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                React.createElement(semantic_ui_react_1.Form, { widths: "equal", className: "mb-0" },
                                    React.createElement(semantic_ui_react_1.Form.Field, { className: "mb-05rem" },
                                        React.createElement(semantic_ui_react_1.Checkbox, { className: "mt-05rem", toggle: true, label: "Show similar names", checked: showSimilarOnly, onChange: (e, { checked }) => {
                                                setSkip(0);
                                                setShowSimilarOnly(checked);
                                            } }),
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "question circle" }) },
                                            React.createElement("p", null, "Filter results to only show payee names that are similar to other payee names and have a higher chance of being duplicates."),
                                            React.createElement("p", null, "Note: this is an experimental feature and may not detect all potential duplicates!")),
                                        React.createElement("p", { className: "divider" })),
                                    React.createElement(semantic_ui_react_1.Form.Field, { className: "mb-05rem" },
                                        React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: filter, location: 'modal', autosuggest: false, state: 'Editing', placeholder: "Type to filter...", shouldSaveOnChange: true, onSave: payee => {
                                                setFilter(payee);
                                            } })),
                                    React.createElement(semantic_ui_react_1.Form.Field, { className: "mt-1rem" },
                                        React.createElement("label", null,
                                            "Limit to transactions after",
                                            ' ',
                                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "question circle" }) },
                                                React.createElement("p", null, "Note: Transactions earlier than this date bearing the same payee names will be affected by any updates."))),
                                        React.createElement(EditableDate_1.default, { identifier: 'linked-account', format: 'month_day_year', firstValue: earliestDate, placeholder: 'No date limit', location: 'modal', state: 'Editing', onSave: (date) => __awaiter(void 0, void 0, void 0, function* () {
                                                if (date) {
                                                    setEarliestDate(Moment(date).format('YYYY-MM-DD'));
                                                }
                                                else {
                                                    setEarliestDate(null);
                                                }
                                            }) }))))),
                        React.createElement(semantic_ui_react_1.Card, null,
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                React.createElement(semantic_ui_react_1.Card.Header, null, "Selected Transactions")),
                            React.createElement(semantic_ui_react_1.Card.Content, null,
                                selectedNames.length < 1 && (React.createElement(semantic_ui_react_1.Message, { info: true }, "Start by selecting one or more merchant names.")), selectedNames === null || selectedNames === void 0 ? void 0 :
                                selectedNames.map(selected => {
                                    return (React.createElement("div", { className: "selected-payee-row", key: `selected-${selected.index}` },
                                        React.createElement(semantic_ui_react_1.Button, { size: "tiny", className: "link clickable mr-05rem", onClick: () => {
                                                setNewName(selected.payee);
                                            } }, "Use"),
                                        ' ',
                                        React.createElement("span", null, selected.payee)));
                                }),
                                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                                    React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: newName, location: 'modal', autosuggest: false, state: 'Editing', placeholder: "New merchant name", shouldSaveOnChange: true, onSave: payee => {
                                            setNewName(payee);
                                        } })),
                                React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: isFetching, disabled: isFetching || !newName || selectedNames.length < 1, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        if (localStorage.getItem('_lm_merchant_names_dont_confirm') == 'true') {
                                            submit();
                                        }
                                        else {
                                            setShowConfirm(true);
                                        }
                                    }) },
                                    "Update (",
                                    selectedNames.reduce((acc, cur) => {
                                        return cur.ids.length + acc;
                                    }, 0),
                                    ")"),
                                selectedNames.length > 0 && (React.createElement(React.Fragment, null,
                                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-05rem", onClick: () => {
                                            window.open(`/transactions?${qs.stringify({
                                                payee_exact: selectedNames.map(o => o.payee),
                                                time: 'all',
                                                match: 'any',
                                            })}`);
                                        }, icon: true },
                                        "View transactions ",
                                        React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" })),
                                    React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "orange", className: "mt-05rem", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            clear();
                                        }) }, "Clear Selected")))))) })),
            React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: showConfirm },
                React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm bulk update"),
                React.createElement(semantic_ui_react_1.Modal.Content, null,
                    React.createElement("p", null,
                        "Are you sure you want to update the following",
                        ' ',
                        _.flatten(selectedNames.map(tx => tx.ids)).length,
                        " transactions' merchant name to ",
                        React.createElement("b", null, newName),
                        "?"),
                    React.createElement("p", null,
                        React.createElement("ul", null, selectedNames.map(o => (React.createElement("li", { key: `selected-name-${o.index}` }, o.payee))))),
                    React.createElement("p", null, "Note: this is an irreversible action!")),
                React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Don't ask me again", onChange: (e, { checked }) => {
                            localStorage.setItem('_lm_merchant_names_dont_confirm', checked ? 'true' : null);
                        } }),
                    React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                                setShowConfirm(false);
                            } }, "No, cancel"),
                        React.createElement(semantic_ui_react_1.Button, { color: "green", onClick: submit }, "Yes, update")))))));
};
exports.default = MerchantNames;
