"use strict";
/**
 *  EditableCategory.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const EditableCategories = ({ initialValue = [], defaultOpen = false, onChange = null, showSelectAll = true, useName = false, placeholder = null, showUncategorized = false, allowGroups = true, disabled = [], }) => {
    const [categoryOptions, setCategoriesOptions] = (0, react_1.useState)([]);
    const [values, setValues] = (0, react_1.useState)(initialValue);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    (0, react_1.useEffect)(() => {
        const concat = [];
        if (showUncategorized) {
            concat.push({
                key: '___Uncategorized',
                value: -1,
                text: 'Uncategorized',
            });
        }
        setCategoriesOptions(concat.concat(_categories.flattened.map(o => {
            return {
                key: o.id,
                value: useName ? o.name : o.id,
                disabled: disabled.includes(o.id) || (!allowGroups && o.is_group),
                content: o.is_group ? (React.createElement("div", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "table", fitted: true }),
                    React.createElement("span", { className: "ml-05rem" }, o.name))) : o.group_id ? (React.createElement("div", { className: "flex--align-center" },
                    React.createElement("span", { className: "hierarchy-line-icon-2 multiple" }),
                    React.createElement("span", null, o.name))) : (React.createElement("span", null, o.name)),
                text: o.name,
            };
        })));
    }, [showUncategorized]);
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal dropdown-category-type", placeholder: placeholder || 'Search categories', fluid: true, multiple: true, defaultOpen: defaultOpen, selection: true, value: values || [], closeOnBlur: true, closeOnEscape: true, search: (options, query) => {
                const results = [];
                options.forEach(option => {
                    var _a;
                    try {
                        const category = _categories.get(option.value);
                        if (category &&
                            !category.is_group &&
                            ((_a = category.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(query.toLowerCase())) > -1) {
                            results.push(Object.assign(Object.assign({}, option), { text: category.name, content: category.group_id ? (React.createElement("span", { className: "text" },
                                    category.name,
                                    " (",
                                    category.group_category_name,
                                    ")")) : (option.content) }));
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                });
                return results.sort((a, b) => {
                    return a.text.localeCompare(b.text);
                });
            }, onChange: (e_1, _a) => __awaiter(void 0, [e_1, _a], void 0, function* (e, { value, options }) {
                const currentIds = value;
                if (onChange) {
                    onChange(currentIds);
                }
                setValues(currentIds);
            }), options: categoryOptions }),
        showSelectAll && (React.createElement("div", { className: "flex--end" },
            React.createElement("span", { onClick: () => {
                    // Get array of ids and call onChange
                    onChange(categoryOptions.filter(o => !o.disabled).map(o => o.value));
                    setValues(categoryOptions.filter(o => !o.disabled).map(o => o.value));
                }, className: "mt-05rem right-align link clickable" }, "Select all"),
            values && values.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "mr-05rem ml-05rem" }, " | "),
                React.createElement("span", { onClick: () => {
                        onChange([]);
                        setValues([]);
                    }, className: "mt-05rem right-align link clickable" }, "Deselect all")))))));
};
exports.default = EditableCategories;
