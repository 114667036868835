"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
;
``;
const React = require("react");
// Types
const react_1 = require("react");
// Modal screens
// Budgets
const SetBudgetView_1 = require("@components/Budget/Modal/SetBudgetView");
// Custom Budgets
const EditRolloverPool_1 = require("@/components/BudgetCustom/EditRolloverPool");
const Edit_1 = require("@components/BudgetCustom/Edit");
const BudgetSettings_1 = require("@components/BudgetCustom/BudgetSettings");
// Transactions
const GroupView_1 = require("@components/Transactions/Modal/GroupView");
const GroupEditTransaction_1 = require("@components/Transactions/Modal/GroupEditTransaction");
const NewTransactionView_1 = require("@components/Transactions/Modal/NewTransactionView");
const DetailsView_1 = require("@components/Transactions/Modal/DetailsView");
const RecurringView_1 = require("@components/Transactions/Modal/RecurringView");
const SplitView_1 = require("@components/Transactions/Modal/SplitView");
const AmortizeView_1 = require("@components/Transactions/Modal/AmortizeView");
const BulkEdit_1 = require("@components/Transactions/Modal/BulkEdit");
const CreateRecurringTransaction_1 = require("@components/Transactions/Modal/CreateRecurringTransaction");
const CreateTransfer_1 = require("@components/Transactions/Modal/CreateTransfer");
const CreateHalfTransfer_1 = require("@components/Transactions/Modal/CreateHalfTransfer");
const Log_1 = require("@components/Transactions/Modal/Log");
const PlaidMetadata_1 = require("@components/Transactions/Modal/PlaidMetadata");
// Categories
const MergeAndDelete_1 = require("@components/Categories/Modal/MergeAndDelete");
const AddCategoryGroup_1 = require("@components/Categories/Modal/AddCategoryGroup");
const EditCategory_1 = require("@components/Categories/Modal/EditCategory");
// Tags
const EditTag_1 = require("@components/Tags/Modal/EditTag");
// Recurring Expenses
const CreateModal_1 = require("@components/RecurringExpenses/CreateModal");
const EditModal_1 = require("@components/RecurringExpenses/EditModal");
const SuggestedModal_1 = require("@components/RecurringExpenses/SuggestedModal");
const MergeRecurring_1 = require("@components/RecurringExpenses/MergeRecurring");
// Linked Account
const TroubleshootAccount_1 = require("@/components/LinkedAccounts/TroubleshootAccount");
const AddModal_1 = require("@components/LinkedAccounts/AddModal");
const EditModal_2 = require("@components/LinkedAccounts/EditModal");
const MergeAccounts_1 = require("@components/LinkedAccounts/Modal/MergeAccounts");
// Rules
const AddModal_2 = require("@components/Rules/AddModal");
const SplitModal_1 = require("@components/Rules/SplitModal");
// Query Tool
const DataSetModal_1 = require("@components/QueryTool/DataSetModal");
const ModalProvider_1 = require("@providers/ModalProvider");
//
const DeleteAssetModal_1 = require("@components/LinkedAccounts/DeleteAssetModal");
const DeleteCryptoModal_1 = require("@components/LinkedAccounts/DeleteCryptoModal");
const DeleteSyncedCryptoModal_1 = require("@components/LinkedAccounts/DeleteSyncedCryptoModal");
const DeleteManualCryptoModal_1 = require("@components/LinkedAccounts/DeleteManualCryptoModal");
const NewModal = ({ show, setShow, data, view, utils, closeCallback }) => {
    const [header, setHeader] = (0, react_1.useState)(null);
    const [leftButton, setLeftButton] = (0, react_1.useState)(null);
    const [middleButton, setMiddleButton] = (0, react_1.useState)(null);
    const [rightButton, setRightButton] = (0, react_1.useState)(null);
    const [visible, setVisible] = (0, react_1.useState)(show);
    const [currentView, setCurrentView] = (0, react_1.useState)(view);
    const [disableOnClickOutside, setDisableOnClickOutside] = (0, react_1.useState)(false);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    // For clicking outside
    const ref = (0, react_1.useRef)();
    useOnClickOutside(ref, () => {
        if (!disableOnClickOutside) {
            setVisibility(false, true);
        }
    });
    useLockBodyScroll();
    const setVisibility = (visibility, skipCallback = false) => {
        // CSS close
        setVisible(visibility);
        // Propagate
        setTimeout(() => {
            setShow(visibility);
            if (!visibility) {
                // document.getElementById('g-footer-fixed').style.display = 'flex'
                // Reset modal data
                _modal.data.current = {};
                document.body.style.overflow = 'auto'; // fail safe?
            }
            if (!visibility && !skipCallback) {
                closeCallback();
            }
        }, 200);
    };
    const switchView = newView => {
        setCurrentView(newView);
        document.getElementById('modal-content').scrollTo(0, 0);
    };
    const props = {
        data,
        useModal: useModal(setHeader, setLeftButton, setMiddleButton, setRightButton),
        utils,
        setVisibility,
        setDisableOnClickOutside,
        switchView,
        _modal,
    };
    return (React.createElement("div", { className: `g-modal-container${visible ? ' visible' : ''}` },
        React.createElement("div", { className: "modal", ref: ref },
            React.createElement("div", { className: "modal-close clickable", onClick: () => {
                    setShow(false);
                } }),
            React.createElement("div", { className: "modal-header" }, header),
            React.createElement("div", { className: "modal-content", id: "modal-content" },
                currentView === 'SET_BUDGET_VIEW' && React.createElement(SetBudgetView_1.default, Object.assign({}, props)),
                currentView === 'EDIT_ROLLOVER_POOL' && (React.createElement(EditRolloverPool_1.default, Object.assign({}, props))),
                currentView === 'EDIT_CUSTOM_BUDGET' && (React.createElement(Edit_1.default, Object.assign({}, props))),
                currentView === 'EDIT_BUDGET_SETTINGS' && (React.createElement(BudgetSettings_1.default, Object.assign({}, props))),
                currentView === 'GROUP_VIEW' && React.createElement(GroupView_1.default, Object.assign({}, props)),
                currentView === 'GROUP_EDIT_TRANSACTION' && (React.createElement(GroupEditTransaction_1.default, Object.assign({}, props))),
                currentView === 'ADD_TRANSACTION' && (React.createElement(NewTransactionView_1.default, Object.assign({}, props))),
                currentView === 'DETAILS_VIEW' && React.createElement(DetailsView_1.default, Object.assign({}, props)),
                currentView === 'RECURRING_VIEW' && React.createElement(RecurringView_1.default, Object.assign({}, props)),
                currentView === 'SPLIT_VIEW' && React.createElement(SplitView_1.default, Object.assign({}, props)),
                currentView === 'AMORTIZE_VIEW' && React.createElement(AmortizeView_1.default, Object.assign({}, props)),
                currentView === 'CREATE_RECURRING_TRANSACTION' && (React.createElement(CreateRecurringTransaction_1.default, Object.assign({}, props))),
                currentView === 'CREATE_TRANSFER' && React.createElement(CreateTransfer_1.default, Object.assign({}, props)),
                currentView === 'CREATE_HALF_TRANSFER' && (React.createElement(CreateHalfTransfer_1.default, Object.assign({}, props))),
                currentView === 'VIEW_TRANSACTION_LOG' && (React.createElement(Log_1.default, Object.assign({}, props))),
                currentView === 'VIEW_PLAID_METADATA' && (React.createElement(PlaidMetadata_1.default, Object.assign({}, props))),
                currentView === 'MERGE_AND_DELETE' && React.createElement(MergeAndDelete_1.default, Object.assign({}, props)),
                currentView === 'ADD_CATEGORY_GROUP' && (React.createElement(AddCategoryGroup_1.default, Object.assign({}, props))),
                currentView === 'EDIT_CATEGORY' && React.createElement(EditCategory_1.default, Object.assign({}, props)),
                currentView === 'EDIT_TAG' && React.createElement(EditTag_1.default, Object.assign({}, props)),
                currentView === 'LINKED_ACCOUNT_VIEW' && (React.createElement(EditModal_2.default, Object.assign({}, props))),
                currentView === 'ADD_LINKED_ACCOUNT' && (React.createElement(AddModal_1.default, Object.assign({}, props))),
                currentView === 'LINKED_ACCOUNT_INACTIVE' && (React.createElement(TroubleshootAccount_1.default, Object.assign({}, props))),
                currentView === 'MERGE_ACCOUNTS' && React.createElement(MergeAccounts_1.default, Object.assign({}, props)),
                currentView === 'ASSET_DELETE' && React.createElement(DeleteAssetModal_1.default, Object.assign({}, props)),
                currentView === 'SYNCED_CRYPTO_DELETE' && (React.createElement(DeleteSyncedCryptoModal_1.default, Object.assign({}, props))),
                ' ',
                currentView === 'CRYPTO_DELETE' && React.createElement(DeleteCryptoModal_1.default, Object.assign({}, props)),
                currentView === 'MANUAL_CRYPTO_DELETE' && (React.createElement(DeleteManualCryptoModal_1.default, Object.assign({}, props))),
                currentView === 'BULK_EDIT' && React.createElement(BulkEdit_1.default, Object.assign({}, props)),
                currentView === 'ADD_RULE' && React.createElement(AddModal_2.default, Object.assign({}, props)),
                currentView === 'SPLIT_RULE' && React.createElement(SplitModal_1.default, Object.assign({}, props)),
                currentView === 'EDIT_RECURRING' && React.createElement(EditModal_1.default, Object.assign({}, props)),
                currentView === 'SUGGESTED_RECURRING' && (React.createElement(SuggestedModal_1.default, Object.assign({}, props))),
                currentView === 'MERGE_RECURRING' && React.createElement(MergeRecurring_1.default, Object.assign({}, props)),
                currentView === 'ADD_RECURRING' && React.createElement(CreateModal_1.default, Object.assign({}, props)),
                currentView === 'EDIT_QUERY_FILTERS' && React.createElement(DataSetModal_1.default, Object.assign({}, props))),
            React.createElement("div", { className: "modal-footer" },
                React.createElement("div", null, leftButton),
                React.createElement("div", null,
                    middleButton,
                    rightButton)))));
};
function useModal(setHeader, setLeftButton, setMiddleButton, setRightButton) {
    const setModal = (components) => {
        if (components.header) {
            setHeader(components.header);
        }
        if (components.leftButton) {
            setLeftButton(components.leftButton);
        }
        if (components.rightButton) {
            setRightButton(components.rightButton);
        }
        if (components.middleButton) {
            setMiddleButton(components.middleButton);
        }
    };
    return setModal;
}
function useLockBodyScroll() {
    (0, react_1.useLayoutEffect)(() => {
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);
}
function useOnClickOutside(ref, handler) {
    (0, react_1.useEffect)(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
exports.default = NewModal;
