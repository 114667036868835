"use strict";
/**
 *  Create.tsx
 *  Custom budgets add modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const EditableDate_1 = require("../elements/EditableDate");
const user_1 = require("@/actions/user");
const EditRolloverPool = ({ useModal, setVisibility, setDisableOnClickOutside, switchView, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [resetDate, setResetDate] = (0, react_1.useState)(_user.budgetPeriodSettings.budget_rollover_pool_start_date);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: (React.createElement("div", null,
                React.createElement("span", { className: "header-text-large" }, "Edit Rollover Pool"))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, []);
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        // Update budget settings
                        yield (0, user_1.updateAccountPreferences)({
                            budget_rollover_pool_start_date: resetDate,
                        });
                        yield _user.fetchAccountSettings();
                        setVisibility(false);
                    }) }, "Save Changes"))),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [resetDate]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement("p", null, "This represents where over- and under-spending from previous periods accumulates when you have at least one category set to rollover to general pool."),
            React.createElement("p", null,
                "You can see the exact calculations by hovering over",
                ' ',
                React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }),
                "."),
            !!_user.budgetPeriodSettings.budget_rollover_start_date && (React.createElement("p", null,
                React.createElement("b", null,
                    "The rollover starts calculating from",
                    ' ',
                    Moment(_user.budgetPeriodSettings.budget_rollover_start_date).format(_user.getFormat('month_day_year')),
                    "."),
                ' ',
                "To update this, go to",
                ' ',
                React.createElement(semantic_ui_react_1.Button, { className: "ml-05rem", size: "mini", onClick: () => {
                        switchView('EDIT_BUDGET_SETTINGS');
                    } }, "Budget Settings")))),
        !_user.budgetPeriodSettings.budget_rollover_start_date && (React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Start Date"),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement(EditableDate_1.default, { identifier: "recurring-billing-date", firstValue: resetDate, state: 'Editing', location: 'modal', format: 'month_day_year', onSave: date => {
                                    setDisableOnClickOutside(true);
                                    setResetDate(date);
                                } })))))))));
};
exports.default = EditRolloverPool;
