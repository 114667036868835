"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const DEV_URL = 'http://localhost:3002';
const ALPHA_URL = 'https://api-alpha.lunchmoney.app';
const BETA_URL = 'https://api-beta.lunchmoney.app';
const STAGING_URL = 'https://api-staging.lunchmoney.app';
const PROD_URL = 'https://api.lunchmoney.app';
let API_URL = DEV_URL;
if (window.location.href.indexOf('staging.lunchmoney') >= 0) {
    API_URL = STAGING_URL;
}
else if (window.location.href.indexOf('alpha.lunchmoney') >= 0) {
    API_URL = ALPHA_URL;
}
else if (window.location.href.indexOf('beta.lunchmoney') >= 0) {
    API_URL = BETA_URL;
}
else if (window.location.href.indexOf('my.lunchmoney') >= 0) {
    API_URL = PROD_URL;
}
exports.default = API_URL;
