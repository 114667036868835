"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.simulate = exports.sendEmailAsAdmin = exports.notifyRelink = exports.sendAnnualSummary = exports.addToBeta = exports.addCrypto = exports.processDeletion = exports.runAccountDeletions = exports.cancelAccount = exports.updateUser = exports.getUser = exports.getUserData = exports.getLatest = exports.pullTransactions = void 0;
const common_1 = require("./common");
const pullTransactions = obj => {
    return (0, common_1.post)(`admin/pull_transactions`, obj);
};
exports.pullTransactions = pullTransactions;
const getLatest = opts => {
    return (0, common_1.post)(`admin/get_latest/`, opts);
};
exports.getLatest = getLatest;
const getUserData = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/get_user_data`, obj);
});
exports.getUserData = getUserData;
const getUser = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/user`, obj);
});
exports.getUser = getUser;
const updateUser = (id, obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`admin/user/${id}`, obj);
});
exports.updateUser = updateUser;
const cancelAccount = (email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`admin/cancel`, { email });
});
exports.cancelAccount = cancelAccount;
const runAccountDeletions = (user_ids) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/init_user_deletion`, { user_ids });
});
exports.runAccountDeletions = runAccountDeletions;
const processDeletion = (user_ids) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`admin/delete`, { user_ids });
});
exports.processDeletion = processDeletion;
const addCrypto = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/add_crypto`, obj);
});
exports.addCrypto = addCrypto;
const addToBeta = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/add_beta`, obj);
});
exports.addToBeta = addToBeta;
const sendAnnualSummary = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/send_annual_summary`, obj);
});
exports.sendAnnualSummary = sendAnnualSummary;
const notifyRelink = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/notify_relink`, {});
});
exports.notifyRelink = notifyRelink;
const sendEmailAsAdmin = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`support/send_email`, body);
});
exports.sendEmailAsAdmin = sendEmailAsAdmin;
const simulate = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`admin/simulate`, body);
});
exports.simulate = simulate;
