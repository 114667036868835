"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_router_dom_1 = require("react-router-dom");
const account_1 = require("@actions/account");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const react_router_dom_2 = require("react-router-dom");
const AccountSetupOptions_1 = require("@components/global/AccountSetupOptions");
const EditableString_1 = require("@components/elements/EditableString");
const UserProvider_1 = require("@providers/UserProvider");
const SwitchAccount = ({ open, onClose }) => {
    var _a;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_2.useHistory)();
    const [openModal, setOpenModal] = (0, react_1.useState)(false);
    const [showSetup, setShowSetup] = (0, react_1.useState)(false);
    const [showSetupExplainer, setShowSetupExplainer] = (0, react_1.useState)(false);
    const [selectedAccount, setSelectedAccount] = (0, react_1.useState)((_a = _user.allAccounts) === null || _a === void 0 ? void 0 : _a.find(o => o.current));
    const [budgetName, setBudgetName] = (0, react_1.useState)('');
    const [showField, setShowField] = (0, react_1.useState)(false);
    const [isLoadingId, setIsLoadingId] = (0, react_1.useState)(null);
    const [allAccounts, setAllAccounts] = (0, react_1.useState)(_user.allAccounts);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [showTooltip, setShowTooltip] = (0, react_1.useState)(true);
    const [copyCategories, setCopyCategories] = (0, react_1.useState)(false);
    const [copyTags, setCopyTags] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (location.hash == '#new-budget-created') {
            setShowSetup(true);
            setShowSetupExplainer(true);
        }
    }, [location.hash]);
    (0, react_1.useEffect)(() => {
        var _a;
        setAllAccounts(_user.allAccounts);
        setSelectedAccount((_a = _user.allAccounts) === null || _a === void 0 ? void 0 : _a.find(o => o.current));
    }, [_user.allAccounts]);
    const createNewBudget = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        // Create the new budget
        yield (0, account_1.createNew)({
            name: budgetName,
            replace_cookies: true,
            copy_categories: copyCategories,
            copy_tags: copyTags,
        });
        // Trigger refresh for other open tabs
        localStorage.setItem('_lm_account_switched', new Date().getTime().toString());
        if (copyCategories || copyTags) {
            // go straight there
            window.location.href = '/overview';
        }
        else {
            // Show account setup options
            setShowSetupExplainer(false);
            setShowSetup(true);
            // Close current modal
            setIsLoading(false);
            onClose();
        }
    });
    const switchToSelectedBudget = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (account_id = null) {
        yield (0, account_1.switchAccount)(account_id || selectedAccount.account_id);
        localStorage.setItem('_lm_account_switched', new Date().getTime().toString());
        window.location.reload();
    });
    (0, react_1.useEffect)(() => {
        if (open) {
            _user.fetchAccountDetails();
        }
        setOpenModal(open);
    }, [open]);
    const renderBudgetRow = account => {
        return (React.createElement("div", { key: `account-${account.account_id}`, className: `help-row ${account.status == 'deleting' || account.status == 'resetting'
                ? 'disabled'
                : ''} ${account.pending ? 'pending' : ''} ${(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.account_id) == account.account_id ? 'active' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                if (account.status !== 'deleting' &&
                    account.status !== 'resetting' &&
                    !account.pending) {
                    setSelectedAccount(account);
                    setShowField(false);
                    setBudgetName(null);
                }
            }) },
            React.createElement("div", null,
                React.createElement("div", { className: "account-icon-letter" },
                    React.createElement("span", { className: "letter" }, Array.from(account.account_display_name || '')[0]))),
            React.createElement("div", { className: "display--flex flex-grow flex--column" },
                React.createElement("span", null,
                    account.account_display_name,
                    ' ',
                    account.is_demo ? React.createElement(React.Fragment, null, "(demo)") : React.createElement(React.Fragment, null),
                    account.status == 'deleting' || account.status == 'resetting' ? (React.createElement(React.Fragment, null,
                        "(",
                        account.status,
                        ")")) : (React.createElement(React.Fragment, null)),
                    (account === null || account === void 0 ? void 0 : account.current) ? ' (Current)' : ''),
                (account === null || account === void 0 ? void 0 : account.pending) ? (React.createElement("span", { className: "smaller" },
                    "Invited by",
                    ' ', account === null || account === void 0 ? void 0 :
                    account.collaborators.find(o => o.access_level == 'admin').name,
                    ' ',
                    "on ",
                    Moment(account.invited_on).format('ll'))) : (React.createElement("span", { className: "smaller" },
                    "Created ",
                    Moment(account.created_at).format('ll'),
                    " by",
                    ' ',
                    account.access_level == 'admin'
                        ? 'you'
                        : account === null || account === void 0 ? void 0 : account.collaborators.find(o => o.access_level == 'admin').name))),
            (account === null || account === void 0 ? void 0 : account.pending) && (React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { type: "button", color: "green", size: "mini", loading: isLoadingId == account.account_id, disabled: isLoadingId == account.account_id, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoadingId(account.account_id);
                        yield (0, account_1.respondCollaboratorInvite)(account.account_id, 'accept');
                        yield _user.fetchAccountDetails();
                        setIsLoadingId(null);
                    }) }, "Accept"),
                React.createElement(semantic_ui_react_1.Button, { type: "button", color: "red", size: "mini", loading: isLoadingId == account.account_id, disabled: isLoadingId == account.account_id, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoadingId(account.account_id);
                        yield (0, account_1.respondCollaboratorInvite)(account.account_id, 'reject');
                        yield _user.fetchAccountDetails();
                        setIsLoadingId(null);
                    }) }, "X")))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Modal, { open: openModal, onClose: () => {
                history.replace({
                    search: null,
                });
                setShowField(false);
                setShowTooltip(true);
                setBudgetName(null);
                onClose();
            }, size: "tiny", dimmer: "inverted" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Switch Accounts"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("h3", null, "Budgets you own"),
                !_user.hasStripeAccount && _user.type !== 'early-adopter' && (React.createElement(semantic_ui_react_1.Message, { info: true }, "To create your own budget, you will need a new paid subscription. We will start you off with a free 14-day trial plus an extra free month!")), allAccounts === null || allAccounts === void 0 ? void 0 :
                allAccounts.filter(o => o.access_level == 'admin').map(account => renderBudgetRow(account)),
                React.createElement("div", { className: `help-row ${showField ? 'active' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setSelectedAccount(null);
                        setShowField(true);
                    }) },
                    React.createElement(semantic_ui_react_1.Icon, { name: "add", size: "large" }),
                    showField ? (React.createElement("div", null,
                        React.createElement(semantic_ui_react_1.Popup, { position: "top left", inverted: true, hideOnScroll: true, size: 'small', open: showTooltip, trigger: React.createElement(EditableString_1.default, { identifier: `new-budget-name`, location: 'inline', firstValue: budgetName, state: 'Editing', className: ' font-size--larger ', autoFocus: true, shouldSaveOnBlur: false, shouldSaveOnEnter: true, shouldSaveOnChange: true, shouldSelectOnFocus: false, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                                    setShowTooltip(false);
                                    setBudgetName(name);
                                }), onEnter: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield createNewBudget();
                                }) }) }, "Hint: You can use an emoji as the first character to set a budget icon!"),
                        React.createElement(semantic_ui_react_1.Form, { className: "mt-1rem mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Group, { className: "mb-0" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: copyCategories, onChange: (e, { checked }) => {
                                            setCopyCategories(checked);
                                        }, label: "Copy categories from current budget" }),
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: copyTags, onChange: (e, { checked }) => {
                                            setCopyTags(checked);
                                        }, label: "Copy tags from current budget" })))))) : (React.createElement("span", null, "Create new budget account"))),
                (allAccounts === null || allAccounts === void 0 ? void 0 : allAccounts.filter(o => o.access_level !== 'admin').length) ? (React.createElement("h3", null, "Budgets you collaborate on")) : (React.createElement(React.Fragment, null)), allAccounts === null || allAccounts === void 0 ? void 0 :
                allAccounts.filter(o => o.access_level !== 'admin').map(account => renderBudgetRow(account))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => {
                        history.replace({
                            search: null,
                        });
                        setShowField(false);
                        setShowTooltip(true);
                        setBudgetName(null);
                        onClose();
                    } }, "Close"),
                showField && (React.createElement(semantic_ui_react_1.Button, { primary: true, loading: isLoading, disabled: isLoading || !budgetName || budgetName.length == 0, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield createNewBudget();
                    }) }, "Create new budget")),
                !!selectedAccount && !(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.current) && (React.createElement(semantic_ui_react_1.Button, { primary: true, loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield switchToSelectedBudget();
                    }) },
                    "Go to",
                    ' ',
                    _.truncate(selectedAccount.account_display_name, { length: 18 }))))),
        React.createElement(AccountSetupOptions_1.default, { open: showSetup, showExplainer: showSetupExplainer })));
};
exports.default = SwitchAccount;
