"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const TagsProvider_1 = require("@providers/TagsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const FilterRow = ({ index, duplicate, remove, allFilters }) => {
    const MATCH_TYPES = [
        { key: 'exact', value: 'exact', text: 'matches exactly' },
        { key: 'contain', value: 'contain', text: 'contains' },
        { key: 'start_with', value: 'start_with', text: 'starts with' },
    ];
    const RELATION_TYPES = [
        { key: 'is', value: 'is', text: 'is' },
        { key: 'is not', value: 'is not', text: 'is not' },
    ];
    const AMOUNT_MATCH_TYPES = [
        { key: 'greater_than', value: 'greater_than', text: 'greater than' },
        {
            key: 'greater_than_or_equal_to',
            value: 'greater_equal',
            text: 'greater than or equal to',
        },
        { key: 'less_than', value: 'less_than', text: 'less than' },
        {
            key: 'less_than_or_equal_to',
            value: 'less_equal',
            text: 'less than or equal to',
        },
        { key: 'exactly', value: 'exactly', text: 'exactly' },
        { key: 'between', value: 'between', text: 'between' },
    ];
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const filters = [
        { value: 'general search', disabled: false },
        { value: 'by Field', disabled: true },
        { value: 'payee', disabled: false },
        { value: 'category', disabled: false },
        { value: 'notes', disabled: false },
        { value: 'tag', disabled: false },
        { value: 'account', disabled: false },
        { value: 'by Property', disabled: true },
        { value: 'recurring', disabled: false },
        { value: 'status', disabled: false },
        { value: 'type', disabled: false },
        { value: 'file', disabled: false },
        { value: 'by Amount', disabled: true },
        { value: 'expense', disabled: false },
        { value: 'income', disabled: false },
    ];
    const types = ['group', 'split', 'part of group'];
    const sources = [
        'plaid',
        'csv',
        'manual',
        'api',
        'user',
        'rule',
        'merge',
        'recurring',
    ];
    const statuses = ['unreviewed', 'reviewed', 'pending', 'delete pending'];
    const [criteria, setCriteria] = (0, react_1.useState)(null);
    const [relation, setRelation] = (0, react_1.useState)(null);
    const [value, setValue] = (0, react_1.useState)(undefined);
    const [value2, setValue2] = (0, react_1.useState)(undefined);
    const [currency, setCurrency] = (0, react_1.useState)(null);
    const [options, setOptions] = (0, react_1.useState)([]);
    const [showError, setShowError] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        var _a;
        if (allFilters.current.hasOwnProperty(index)) {
            setCriteria(allFilters.current[index].criteria);
            setRelation(allFilters.current[index].relation);
            if (allFilters.current[index].criteria == 'account') {
                if (!allFilters.current[index].value ||
                    ((_a = allFilters.current[index].value) === null || _a === void 0 ? void 0 : _a.toString().indexOf('plaid')) > -1) {
                    setValue(allFilters.current[index].value);
                }
                else {
                    setValue(parseInt(allFilters.current[index].value));
                }
            }
            else {
                setValue(allFilters.current[index].value);
                setValue2(allFilters.current[index].value2);
                setCurrency(allFilters.current[index].currency);
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (criteria === 'status') {
            setOptions(statuses.map(status => {
                return {
                    key: status,
                    value: status,
                    text: status,
                };
            }));
        }
        else if (criteria === 'type') {
            setOptions(types.map(type => {
                return {
                    key: type,
                    value: type,
                    text: type,
                };
            }));
        }
        else if (criteria === 'tag') {
            setOptions([
                {
                    key: 'untagged',
                    value: 'untagged',
                    text: '(untagged)',
                },
                ..._tags.tags.map(tag => {
                    return {
                        key: `tag-${tag.id}`,
                        value: tag.id,
                        text: tag.name,
                    };
                }),
            ]);
        }
        else if (criteria === 'recurring') {
            setOptions([
                {
                    key: 'recurring-any',
                    value: 'any',
                    text: '(any)',
                },
                {
                    key: 'recurring-suggested',
                    value: 'suggested',
                    text: '(suggested)',
                },
                ..._recurring.dropdownOptions,
            ]);
        }
        else if (criteria === 'file') {
            setOptions([
                {
                    key: 'attached',
                    value: 'attached',
                    text: 'attached',
                },
            ]);
        }
    }, [criteria]);
    return (React.createElement("div", { className: "filter-row" },
        React.createElement("div", { className: "display--flex" },
            React.createElement("div", { className: "filter-row-criteria" },
                React.createElement(semantic_ui_react_1.Dropdown
                // defaultOpen={criteria == null}
                , { 
                    // defaultOpen={criteria == null}
                    selection: true, className: "filter-row-dropdown filter-row-dropdown-criteria", value: criteria == 'asset' ? 'account' : criteria, placeholder: "Choose filter", options: filters.map(filter => {
                        return {
                            disabled: filter.disabled,
                            key: filter.value,
                            value: filter.value,
                            text: (0, format_1.capitalize)(filter.value),
                        };
                    }), onChange: (e, { value }) => {
                        // If value is file and there's already a file, reject
                        if (value == 'file') {
                            if (!!Object.values(allFilters.current).find(o => o['criteria'] == 'file')) {
                                setShowError('file');
                                return;
                            }
                        }
                        else if (value == 'general search') {
                            if (!!Object.values(allFilters.current).find(o => o['criteria'] == 'general search')) {
                                setShowError('General search');
                                return;
                            }
                        }
                        setCriteria(value);
                        setValue(null);
                        let relation = 'is';
                        if (value === 'payee' ||
                            value === 'notes' ||
                            value === 'general search') {
                            relation = 'contain';
                        }
                        else if (['expense', 'income'].indexOf(value) > -1) {
                            relation = 'exactly';
                        }
                        else if (value == 'file') {
                            setValue('attached');
                        }
                        else if (value == 'account') {
                            // Defaults to cash
                            setValue('cash');
                        }
                        setRelation(relation);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: {
                                criteria: value,
                                relation: relation,
                                value: value == 'file'
                                    ? 'attached'
                                    : value == 'account'
                                        ? 'cash'
                                        : null,
                                value2: null,
                            } });
                    } })),
            criteria && (React.createElement("div", { className: "filter-row-relation" }, criteria === 'payee' ||
                criteria === 'notes' ||
                ['expense', 'income'].indexOf(criteria) > -1 ||
                criteria === 'general search' ? (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-1rem mr-1rem p-match-inline width-100", inline: true, disabled: criteria == 'general search', value: relation ||
                    (['expense', 'income'].indexOf(criteria) > -1
                        ? 'exactly'
                        : 'contain'), options: ['expense', 'income'].indexOf(criteria) > -1
                    ? AMOUNT_MATCH_TYPES
                    : MATCH_TYPES, onChange: (e, { value }) => {
                    setRelation(value);
                    allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { relation: value }) });
                } })) : (React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-1rem mr-1rem p-match-inline filter-row-relation", inline: true, value: relation || 'is', options: RELATION_TYPES, onChange: (e, { value }) => {
                    setRelation(value);
                    allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { relation: value }) });
                } }))))),
        React.createElement("div", { className: "display--flex flex--align-center flex-grow" },
            criteria && (React.createElement("div", { className: "filter-row-value" },
                (criteria === 'payee' ||
                    criteria === 'notes' ||
                    criteria === 'general search') && (React.createElement(EditableString_1.default, { placeholder: 'Enter value', identifier: `bulk-edit-name`, firstValue: value, shouldSaveOnChange: true, location: 'modal', state: 'Editing', autosuggest: criteria === 'payee', onSave: name => {
                        setValue(name);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: name }) });
                    } })),
                criteria === 'category' && (React.createElement(EditableCategory_1.default, { identifier: `filter-category-${index}`, location: 'filter-row', className: "filter-row-dropdown filter-row-dropdown-value", firstValue: value, state: 'Editing', placeholder: "Select value", allowAdditions: false, allowSelectingGroups: true, showUncategorized: false, hideArchived: false, extraValues: [
                        {
                            key: 'uncategorized',
                            value: 'uncategorized',
                            text: '(uncategorized)',
                        },
                        {
                            key: 'income',
                            value: 'income',
                            text: '(treat as income)',
                        },
                        {
                            key: 'exclude_from_totals',
                            value: 'exclude_from_totals',
                            text: '(exclude from totals)',
                        },
                        {
                            key: 'exclude_from_budget',
                            value: 'exclude_from_budget',
                            text: '(exclude from budget)',
                        },
                    ], onSave: (category_id) => __awaiter(void 0, void 0, void 0, function* () {
                        setValue(category_id);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: category_id }) });
                    }) })),
                ['expense', 'income'].indexOf(criteria) > -1 &&
                    relation !== 'between' && (React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: value, currency: currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, saveOnError: true, allowNegative: false, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                        setValue(amount);
                        setCurrency(currency);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: amount, value2: null, currency }) });
                    }) })),
                (criteria === 'account' || criteria === 'asset') && (React.createElement(EditableAsset_1.default, { assignableOnly: false, location: 'filter-row', className: "filter-row-dropdown filter-row-dropdown-value", defaultSelection: {
                        source: criteria == 'account'
                            ? 'plaid'
                            : value == 'cash'
                                ? null
                                : 'manual',
                        id: value == 'cash' ? -1 : parseInt(value),
                    }, onSave: value => {
                        setValue(value.id);
                        setCriteria(value.source == 'plaid' ? 'account' : 'asset');
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { criteria: value.source == 'plaid' ? 'account' : 'asset', value: value.id == -1 ? 'cash' : value.id }) });
                    } })),
                ['status', 'type', 'tag', 'recurring', 'file'].indexOf(criteria) >
                    -1 && (React.createElement(semantic_ui_react_1.Dropdown, { className: "filter-row-dropdown filter-row-dropdown-value", search: true, fluid: true, selection: true, disabled: criteria == 'file', value: value, placeholder: `Select ${criteria}`, options: options, onChange: (e, { value }) => {
                        setValue(value);
                        allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value }) });
                    } })),
                ['expense', 'income'].indexOf(criteria) > -1 &&
                    relation === 'between' && (React.createElement("div", { className: "flex--align-center" },
                    React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: value, currency: currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, saveOnError: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setValue(amount);
                            setCurrency(currency);
                            allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value: amount, currency }) });
                        }) }),
                    React.createElement("span", { className: "mr-1rem ml-05rem monospace" }, "&"),
                    React.createElement(EditablePrice_1.default, { identifier: `criteria-amount-2`, amount: value2, currency: currency, location: 'modal', state: 'Editing', saveOnChange: true, allowNegative: false, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setValue2(amount);
                            setCurrency(currency);
                            allFilters.current = Object.assign(Object.assign({}, allFilters.current), { [index]: Object.assign(Object.assign({}, allFilters.current[index]), { value2: amount, currency }) });
                        }) }))))),
            React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, disabled: criteria == 'file' || criteria == 'general search', color: "orange", size: "small", className: "ml-05rem", onClick: () => {
                    duplicate({
                        criteria,
                        relation,
                        value,
                        value2,
                        currency,
                    });
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "copy outline" })),
            React.createElement(semantic_ui_react_1.Button, { icon: true, basic: true, color: "red", size: "small", onClick: () => {
                    remove(index);
                    delete allFilters.current[index];
                } },
                React.createElement(semantic_ui_react_1.Icon, { name: "x" }))),
        React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: !!showError },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Error"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                "You may only have one \"",
                showError,
                "\" criteria."),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowError(null);
                    } }, "Okay")))));
};
exports.default = FilterRow;
