"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@/providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const format_1 = require("@/helpers/format");
const budget_1 = require("@/helpers/budget");
const OneClickBudgetBtn = ({ budgets, onSaveBudget, periodEnd }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [showButton, setShowButton] = (0, react_1.useState)(false);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [autoBudgetActions, setAutoBudgetActions] = (0, react_1.useState)([]);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setAutoBudgetActions(_.flatten(budgets.outflow
            .filter(o => {
            return !o.properties.category.is_group && !o.totals.budgeted;
        })
            .map(budget => {
            /**
             * Auto-budgeting
             */
            if (budget.properties.hasOwnProperty('auto_budget') &&
                budget.properties.auto_budget.type !== 'nothing') {
                const autoBudgetType = budget.properties.auto_budget.type;
                const autoBudgetAmount = budget.properties.auto_budget.amount;
                const autoBudgetCurrency = budget.properties.auto_budget.currency || _user.primaryCurrency;
                return budget.occurrences
                    .filter(o => !o.historical)
                    .map((occurrence, index) => {
                    let result = null;
                    if (autoBudgetType == 'fixed') {
                        result = { amount: autoBudgetAmount };
                    }
                    else if (autoBudgetType == 'fill') {
                        result = (0, budget_1.calculateBudgetSuggestions)(budget.occurrences, occurrence, ['fill'], budget.properties);
                    }
                    else if (autoBudgetType == 'spend') {
                        result = (0, budget_1.calculateBudgetSuggestions)(budget.occurrences, occurrence, ['spend']);
                    }
                    else if (autoBudgetType == 'budget') {
                        result = (0, budget_1.calculateBudgetSuggestions)(budget.occurrences, occurrence, ['budget']);
                    }
                    else if (autoBudgetType == 'average-3') {
                        result = (0, budget_1.calculateBudgetSuggestions)(budget.occurrences, occurrence, ['average-3']);
                    }
                    return {
                        amount: (result === null || result === void 0 ? void 0 : result.amount) || null,
                        currency: autoBudgetCurrency,
                        category_id: budget.properties.category.id,
                        start_date: occurrence.start_date,
                        override: occurrence.budgeted !== null,
                        skip: occurrence.budgeted !== null || (result === null || result === void 0 ? void 0 : result.length) == 0,
                        type: autoBudgetType,
                        auto_amount: autoBudgetAmount,
                    };
                });
            }
            else {
                const occurrence = budget.occurrences.find(o => !o.historical);
                const results = (0, budget_1.calculateBudgetSuggestions)(budget.occurrences, occurrence, ['balance'], budget.properties);
                if ((results === null || results === void 0 ? void 0 : results.amount) > 0) {
                    return {
                        start_date: occurrence.start_date,
                        category_id: budget.properties.category.id,
                        amount: results.amount,
                        currency: _user.primaryCurrency,
                        skip: false,
                        override: false,
                        type: 'auto-balance',
                    };
                }
            }
        })).filter(o => !!o && !!o.amount));
    }, [budgets]);
    (0, react_1.useEffect)(() => {
        setShowButton((autoBudgetActions === null || autoBudgetActions === void 0 ? void 0 : autoBudgetActions.length) > 0);
    }, [autoBudgetActions]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onSaveBudget(autoBudgetActions.filter(o => !o.skip));
        setAutoBudgetActions([]);
    });
    return (React.createElement(React.Fragment, null,
        showButton && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", loading: buttonIsLoading, disabled: buttonIsLoading, color: "orange", className: "mb-05rem width-100", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (localStorage.getItem('_lm_auto_budget_dont_confirm') == 'true') {
                        setButtonIsLoading(true);
                        yield submit();
                        setButtonIsLoading(false);
                    }
                    else {
                        setShowModal(true);
                    }
                }) },
                React.createElement(semantic_ui_react_1.Icon, { name: "magic" }),
                React.createElement("span", { className: "position-top-1-5px" }, "Set one-click budgets")), inverted: true, size: "small", position: "left center" },
            "Click to set ",
            autoBudgetActions.filter(o => !o.skip).length,
            " budget",
            autoBudgetActions.filter(o => !o.skip).length !== 1 ? 's' : '',
            ' ',
            "totalling",
            ' ',
            (0, format_1.toPrice)(autoBudgetActions
                .filter(o => !o.skip)
                .reduce((sum, o) => sum + o.amount, 0), _user.primaryCurrency))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showModal, onClose: () => {
                setShowModal(false);
            }, closeOnEscape: true, closeOnDimmerClick: true, size: "tiny" },
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    "You are about the confirm all the budget suggestions for remaining categories with no current budgeted value, and with auto-budget set up:",
                    React.createElement("ul", null, autoBudgetActions.map(o => {
                        return (React.createElement("li", null,
                            "Set ",
                            React.createElement("b", null, _categories.getName(o.category_id)),
                            " to",
                            ' ',
                            (0, format_1.toPrice)(o.amount, o.currency)));
                    })))),
            React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Don't ask me again", onChange: (e, { checked }) => {
                        localStorage.setItem('_lm_auto_budget_dont_confirm', checked ? 'true' : null);
                    } }),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button, { basic: autoBudgetActions.length > 0, onClick: () => {
                            setShowModal(false);
                        }, color: "orange" }, "Cancel"),
                    autoBudgetActions.length > 0 && (React.createElement(semantic_ui_react_1.Button, { disabled: autoBudgetActions.filter(o => !o.skip).length == 0, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield submit();
                            setShowModal(false);
                        }) }, "Confirm")))))));
};
exports.default = OneClickBudgetBtn;
