"use strict";
/**
 *  Billing.tsx
 */
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const Plans_1 = require("@components/Billing/Plans");
const Cancellation_1 = require("@components/Billing/Cancellation");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const BillingProvider_1 = require("@providers/BillingProvider");
const UserProvider_1 = require("@providers/UserProvider");
const SubscriptionDetails_1 = require("@/components/Billing/SubscriptionDetails");
const Billing = ({ _showToast }) => {
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const subscriptionUser = _billing === null || _billing === void 0 ? void 0 : _billing.user;
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Billing - Lunch Money';
    }, []);
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Billing & Subscription" }),
        !_user.isAdmin && (React.createElement(semantic_ui_react_1.Message, { error: true, className: "display--block mt-0 mb-1rem" }, "You are not the owner of this budget and may not make any changes to the billing of this account.")),
        _user.isAdmin &&
            subscriptionUser &&
            subscriptionUser.type == 'early-adopter' ? (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { success: true, className: "display--block mt-0 mb-1rem" },
                React.createElement(semantic_ui_react_1.Header, { as: "h2", icon: true, textAlign: "center" },
                    React.createElement("div", { className: "mt-1rem mb-1rem yellow-mascot" }),
                    React.createElement(semantic_ui_react_1.Header.Content, null, "You've got Lunch Money for free for life!")),
                React.createElement(semantic_ui_react_1.Header, { as: "h3", textAlign: "center" },
                    React.createElement("p", { className: "mt-1rem mb-1rem" }, "Thank you for your support! \u2764\uFE0F"))),
            React.createElement("div", { id: "g-billing" },
                React.createElement("div", null,
                    React.createElement(Cancellation_1.default, null))))) : null,
        subscriptionUser && subscriptionUser.type !== 'early-adopter' ? (React.createElement(React.Fragment, null,
            _user.isAdmin &&
                subscriptionUser &&
                subscriptionUser.stripe_status == 'no subscription' && (React.createElement(semantic_ui_react_1.Message, { error: true, className: "display--block mt-0 mb-1rem" }, "You do not currently have a subscription. Choose a plan below to start your subscription again and regain access to your account.")),
            React.createElement("div", { id: "g-billing" },
                React.createElement("div", null,
                    React.createElement(Plans_1.default, null)),
                React.createElement("div", null,
                    !(_user.isAdmin &&
                        subscriptionUser &&
                        subscriptionUser.stripe_status == 'no subscription') && (React.createElement("div", { className: "mb-1rem" },
                        React.createElement(SubscriptionDetails_1.default, { _showToast: _showToast }))),
                    React.createElement("div", null,
                        React.createElement(Cancellation_1.default, null)))))) : (React.createElement(React.Fragment, null))));
};
exports.default = Billing;
