"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const _ = require("lodash");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const utils_1 = require("@actions/utils");
const plaid_1 = require("@actions/plaid");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const PlaidLink = ({ showOpts, //: { siblings = [], plaid_account_id = null, link_new = null },
linkCallback, }) => {
    const [showLoading, setShowLoading] = (0, react_1.useState)(false);
    const [showPreNotice, setShowPreNotice] = (0, react_1.useState)(false);
    const [showSelectProducts, setShowSelectProducts] = (0, react_1.useState)(false);
    const [selectedCountry, setSelectedCountry] = (0, react_1.useState)(null);
    const [selectInvestments, setSelectInvestments] = (0, react_1.useState)(false);
    const [text, setText] = (0, react_1.useState)(null);
    const [understand, setUnderstand] = (0, react_1.useState)(false);
    const environment = (0, react_1.useRef)('production');
    const type = (0, react_1.useRef)(null);
    const linkToken = (0, react_1.useRef)(null);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const COUNTRIES = [
        { display: '🇧🇪 Belgium', code: 'BE' },
        { display: '🇨🇦 Canada', code: 'CA' },
        { display: '🇩🇰 Denmark', code: 'DK' },
        { display: '🇫🇷 France', code: 'FR' },
        { display: '🇩🇪 Germany', code: 'DE' },
        { display: '🇮🇪 Ireland', code: 'IE' },
        { display: '🇮🇹 Italy', code: 'IT' },
        { display: '🇳🇱 Netherlands', code: 'NL' },
        { display: '🇳🇴 Norway', code: 'NO' },
        { display: '🇵🇱 Poland', code: 'PL' },
        { display: '🇵🇹 Portugal', code: 'PT' },
        { display: '🇪🇸 Spain', code: 'ES' },
        { display: '🇸🇪 Sweden', code: 'SE' },
        { display: '🇺🇸 United States', code: 'US' },
    ];
    // Step 0: Load Plaid script
    (0, react_1.useEffect)(() => {
        // Load Plaid script
        const script = document.createElement('script');
        script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        script.async = true;
        script.id = 'PLAID_SCRIPT';
        document.body.appendChild(script);
    }, []);
    // Step 1: Get triggered to open link or relink
    const fetchToken = () => {
        setText(null);
        if (showOpts === null || showOpts === void 0 ? void 0 : showOpts.link_new) {
            type.current = 'Link Plaid';
            getLinkToken();
        }
        else if (showOpts === null || showOpts === void 0 ? void 0 : showOpts.plaid_account_id) {
            type.current = 'Relink Plaid';
            getLinkToken(showOpts.plaid_account_id);
        }
    };
    (0, react_1.useEffect)(() => {
        if (showOpts === null || showOpts === void 0 ? void 0 : showOpts.link_new) {
            if (_user.settings['viewed_bank_syncing_preamble']) {
                setSelectedCountry(null);
                setSelectInvestments(false);
                setShowSelectProducts(true);
                // fetchToken()
            }
            else {
                setShowPreNotice(true);
            }
        }
        else {
            fetchToken();
        }
    }, [showOpts]);
    // Step 2: Get link token
    const getLinkToken = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (plaid_account_id = null) {
        setShowLoading(true);
        const opts = Object.assign(Object.assign({}, (selectedCountry ? { selected_countries: [selectedCountry] } : {})), { primary_product: selectInvestments ? 'investments' : 'transactions' });
        const results = yield (0, plaid_1.createLinkToken)(Object.assign(Object.assign({}, opts), (plaid_account_id ? { plaid_account_id } : {})));
        if (!results.data) {
            setText('We ran into an error trying to connect to Plaid. Please try again later or contact support for assistance.');
        }
        else if (results.data.removed) {
            setText(React.createElement(semantic_ui_react_1.Message, { error: true },
                React.createElement("p", null,
                    React.createElement("b", null, "This connection has been revoked by either Plaid or your bank.")),
                React.createElement("p", null, "To fix this, you'll need to create a new connection and when prompted, you can merge the new connection with the existing accounts to continue seamlessly syncing.")));
        }
        else {
            linkToken.current = results === null || results === void 0 ? void 0 : results.data.link_token;
            environment.current = results === null || results === void 0 ? void 0 : results.data.environment;
            launchLink();
        }
    });
    // Step 3: Initialize and open Plaid Link
    const launchLink = () => {
        if (!linkToken.current)
            return;
        window.plaidHandler = window.Plaid.create({
            token: linkToken.current,
            env: environment.current,
            onLoad: function () {
                setShowLoading(false);
                window.plaidHandler.open();
            },
            onSuccess: function (public_token, metadata) {
                return __awaiter(this, void 0, void 0, function* () {
                    yield (0, utils_1.logPlaidErrors)({
                        type: type.current,
                        event: 'success',
                        value: metadata,
                    });
                    // Retire current link token
                    linkToken.current = null;
                    let prevMissingAccounts = [];
                    let newlyMissingAccounts = [];
                    // Only on relink
                    if (showOpts === null || showOpts === void 0 ? void 0 : showOpts.plaid_account_id) {
                        // See if any plaid_account_id are missing that were previously 'active'
                        newlyMissingAccounts = _.differenceWith(showOpts.activeSiblings, metadata.accounts, (a, b) => {
                            return a.external_id == b.id;
                        });
                        prevMissingAccounts = _.differenceWith(showOpts.inactiveSiblings, metadata.accounts, (a, b) => {
                            return a.external_id == b.id;
                        });
                    }
                    linkCallback(public_token, {
                        institution: metadata.institution,
                        originalAccountId: showOpts === null || showOpts === void 0 ? void 0 : showOpts.plaid_account_id,
                        activeSiblings: showOpts === null || showOpts === void 0 ? void 0 : showOpts.activeSiblings,
                        inactiveSiblings: showOpts === null || showOpts === void 0 ? void 0 : showOpts.inactiveSiblings,
                        newlyMissingAccounts, // missing accounts from this time around
                        prevMissingAccounts, // missing accounts from previous time around
                        newAccountsDetected: _.differenceWith(metadata.accounts, // what is returned that isn't in _assets.plaidAccounts
                        _assets.plaidAccounts, (a, b) => {
                            return a.id == b.external_id;
                        }),
                    });
                });
            },
            onExit: function (error, metadata) {
                return __awaiter(this, void 0, void 0, function* () {
                    // console.log(`${type.current} onExit error`, error)
                    // console.log(`${type.current} onExit metadata`, metadata)
                    // await logPlaidErrors({
                    //   type: type.current,
                    //   event: 'exit',
                    //   value: { ...error, ...metadata },
                    // })
                    // Retire current link token
                    linkToken.current = null;
                    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
                        // Get a new token
                        fetchToken();
                    }
                });
            },
            onEvent: function (eventName, metadata) {
                return __awaiter(this, void 0, void 0, function* () {
                    // console.log(`${type.current} ${eventName}`, metadata)
                    yield (0, utils_1.logPlaidErrors)({
                        type: type.current,
                        event: eventName,
                        value: metadata,
                    });
                });
            },
        });
    };
    return showPreNotice ? (React.createElement(semantic_ui_react_1.Modal, { open: showPreNotice, dimmer: 'inverted', size: "tiny" },
        React.createElement(semantic_ui_react_1.Modal.Header, null, "How does bank syncing work?"),
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement("div", null,
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: 'green', name: 'university' }),
                        React.createElement("div", { className: `log-icon-line thick moving-green` })),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, "Your Financial Institution"),
                        React.createElement("div", { className: "log-items" },
                            React.createElement("p", { className: "monospace" }, "Your bank sends data (transactions, balances) about your connected account(s) to Plaid.")))),
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: 'green', name: 'factory' }),
                        React.createElement("div", { className: `log-icon-line thick moving-green` })),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, "Plaid"),
                        React.createElement("div", { className: "log-items" },
                            React.createElement("p", { className: "monospace" }, "You connect to your bank via Plaid, our trusted third-party partner. No banking credentials are saved or stored by Lunch Money.")))),
                React.createElement("div", { className: "log-container" },
                    React.createElement("div", { className: "log-icon" },
                        React.createElement(semantic_ui_react_1.Icon, { circular: true, fitted: true, inverted: true, color: 'green', name: 'checkmark' })),
                    React.createElement("div", { className: "log-details" },
                        React.createElement("span", { className: "log-header large" }, "Lunch Money"),
                        React.createElement("div", { className: "log-items" },
                            React.createElement("p", { className: "monospace" }, "Your transactions (for cash & credit accounts) and balances are synced automatically within Lunch Money!"))))),
            React.createElement(semantic_ui_react_1.Message, { info: true },
                React.createElement(semantic_ui_react_1.Message.Header, null, "Need help with your bank connection?"),
                React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                    React.createElement("p", null,
                        "If you ever run into an issue with bank syncing, the best way to get help is by ",
                        React.createElement("b", null, "opening a bank syncing support ticket"),
                        ". This helps us gather as much information as possible so we can reach out directly to Plaid on your behalf to investigate your issue."),
                    React.createElement("p", null, "To do so, click into any account and select 'I need help with this account', or click on the Help button at the top right corner."))),
            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: understand, onChange: (e, { checked }) => {
                    setUnderstand(checked);
                }, label: "I have fully read and understood the above regarding bank syncing at Lunch Money." })),
        React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
            React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                    setShowPreNotice(false);
                } }, "Close"),
            React.createElement(semantic_ui_react_1.Button, { disabled: !understand, onClick: () => {
                    setShowPreNotice(false);
                    _user.updateSetting('viewed_bank_syncing_preamble', true);
                    fetchToken();
                }, icon: true, labelPosition: "right" },
                "Got it, connect to my bank",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))))) : showSelectProducts ? (React.createElement(semantic_ui_react_1.Modal, { open: showSelectProducts, dimmer: 'inverted', size: "tiny" },
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement(semantic_ui_react_1.Message, null, "Lunch Money is able to sync all your accounts that exist under the same login at your financial institution. Please select the country of the institution you would like to connect to."),
            React.createElement("div", { className: "display--flex flex--wrap" }, COUNTRIES.map(country => (React.createElement("div", { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setSelectedCountry(country.code);
                }), className: `half link-recurring-row ${selectedCountry == country.code ? 'selected' : ''}` },
                React.createElement("span", null, country.display))))),
            (selectedCountry == 'CA' || selectedCountry == 'US') &&
                (window.location.href.indexOf('beta.lunchmoney') >= 0 ||
                    window.location.href.indexOf('alpha.lunchmoney') >= 0 ||
                    window.location.href.indexOf('staging.lunchmoney') >= 0) && (React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: selectInvestments, onChange: (e, { checked }) => {
                        setSelectInvestments(checked);
                    }, label: "I want to sync an investment account" }),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey", style: { cursor: 'pointer' } }), content: "Check this box to enable connecting to investment-only institutions. You will still be able to connect to cash/credit accounts at the same institution but may not be able to connect to institutions that offer only cash/credit accounts.", position: "right center" })))),
        React.createElement(semantic_ui_react_1.Modal.Actions, { className: "flex--space-between" },
            React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                    setShowSelectProducts(false);
                } }, "Close"),
            React.createElement(semantic_ui_react_1.Button, { disabled: selectedCountry == null, onClick: () => {
                    setShowSelectProducts(false);
                    fetchToken();
                }, icon: true, labelPosition: "right" },
                "Continue",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" }))))) : (React.createElement(semantic_ui_react_1.Modal, { open: showLoading, dimmer: 'inverted', size: "mini" },
        React.createElement(semantic_ui_react_1.Modal.Header, null, text ? 'Error' : 'Connecting to Plaid...'),
        React.createElement(semantic_ui_react_1.Modal.Content, null,
            React.createElement("p", null, text ||
                'Please wait while we connect to Plaid. This may take a few seconds.'),
            text ? React.createElement(React.Fragment, null) : React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true })),
        text ? (React.createElement(semantic_ui_react_1.Modal.Actions, null,
            React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                    setShowLoading(false);
                } }, "Close"))) : (React.createElement(React.Fragment, null))));
};
exports.default = PlaidLink;
