"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const semantic_ui_react_1 = require("semantic-ui-react");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const format_1 = require("@/helpers/format");
const EditRow = ({ auto_budget, budget_settings, category, showModal }) => {
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    return (React.createElement(semantic_ui_react_1.Table.Row, { className: "clickable", onClick: () => {
            showModal(true);
        } },
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell", colSpan: category.is_group || !category.is_child ? 3 : 1, style: category.is_child
                ? {
                    width: '10px',
                    borderRight: '1px solid #f8b500',
                    backgroundColor: '#fcfcfc',
                    borderTop: 0,
                }
                : {} },
            React.createElement("div", { className: "flex--space-between" }, category.is_group || !category.is_child
                ? _categories.getName(category.id)
                : '')),
        category.is_child && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
            React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" },
                React.createElement("div", { className: "flex--space-between" }, category.is_child && _categories.getName(category.id))))),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
        category.is_group ? (React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, "-")) : (React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, budget_settings.rollover_option == 'same category'
            ? `Same category starting ${Moment(budget_settings.rollover_start_date).format(_user.getFormat('month_day_year'))}`
            : budget_settings.rollover_option == 'available funds'
                ? `General pool starting ${Moment(budget_settings.rollover_start_date).format(_user.getFormat('month_day_year'))}`
                : 'Do nothing')),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
        category.is_group ? (React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" }, "-")) : (React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell" },
            (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fixed'
                ? 'Add fixed amount'
                : (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fill'
                    ? "Fill 'Available' up to a certain amount"
                    : (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'spend'
                        ? "Set to previous period's spend"
                        : (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'budget'
                            ? "Set to previous period's budget"
                            : (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'average-3'
                                ? 'Set to average spend of last 3 periods'
                                : 'Do nothing',
            ' ',
            (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fixed' || (auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.type) == 'fill'
                ? (0, format_1.toPrice)(auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.amount, auto_budget === null || auto_budget === void 0 ? void 0 : auto_budget.currency)
                : '')),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable" },
            React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey` }))));
};
exports.default = EditRow;
