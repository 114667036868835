"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const EditableDate_1 = require("../elements/EditableDate");
const CustomPeriod = ({ budgetCadenceQuantity, setBudgetCadenceQuantity, budgetCadenceGranularity, setBudgetCadenceGranularity, budgetAnchorDate, setBudgetAnchorDate, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex--space-between" },
            React.createElement("label", { style: {
                    display: 'flex',
                    position: 'relative',
                    top: '2px',
                    whiteSpace: 'nowrap',
                    marginRight: '5px',
                } }, "New budget every"),
            React.createElement(semantic_ui_react_1.Form.Input, { type: "text", placeholder: "30", action: true },
                React.createElement(semantic_ui_react_1.Input, { style: {
                        width: '120px',
                        height: '38px',
                        paddingRight: '3px',
                    }, value: budgetCadenceQuantity, className: "p-input-modal", error: budgetCadenceQuantity < 1 || isNaN(budgetCadenceQuantity), onChange: (e, { value }) => {
                        if (Number(value) < 1 || isNaN(Number(value))) {
                            setBudgetCadenceQuantity(value);
                            //   onSave({ quantity: value })
                        }
                        else {
                            setBudgetCadenceQuantity(Number(value));
                            //   onSave({ quantity: Number(value) })
                        }
                    } }),
                React.createElement(semantic_ui_react_1.Form.Select, { compact: true, placeholder: "Select", value: budgetCadenceGranularity, options: ['day(s)', 'week(s)', 'month(s)', 'year(s)'].map(o => {
                        return {
                            key: o,
                            value: o.replace('(s)', ''),
                            text: o,
                        };
                    }), onChange: (e, { value }) => {
                        setBudgetCadenceGranularity(value.toString());
                        // onSave({ granularity: value })
                    } }))),
        React.createElement("div", { className: "transaction-detail mt-05rem" },
            React.createElement("label", null, "Starting on"),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement(EditableDate_1.default, { identifier: "recurring-billing-date", firstValue: budgetAnchorDate, state: 'Editing', location: 'modal', format: 'month_day_year', yearPicker: true, onSave: date => {
                                setBudgetAnchorDate(date);
                            } })))))));
};
exports.default = CustomPeriod;
