"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_router_dom_1 = require("react-router-dom");
const UserProvider_1 = require("@providers/UserProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
// Functions
const recurring_expenses_1 = require("@actions/recurring_expenses");
const dates_1 = require("@helpers/dates");
// Components
const Sticky_1 = require("@components/elements/Sticky");
const Overview_1 = require("@components/RecurringExpenses/Overview");
const RecurringExpenseRow_1 = require("@components/RecurringExpenses/RecurringExpenseRow");
const Loader_1 = require("@components/global/Loader");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const RecurringExpenses_1 = require("@components/TaskCard/RecurringExpenses");
const NewModal_1 = require("@components/global/NewModal");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const format_1 = require("@/helpers/format");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const CADENCE_TO_DAYS = {
    'once a week': 7,
    'twice a month': 15,
    'every 2 weeks': 14,
    monthly: 30,
    'every 2 months': 2 * 30,
    'every 3 months': 3 * 30,
    'every 4 months': 4 * 30,
    'twice a year': 6 * 30,
    yearly: 365,
};
const GRANULARITY_TO_DAYS = {
    day: 1,
    week: 7,
    month: 30,
    year: 365,
};
const RecurringItems = ({ history, _process, _showToast, _removeToast }) => {
    const { year, month } = (0, react_router_dom_1.useParams)();
    const [currentPeriod, setCurrentPeriod] = (0, react_1.useState)(null);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [showNewRecurringExpense, setShowNewRecurringExpense] = (0, react_1.useState)(false);
    const [recurringExpenses, setRecurringExpenses] = (0, react_1.useState)([]);
    const [suggestedRecurring, setSuggestedRecurring] = (0, react_1.useState)([]);
    const [expectedTotal, setExpectedTotal] = (0, react_1.useState)(null);
    const [actualTotal, setActualTotal] = (0, react_1.useState)(null);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [recurringMonths, setRecurringMonths] = (0, react_1.useState)([]);
    const [missingRecurringExpenses, setMissingRecurringExpenses] = (0, react_1.useState)(null);
    const [recurringRemaining, setRecurringRemaining] = (0, react_1.useState)({
        income: 0,
        expense: 0,
    });
    const [sortColumn, setSortColumn] = (0, react_1.useState)('billing_date');
    const [sortDirection, setSortDirection] = (0, react_1.useState)('ascending');
    const [showModal, setShowModal] = (0, react_1.useState)(null);
    const [modalData, setModalData] = (0, react_1.useState)(undefined);
    const [showHelpTooltip, setShowHelpTooltip] = (0, react_1.useState)(false);
    const [recurringUI, setRecurringUI] = (0, react_1.useState)([]);
    const [suggestedCount, setSuggestedCount] = (0, react_1.useState)(0);
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(false);
    const [merchantWidth, setMerchantWidth] = (0, react_1.useState)(null);
    const [categoryWidth, setCategoryWidth] = (0, react_1.useState)(null);
    const [descriptionWidth, setDescriptionWidth] = (0, react_1.useState)(null);
    const [repeatsWidth, setRepeatsWidth] = (0, react_1.useState)(null);
    const [billingDayWidth, setBillingDayWidth] = (0, react_1.useState)(null);
    const [amountWidth, setAmountWidth] = (0, react_1.useState)(null);
    const [selectedRecurring, setSelectedRecurring] = (0, react_1.useState)(null);
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const _collapsed = localStorage.getItem('_lm_recurring_collapsed_obj');
    const [collapsed, setCollapsed] = (0, react_1.useState)(_collapsed ? JSON.parse(_collapsed) : {});
    // Views
    const [groupBy, setGroupBy] = (0, react_1.useState)(localStorage.getItem('_lm_recurring_group_by') || 'category');
    (0, react_1.useEffect)(() => {
        if (typeof month === 'undefined' && typeof year === 'undefined') {
            const _currentPeriod = localStorage.getItem('_lm_recurring_current_period');
            const currentPeriod = _currentPeriod !== 'null' && !!_currentPeriod
                ? _currentPeriod
                : (0, dates_1.getFormattedMonth)();
            history.replace(`/recurring/${Moment(currentPeriod).format('YYYY/MM')}${window.location.search}`);
        }
        else {
            const newCurrentPeriod = year && month
                ? (0, dates_1.getFormattedMonth)(year, month)
                : localStorage.getItem('_lm_recurring_current_period') ||
                    (0, dates_1.getFormattedMonth)();
            if (newCurrentPeriod !== currentPeriod) {
                setCurrentPeriod(newCurrentPeriod);
                localStorage.setItem('_lm_recurring_current_period', newCurrentPeriod);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_recurring_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_transactions_current_period', newCurrentPeriod);
                    localStorage.setItem('_lm_calendar_current_period', newCurrentPeriod);
                }
            }
        }
    }, [month, year]);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Recurring Items - Lunch Money';
        document.addEventListener('keydown', handleKeyDown, false);
        // removeLargestWidth
        const columns = [
            'merchant',
            'category',
            'description',
            'repeats',
            'billing-day',
            'amount',
        ];
        let missing = false;
        let maxCol;
        let total = 0;
        let maxWidth = 0;
        columns.forEach(col => {
            if (localStorage.getItem(`recurring-${col}`) == null) {
                missing = true;
            }
            else {
                const width = parseInt(localStorage.getItem(`recurring-${col}`) || '0');
                total += width;
                if (width > maxWidth) {
                    maxCol = col;
                    maxWidth = width;
                }
            }
        });
        // Only do this if all widths are set
        if (!missing || total > 0.4 * window.innerWidth) {
            localStorage.removeItem(`recurring-${maxCol}`);
        }
        // Figure out which one is the largest
        setMerchantWidth(localStorage.getItem(`recurring-merchant`));
        setCategoryWidth(localStorage.getItem(`recurring-category`));
        setDescriptionWidth(localStorage.getItem(`recurring-description`));
        setRepeatsWidth(localStorage.getItem(`recurring-repeats`));
        setBillingDayWidth(localStorage.getItem(`recurring-billing-day`));
        setAmountWidth(localStorage.getItem(`recurring-amount`));
    }, []);
    (0, react_1.useEffect)(() => {
        if (currentPeriod) {
            setIsLoading(true);
            // Reset sort until we store sort in the url
            setSortColumn('billing_date');
            setSortDirection('ascending');
            refreshExpenses();
        }
    }, [currentPeriod]);
    const handleKeyDown = event => {
        if (event.keyCode === 27 && showNewRecurringExpense) {
            // ESC exits new recurring expense
            setShowNewRecurringExpense(false);
        }
    };
    /**
     * Fetch all recurring expenses and totals
     */
    const refreshExpenses = () => __awaiter(void 0, void 0, void 0, function* () {
        const snapshot = yield (0, recurring_expenses_1.getRecurringExpensesPage)({
            start_date: currentPeriod,
            end_date: (0, dates_1.getThisMonthEnd)(currentPeriod),
        });
        // Assume it's list every single one
        const _items = [];
        snapshot.recurringItems.forEach(item => {
            _items.push(...Object.keys(item.occurrences)
                .map(date => {
                var _a;
                // Either the date of the transaction or the expected date
                const _date = ((_a = item.occurrences[date]) === null || _a === void 0 ? void 0 : _a.length) > 0
                    ? item.occurrences[date][0].date
                    : date;
                if (Moment(_date).isSameOrAfter(Moment(currentPeriod)) &&
                    Moment(_date).isSameOrBefore(Moment((0, dates_1.getThisMonthEnd)(currentPeriod)))) {
                    return Object.assign(Object.assign({}, item), { billing_date: date, anchor_date: item.billing_date, occurrences: item.occurrences, transaction: item.occurrences[date][0] });
                }
                return null;
            })
                .filter(o => !!o));
        });
        const sorted = _sort(_items, sortColumn);
        setRecurringExpenses(sorted.filter(o => o.type == 'cleared'));
        setSuggestedRecurring(sorted.filter(o => o.type == 'suggested'));
        setExpectedTotal(snapshot.recurringTotals.expected);
        setActualTotal(snapshot.recurringTotals.actual);
        setShowNewRecurringExpense(false);
        setSuggestedCount(snapshot.suggestedCount);
        setIsLoading(false);
        setRecurringMonths(snapshot.recurringMonths);
        setIsReady(true);
        setRecurringRemaining(snapshot.recurringTotals.remaining);
        setMissingRecurringExpenses(snapshot.recurringItems
            .reduce((acc, cur) => {
            acc.push(...cur.missing_dates_within_range);
            return acc;
        }, [])
            .reduce((acc, cur) => {
            // if cur is after today, add count to acc
            if (Moment().isAfter(Moment(cur).add(1, 'day'))) {
                acc += 1;
            }
            return acc;
        }, 0));
        yield _recurring.fetchItems(); // Get new list of recurring
    });
    /**
     * Approves a recurring expense via transaction row
     */
    const clearRecurringId = (recurring_id) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(recurring_expenses_1.clearByRecurringId)(recurring_id);
        if (!results.error) {
            // Refresh TransactionsProvider's recurringMap
            yield refreshExpenses();
            // Show success message
            _showToast({
                message: 'Successfully updated transaction and approved recurring item',
                type: 'success',
            });
        }
    });
    /**
     * Dismisses a recurring expense via transaction row
     */
    const dismissRecurringId = () => __awaiter(void 0, void 0, void 0, function* () {
        yield refreshExpenses();
        // Show success message
        _showToast({
            message: 'Successfully dismissed recurring item',
            type: 'success',
        });
    });
    /**
     * onSave: Handler when user wants to save edits in-line
     */
    const onSave = (id, item) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(recurring_expenses_1.updateRecurringExpense)(id, Object.assign({}, item));
        if (!results.error) {
            // Refresh TransactionsProvider's recurringMap
            yield _recurring.fetchItems();
            if (Object.keys(item).length == 1 &&
                (item.payee || item.description || item.category_id)) {
                if (item.payee && sortColumn == 'payee') {
                    setSortColumn(null);
                }
                if (item.description && sortColumn == 'description') {
                    setSortColumn(null);
                }
                amendRecurring({ update: Object.assign({ id }, item) });
            }
            else if (Object.keys(item).length == 1 && item.type) {
                // Either dismissed or cleared it.
                const newSuggested = suggestedRecurring.filter(o => {
                    return o.id !== id;
                });
                if (item.type === 'dismissed') {
                    // Remove from suggestedRecurring
                    setSuggestedRecurring(newSuggested);
                }
                else if (item.type === 'cleared') {
                    // Could be multiple
                    const matched = suggestedRecurring
                        .filter(o => {
                        return o.id == id;
                    })
                        .map(o => {
                        return Object.assign(Object.assign({}, o), { type: 'cleared' });
                    });
                    // Remove from suggestedRecurring
                    setSuggestedRecurring(newSuggested);
                    // Add to recurring
                    setRecurringExpenses([...matched, ...recurringExpenses]);
                }
                if (newSuggested.length == 0) {
                    history.replace(`/recurring/${Moment(currentPeriod).format('YYYY/MM')}`);
                }
            }
            else {
                yield refreshExpenses();
            }
            _showToast({
                message: 'Successfully updated recurring item',
                type: 'success',
            });
        }
    });
    const amendRecurring = (_a) => __awaiter(void 0, [_a], void 0, function* ({ add = [], update, remove = null }) {
        setRecurringExpenses([
            ...add,
            ...recurringExpenses
                .filter(rec => {
                return rec.id !== remove;
            })
                .map(recurring => {
                if (update.id === recurring.id) {
                    return Object.assign(Object.assign({}, recurring), update);
                }
                else {
                    return recurring;
                }
            }),
        ]);
    });
    /**
     * Handles sorting account table
     */
    const handleSort = (clickedColumn) => {
        if (sortColumn !== clickedColumn) {
            const sortKey = clickedColumn == 'amount' ? 'to_base' : clickedColumn;
            const sorted = _sort(recurringExpenses, sortKey);
            setRecurringExpenses([...sorted]);
            setSortColumn(clickedColumn);
            setSortDirection('ascending');
        }
        else {
            setRecurringExpenses([...recurringExpenses.reverse()]);
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        }
    };
    const _sort = (recurringExpenses, sortKey) => {
        return recurringExpenses.sort((a, b) => {
            var _a, _b;
            if (sortKey == 'billing_date') {
                const compareA = ((_a = a === null || a === void 0 ? void 0 : a.transaction) === null || _a === void 0 ? void 0 : _a.date) || a.billing_date;
                const compareB = ((_b = b === null || b === void 0 ? void 0 : b.transaction) === null || _b === void 0 ? void 0 : _b.date) || b.billing_date;
                return Moment(compareA).isBefore(Moment(compareB)) ? -1 : 1;
            }
            else if (sortKey == 'status') {
                if (a.hasOwnProperty('transaction') &&
                    Object.keys(a['transaction']).length == 0) {
                    a._status = 'missing';
                }
                else if (!a.hasOwnProperty('transaction')) {
                    a._status = 'coming up';
                }
                else {
                    a._status = 'cleared';
                }
                if (b.hasOwnProperty('transaction') &&
                    Object.keys(b['transaction']).length == 0) {
                    b._status = 'missing';
                }
                else if (!b.hasOwnProperty('transaction')) {
                    b._status = 'coming up';
                }
                else {
                    b._status = 'cleared';
                }
                return a._status.localeCompare(b._status);
            }
            else if (!a[sortKey]) {
                return -1;
            }
            else if (!b[sortKey]) {
                return 1;
            }
            else if (sortKey == 'cadence') {
                // Convert cadences to days
                let compareA = 0;
                let compareB = 0;
                if (CADENCE_TO_DAYS.hasOwnProperty(a[sortKey])) {
                    compareA = CADENCE_TO_DAYS[a[sortKey]];
                }
                else {
                    compareA = a.quantity * GRANULARITY_TO_DAYS[a.granularity];
                }
                if (CADENCE_TO_DAYS.hasOwnProperty(b[sortKey])) {
                    compareB = CADENCE_TO_DAYS[b[sortKey]];
                }
                else {
                    compareB = b.quantity * GRANULARITY_TO_DAYS[b.granularity];
                }
                return compareA - compareB;
            }
            else if (typeof a[sortKey] === 'string') {
                return a[sortKey]
                    .trim()
                    .toLowerCase()
                    .localeCompare(b[sortKey].toLowerCase().trim());
            }
            else {
                // const compareA = a.cadence == 'yearly' ? a[sortKey] / 12 : a[sortKey]
                // const compareB = b.cadence == 'yearly' ? b[sortKey] / 12 : b[sortKey]
                return a[sortKey] - b[sortKey];
            }
        });
    };
    /**
     *  Handles month jumping from dropdown menu
     */
    const handleMonthJump = (e, { value }) => {
        history.push(`/recurring/${value}`);
    };
    (0, react_1.useEffect)(() => {
        if (!isReady) {
            return;
        }
        /**
         * Build recurring expenses table rows
         */
        let tooltipId = null, notFoundTooltipId = null;
        if (showHelpTooltip) {
            // Tooltip on 'not found'
            const notFound = _.find(recurringExpenses, o => {
                return (Moment().isAfter(Moment(o.billing_date).add(1, 'day')) &&
                    o.transaction &&
                    Object.keys(o.transaction).length == 0);
            });
            if (notFound) {
                notFoundTooltipId = notFound.id;
            }
        }
        const GROUP_BY_MAPPING = {
            cadence: 'cadence',
            category_id: 'category',
            type: 'status',
            account: 'account',
        };
        const getCollapsedKey = item => {
            if (groupBy == 'account') {
                if (item.asset_id) {
                    return `asset_${item.asset_id}`;
                }
                else if (item.plaid_account_id) {
                    return `plaid_${item.plaid_account_id}`;
                }
                else {
                    return 'none';
                }
            }
            else if (groupBy == 'type') {
                if (item.to_base > 0) {
                    return 'credit';
                }
                else {
                    return 'debit';
                }
            }
            else if (groupBy == 'status') {
                return item.type;
            }
            else if (groupBy == 'category') {
                return item.category_id;
            }
            return item[GROUP_BY_MAPPING[groupBy]];
        };
        let tooltipMatched = false;
        let notFoundTooltipMatched = false;
        let visibleExpenses = recurringExpenses;
        let _recurringExpenses = [];
        let grouped = null;
        if (groupBy == 'cadence') {
            grouped = _.groupBy(visibleExpenses, 'cadence');
        }
        else if (groupBy == 'category') {
            grouped = _.groupBy(visibleExpenses, 'category_id');
        }
        else if (groupBy == 'account') {
            grouped = _.groupBy(visibleExpenses, o => {
                if (o.plaid_account_id) {
                    return `plaid_${o.plaid_account_id}`;
                }
                else if (o.asset_id) {
                    return `asset_${o.asset_id}`;
                }
            });
        }
        else if (groupBy == 'type') {
            grouped = _.groupBy(visibleExpenses, o => {
                return o.to_base > 0 ? 'debit' : 'credit';
            });
        }
        if (grouped !== null) {
            Object.keys(grouped).forEach((key, index) => {
                _recurringExpenses.push(React.createElement(semantic_ui_react_1.Table.Row, { className: "padded-row highlighted clickable", key: `header-row-${index}` },
                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "editable", onClick: () => {
                            const newValue = Object.assign(Object.assign({}, collapsed), { [getCollapsedKey(grouped[key][0])]: !collapsed[getCollapsedKey(grouped[key][0])] });
                            localStorage.setItem('_lm_recurring_collapsed_obj', JSON.stringify(newValue));
                            setCollapsed(newValue);
                        }, colSpan: ['category', 'cadence'].indexOf(groupBy) > -1 ? 15 : 17 },
                        React.createElement("div", { className: "ellipsis" },
                            React.createElement(semantic_ui_react_1.Icon, { style: { width: 'auto', marginRight: '0.7rem' }, name: collapsed[getCollapsedKey(grouped[key][0])]
                                    ? 'caret right'
                                    : 'caret down' }),
                            React.createElement("span", null, groupBy == 'category'
                                ? `Category: ${_categories.getName(key)}`
                                : groupBy == 'account'
                                    ? key.split('_')[0] == 'asset'
                                        ? _assets.getAssetName(key.split('_')[1])
                                        : _assets.getPlaidAccountName(key.split('_')[1])
                                    : (0, format_1.capitalize)(key))))));
                if (!collapsed[getCollapsedKey(grouped[key][0])]) {
                    grouped[key].forEach((expense, index) => {
                        if (tooltipId === expense.id && !tooltipMatched) {
                            tooltipMatched = true;
                        }
                        if (notFoundTooltipId === expense.id && !notFoundTooltipMatched) {
                            notFoundTooltipMatched = true;
                        }
                        _recurringExpenses.push(React.createElement(RecurringExpenseRow_1.default, { key: `recurring-expense-${expense.id}-${index}`, onSave: onSave, onOpenModal: (data, view) => {
                                setModalData(data);
                                setShowModal(view);
                            }, groupBy: groupBy, expense: expense, currentPeriod: currentPeriod, flipSign: _user.settings['show_debits_as_negative'], openTable: item => {
                                setSelectedRecurring(item);
                                setOpenTransactionsTable(true);
                            } }));
                    });
                }
            });
        }
        else {
            _recurringExpenses = visibleExpenses.map((expense, index) => {
                if (tooltipId === expense.id && !tooltipMatched) {
                    tooltipMatched = true;
                }
                if (notFoundTooltipId === expense.id && !notFoundTooltipMatched) {
                    notFoundTooltipMatched = true;
                }
                return (React.createElement(RecurringExpenseRow_1.default, { key: `recurring-expense-${expense.id}-${index}`, onSave: onSave, onOpenModal: (data, view) => {
                        setModalData(data);
                        setShowModal(view);
                    }, expense: expense, groupBy: groupBy, currentPeriod: currentPeriod, flipSign: _user.settings['show_debits_as_negative'], openTable: item => {
                        setSelectedRecurring(item);
                        setOpenTransactionsTable(true);
                    } }));
            });
        }
        setShowNewRecurringExpense(false);
        setRecurringUI(_recurringExpenses);
    }, [recurringExpenses, suggestedRecurring, groupBy, collapsed, isReady]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-recurring" },
        React.createElement(ContainerHeader_1.default, { backLink: {
                pathname: `/recurring/${(0, dates_1.getPreviousMonth)(currentPeriod)
                    .replace('-', '/')
                    .substring(0, 7)}`,
            }, forwardLink: {
                pathname: `/recurring/${(0, dates_1.getNextMonth)(currentPeriod)
                    .replace('-', '/')
                    .substring(0, 7)}`,
            }, title: currentPeriod
                ? `${Moment(currentPeriod, 'YYYY-MM-DD').format('MMMM YYYY')}`
                : null, dropdownOnChange: handleMonthJump, dropdownOptions: recurringMonths, showBackToCurrentMonthButton: currentPeriod !== `${Moment().format('YYYY-MM')}-01`, jumpBackToCurrentMonth: () => {
                localStorage.setItem('_lm_recurring_current_period', `${Moment().format('YYYY-MM')}-01`);
                if (_user.settings['link_months_between_views']) {
                    localStorage.setItem('_lm_transactions_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_budget_current_period', `${Moment().format('YYYY-MM')}-01`);
                    localStorage.setItem('_lm_calendar_current_period', `${Moment().format('YYYY-MM')}-01`);
                }
                history.push(`/recurring/${Moment().format('YYYY/MM')}`);
            } }),
        !!showModal && (React.createElement(NewModal_1.default, { show: !!showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                recurring: modalData,
                refreshExpenses,
                currentPeriod,
                flipSign: _user.settings['show_debits_as_negative'],
                primaryCurrency: _user.primaryCurrency,
                allowRecurringCategories: _user.settings['allow_recurring_categories'],
                clearRecurring: clearRecurringId,
                dismissRecurring: dismissRecurringId,
                deleteCallback: (type, id) => {
                    if (type == 'rule') {
                        // Remove rule by criteria_id in modalData and recurringExpenses
                        const recurring_id = modalData.id;
                        setModalData(Object.assign(Object.assign({}, modalData), { rules: modalData.rules.filter(o => o.rule_criteria_id !== id) }));
                        setRecurringExpenses(recurringExpenses.map(rec => {
                            if (rec.id == recurring_id) {
                                return Object.assign(Object.assign({}, rec), { rules: rec.rules.filter(o => o.rule_criteria_id !== id) });
                            }
                            else {
                                return rec;
                            }
                        }));
                        _recurring.fetchItems(); // Get new list of recurring
                    }
                },
                submitCallback: (results, _applyRules, oldRuleCriteriaId = null) => {
                    if (results === null || results === void 0 ? void 0 : results.data) {
                        // A new rule was created for this recurring expense
                        if (!modalData.rules || modalData.rules.length == 0) {
                            amendRecurring({
                                update: { id: modalData.id, rules: [results.data] },
                            });
                            setModalData(Object.assign(Object.assign({}, modalData), { rules: [results.data] }));
                        }
                        else {
                            // Update it
                            amendRecurring({
                                update: {
                                    id: modalData.id,
                                    rules: modalData.rules
                                        .filter(rule => {
                                        return rule.rule_criteria_id !== oldRuleCriteriaId;
                                    })
                                        .concat(results.data),
                                },
                            });
                            setModalData(Object.assign(Object.assign({}, modalData), { rules: modalData.rules
                                    .filter(rule => {
                                    return rule.rule_criteria_id !== oldRuleCriteriaId;
                                })
                                    .concat(results.data) }));
                        }
                    }
                },
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: showModal, closeCallback: () => {
                refreshExpenses();
            } })),
        React.createElement("div", { className: "flex--space-between" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowModal('ADD_RECURRING');
                        // setShowNewRecurringExpense(true)
                    } }, "Add New Recurring Item"),
                suggestedCount > 0 && (React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        history.push('/recurring/suggested');
                    } },
                    "Show Suggestions (",
                    suggestedCount,
                    ")"))),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem view-dropdown", text: `Group by: ${groupBy}` },
                    React.createElement(semantic_ui_react_1.Dropdown.Menu, null, ['category', 'cadence', 'account', 'type', 'none'].map(group => (React.createElement(semantic_ui_react_1.Dropdown.Item, { key: group, value: group, selected: groupBy === group, content: (0, format_1.capitalize)(group), onClick: (e, { value }) => {
                            localStorage.setItem('_lm_recurring_group_by', group);
                            setGroupBy(group);
                        } }))))),
                React.createElement(semantic_ui_react_1.Button, { className: "ml-1rem display-on-mobile", basic: !showSidebar, color: "orange", icon: true, onClick: () => {
                        setShowSidebar(!showSidebar);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' })))),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: "left", id: "sticky-counterpart" },
                React.createElement("div", { className: "table-container" },
                    React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, sortable: true, selectable: true, celled: true, className: "p-recurring-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-merchant", sorted: sortColumn === 'payee' ? sortDirection : null, onClick: () => {
                                        handleSort('payee');
                                    }, style: merchantWidth
                                        ? {
                                            width: `${merchantWidth}px`,
                                        }
                                        : {} }, "Merchant"),
                                _user.settings['allow_recurring_categories'] &&
                                    groupBy !== 'category' && (React.createElement(React.Fragment, null,
                                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: 'merchant', nextCol: 'category' }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-category", sorted: sortColumn === 'category_name'
                                            ? sortDirection
                                            : null, onClick: () => {
                                            handleSort('category_name');
                                        }, style: categoryWidth
                                            ? {
                                                width: `${categoryWidth}px`,
                                            }
                                            : {} }, "Category"))),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: _user.settings['allow_recurring_categories']
                                        ? 'category'
                                        : 'merchant', nextCol: 'description' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-description", sorted: sortColumn === 'description' ? sortDirection : null, onClick: () => {
                                        handleSort('description');
                                    }, style: descriptionWidth
                                        ? {
                                            width: `${descriptionWidth}px`,
                                        }
                                        : {} }, "Description"),
                                groupBy !== 'cadence' && (React.createElement(React.Fragment, null,
                                    React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: 'description', nextCol: 'repeats' }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-cadence", sorted: sortColumn === 'cadence' ? sortDirection : null, onClick: () => {
                                            handleSort('cadence');
                                        }, style: repeatsWidth
                                            ? {
                                                width: `${repeatsWidth}px`,
                                            }
                                            : {} }, "Repeats"))),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: 'repeats', nextCol: 'billing-day' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-billing-day", sorted: sortColumn === 'billing_date' ? sortDirection : null, onClick: () => {
                                        handleSort('billing_date');
                                    }, style: billingDayWidth
                                        ? {
                                            width: `${billingDayWidth}px`,
                                        }
                                        : {} },
                                    "Billing Day",
                                    '  ',
                                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "This is when you should expect to get billed for this expense.")),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: 'billing-day', nextCol: 'amount' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-amount", sorted: sortColumn === 'amount' ? sortDirection : null, onClick: () => {
                                        handleSort('amount');
                                    }, style: amountWidth
                                        ? {
                                            width: `${amountWidth}px`,
                                        }
                                        : {} }, "Amount"),
                                React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'recurring', prevCol: 'amount', nextCol: 'status' }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-status center-align", sorted: sortColumn === 'status' ? sortDirection : null, onClick: () => {
                                        handleSort('status');
                                    } }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-hover table-cell-arrow" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-hover table-cell-arrow" }))),
                        React.createElement(semantic_ui_react_1.Table.Body, { className: !isLoading && recurringExpenses.length === 0
                                ? 'g-empty-body'
                                : '' },
                            !isLoading && recurringExpenses.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: ['category', 'cadence'].indexOf(groupBy) > -1 ? 15 : 17, className: "center-align" },
                                    React.createElement("div", { className: "empty-mascot" }),
                                    React.createElement("p", null, "You have no recurring items yet!"),
                                    React.createElement("p", null,
                                        "Recurring items are automatically detected if you have a",
                                        ' ',
                                        React.createElement(react_router_dom_1.Link, { className: "link", to: "/accounts" }, "synced bank account"),
                                        ' ',
                                        "or",
                                        ' ',
                                        React.createElement(react_router_dom_1.Link, { className: "link", to: "/transactions/import" }, "imported CSV transactions"),
                                        "."),
                                    React.createElement("p", null,
                                        "You can also add them manually by clicking on",
                                        React.createElement(semantic_ui_react_1.Button, { size: "mini", onClick: () => {
                                                setShowModal('ADD_RECURRING');
                                                // setShowNewRecurringExpense(true)
                                            } }, "Add new recurring item"))))),
                            !isLoading && recurringUI,
                            isLoading && (React.createElement(Loader_1.default, { colSpan: ['category', 'cadence'].indexOf(groupBy) > -1 ? 15 : 17 })))))),
            React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile'}` },
                React.createElement(Sticky_1.default, { reset: showSidebar || !isLoading ? new Date().getTime() : null, content: React.createElement(React.Fragment, null,
                        React.createElement(RecurringExpenses_1.default, { suggestedCount: suggestedCount, missingCount: missingRecurringExpenses, onHover: () => {
                                setShowHelpTooltip(true);
                            }, onBlur: () => {
                                setShowHelpTooltip(false);
                            } }),
                        React.createElement(Overview_1.default, { currentPeriod: currentPeriod, isLoading: isLoading, expectedTotal: expectedTotal, actualTotal: actualTotal, remaining: recurringRemaining })) }))),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedRecurring(null);
                setOpenTransactionsTable(false);
            }), headerText: `Recurring transactions for ${selectedRecurring === null || selectedRecurring === void 0 ? void 0 : selectedRecurring.payee}`, query: {
                time: 'all',
                match: 'all',
                recurring: selectedRecurring === null || selectedRecurring === void 0 ? void 0 : selectedRecurring.id,
            }, _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = RecurringItems;
