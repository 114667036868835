"use strict";
/**
 *  EditableString.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
// Components
const semantic_ui_react_1 = require("semantic-ui-react");
const Autosuggest = require("react-autosuggest");
// Helpers
const checks_1 = require("@helpers/checks");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const UserProvider_1 = require("@providers/UserProvider");
var State;
(function (State) {
    /** Default display */
    State["Default"] = "Default";
    /** Is currently being edited */
    State["Editing"] = "Editing";
    /** Is read only; not editable */
    State["ReadOnly"] = "ReadOnly";
})(State || (State = {}));
const EditableString = ({ 
/** Unique identifier */
identifier, 
/** Initialized value */
firstValue, 
/** Optional placeholder if no value */
placeholder = null, 
/* Optional empty text */
emptyText = 'Add a value', 
/** Whether or not input should autofocus, default false */
autoFocus = false, 
/** Handler to be called on save */
onSave = null, 
/** Starting state */
state = 'Default', 
/** Should save on blur, default false */
shouldSaveOnBlur = false, 
/** Should select on focus, default false */
shouldSelectOnFocus = false, 
/** Should save on enter, default false */
shouldSaveOnEnter = false, 
/** Should pass value on change, default false */
shouldSaveOnChange = false, 
/** Just show a blank if the value is null (instead of 'Add a Value') */
blankIfNull = false, 
/** onEnter override; shouldSaveOnEnter is not necessary */
onEnter = null, 
/** Used inline in table or in modal */
location = 'modal', showError = false, isPassword = false, className = '', percentage = false, disabled = false, autosuggest = false, datalist = null, label = null, autoComplete = false, allowEscape = true, }) => {
    const [currentState, setCurrentState] = (0, react_1.useState)(state || State.Default);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(firstValue || '');
    const [hasNoValue, setHasNoValue] = (0, react_1.useState)((0, checks_1.isNull)(firstValue) || (0, checks_1.isEmptyString)(firstValue));
    const [suggestions, setSuggestions] = (0, react_1.useState)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    (0, react_1.useEffect)(() => {
        if (state !== currentState) {
            setCurrentState(state);
        }
    }, [state]);
    (0, react_1.useEffect)(() => {
        setCurrentValue(firstValue || '');
        setHasNoValue((0, checks_1.isNull)(firstValue) || (0, checks_1.isEmptyString)(firstValue));
    }, [firstValue]);
    (0, react_1.useEffect)(() => {
        if (autoFocus && identifier) {
            setTimeout(() => {
                var _a;
                (_a = document.getElementById(identifier)) === null || _a === void 0 ? void 0 : _a.focus();
            }, 500);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (currentState == State.Editing && identifier && location == 'inline') {
            (_a = document
                .getElementById(identifier)) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', handleKeyDown, false);
        }
        return () => {
            var _a;
            (_a = document
                .getElementById(identifier)) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', handleKeyDown);
        };
    }, [identifier, currentState, location, firstValue]);
    const handleKeyDown = event => {
        if (event.keyCode === 27 && allowEscape) {
            // ESC
            setCurrentValue(firstValue || '');
            setCurrentState(State.Default);
        }
    };
    const getSuggestions = (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        try {
            if (value == null) {
                return;
            }
            if (((_b = (_a = _transactions.merchantNames) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.length) == 0) {
                yield _transactions.fetchMerchantNames();
            }
            const list = datalist || ((_c = _transactions.merchantNames) === null || _c === void 0 ? void 0 : _c.current);
            const _suggestions = [];
            let currentIndex = 0;
            const MAX_SUGGESTIONS = 10;
            // Preference over strings that start with value
            while (_suggestions.length < MAX_SUGGESTIONS &&
                currentIndex < list.length) {
                const payee = list[currentIndex];
                if (!value ||
                    ((_e = (_d = payee === null || payee === void 0 ? void 0 : payee.toLowerCase()) === null || _d === void 0 ? void 0 : _d.trim()) === null || _e === void 0 ? void 0 : _e.indexOf((_f = value === null || value === void 0 ? void 0 : value.toLowerCase()) === null || _f === void 0 ? void 0 : _f.trim())) == 0) {
                    _suggestions.push(payee);
                }
                currentIndex++;
            }
            if (_suggestions.length < MAX_SUGGESTIONS) {
                // Secondary to be any length
                currentIndex = 0;
                while (_suggestions.length < MAX_SUGGESTIONS &&
                    currentIndex < list.length) {
                    const payee = list[currentIndex];
                    if (
                    // Make sure it's not already in _suggestions
                    _suggestions.indexOf(payee) == -1 &&
                        // Make sure it's not matching at the beginning
                        ((_g = payee === null || payee === void 0 ? void 0 : payee.toLowerCase()) === null || _g === void 0 ? void 0 : _g.trim().indexOf((_h = value.toLowerCase()) === null || _h === void 0 ? void 0 : _h.trim())) > 0) {
                        _suggestions.push(payee);
                    }
                    currentIndex++;
                }
            }
            setSuggestions(_suggestions);
        }
        catch (e) {
            console.log('Error in getSuggestions', { e, value });
        }
    });
    return (React.createElement("div", { className: `g-editable-text ${currentState === State.Editing && (location === null || location === void 0 ? void 0 : location.indexOf('inline')) > -1
            ? 'selected'
            : ''}${className !== null && className !== void 0 ? className : ''}` },
        currentState === State.ReadOnly && (React.createElement("span", { className: "readonly" }, currentValue)),
        currentState === State.Default && !hasNoValue && (React.createElement("div", { className: "default-state editable-string", onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } }, currentValue)),
        currentState === State.Default && hasNoValue && blankIfNull && (React.createElement("div", { className: "default-state", onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } },
            React.createElement("span", { className: "show-on-hover" }, emptyText))),
        currentState === State.Default && hasNoValue && !blankIfNull && (React.createElement("div", { className: `default-state empty-state`, onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } }, emptyText)),
        currentState === State.Editing &&
            !disabled &&
            autosuggest &&
            _user.settings['auto_suggest_payee'] && (React.createElement(Autosuggest, { suggestions: suggestions, shouldRenderSuggestions: (value, reason) => {
                if ((value === null || value === void 0 ? void 0 : value.length) > 0) {
                    return true;
                }
            }, onSuggestionsFetchRequested: (_j) => __awaiter(void 0, [_j], void 0, function* ({ value, reason }) {
                if ((reason == 'input-focused' && !!datalist) ||
                    reason == 'input-changed') {
                    yield getSuggestions(value);
                }
            }), onSuggestionsClearRequested: () => { }, getSuggestionValue: suggestion => {
                return suggestion;
            }, renderSuggestion: suggestion => {
                return React.createElement("span", null, suggestion);
            }, inputProps: {
                autoFocus: autoFocus,
                placeholder: placeholder,
                value: currentValue,
                onChange: (event, { newValue }) => {
                    if (shouldSaveOnChange) {
                        onSave(newValue);
                    }
                    setHasNoValue(newValue.toString().length === 0);
                    setCurrentValue(newValue);
                },
                onBlur: () => {
                    if (shouldSaveOnBlur) {
                        setCurrentState(State.Default);
                        // Save value
                        onSave(currentValue || null);
                    }
                },
                onKeyPress: (e) => __awaiter(void 0, void 0, void 0, function* () {
                    if (shouldSaveOnEnter || onEnter) {
                        if (e.key == 'Enter') {
                            // Save value
                            yield onSave(currentValue || null);
                            if (!onEnter) {
                                setCurrentState(State.Default);
                            }
                            else {
                                onEnter();
                            }
                        }
                    }
                }),
            }, onSuggestionSelected: (event_1, _k) => __awaiter(void 0, [event_1, _k], void 0, function* (event, { suggestion, method }) {
                if (shouldSaveOnEnter || onEnter) {
                    // Save value
                    setCurrentValue(suggestion);
                    yield onSave(suggestion || null);
                    if (!onEnter) {
                        setCurrentState(State.Default);
                    }
                    else {
                        onEnter();
                    }
                }
            }), containerProps: {
                id: identifier,
            }, theme: {
                container: `ui input p-input-${location} display--flex flex--column`,
                containerOpen: 'react-autosuggest__container--open',
                input: 'react-autosuggest__input',
                inputOpen: 'react-autosuggest__input--open',
                inputFocused: 'react-autosuggest__input--focused',
                suggestionsContainer: 'react-autosuggest__suggestions-container',
                suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                suggestionsList: 'react-autosuggest__suggestions-list',
                suggestion: 'react-autosuggest__suggestion',
                suggestionFirst: 'react-autosuggest__suggestion--first',
                suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                sectionContainer: 'react-autosuggest__section-container',
                sectionContainerFirst: 'react-autosuggest__section-container--first',
                sectionTitle: 'react-autosuggest__section-title',
            } })),
        currentState === State.Editing &&
            (!autosuggest || disabled || !_user.settings['auto_suggest_payee']) && (React.createElement(semantic_ui_react_1.Input, { autoComplete: autoComplete ? 'true' : 'false', labelPosition: label ? 'right' : null, icon: percentage ? 'percent' : null, className: `p-input-${location} width-100`, id: identifier, value: currentValue || '', 
            /** Properties */
            disabled: disabled, type: isPassword ? 'password' : 'text', autoFocus: autoFocus, placeholder: placeholder, error: showError, 
            /** Handlers */
            onBlur: () => {
                if (shouldSaveOnBlur) {
                    setCurrentState(State.Default);
                    // Save value
                    onSave(currentValue || null);
                }
            }, onChange: e => {
                if (shouldSaveOnChange) {
                    onSave(e.target.value);
                }
                setHasNoValue(e.target.value.toString().length === 0);
                setCurrentValue(e.target.value);
            }, onFocus: e => {
                if (shouldSelectOnFocus) {
                    e.target.select();
                }
            }, onKeyPress: (e) => __awaiter(void 0, void 0, void 0, function* () {
                if (shouldSaveOnEnter || onEnter) {
                    if (e.key == 'Enter') {
                        // Save value
                        yield onSave(currentValue || null);
                        if (!onEnter) {
                            setCurrentState(State.Default);
                        }
                        else {
                            onEnter();
                        }
                    }
                }
            }), label: label }))));
};
exports.default = EditableString;
