"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetsProvider = exports.AssetsContext = void 0;
const React = require("react");
const react_1 = require("react");
const UserProvider_1 = require("@/providers/UserProvider");
const assets_1 = require("@actions/assets");
const api_token_1 = require("@actions/api_token");
const AssetsContext = (0, react_1.createContext)({
    isReady: false,
    types: [],
    subtypes: [],
    assets: [],
    plaidAccounts: [],
    crypto: [],
    syncedCrypto: [],
    manualCrypto: [],
    hasAPIKey: false,
    addAsset: obj => { },
    removeAsset: id => { },
    updateCrypto: (id, obj) => { },
    updateSyncedCrypto: (id, coingecko_id, obj) => { },
    updateManualCrypto: (id, obj) => { },
    getCryptoName: (id, currency) => { },
    removeCrypto: id => { },
    removeSyncedCrypto: (id, currency) => { },
    removeManualCrypto: id => { },
    getCrypto: (id, currency) => {
        return { currency: null };
    },
    getSyncedCrypto: (id, currency) => {
        return { currency: null };
    },
    getManualCrypto: id => {
        return { currency: null };
    },
    addTempAccounts: obj => { },
    getAssetName: (id, showCash) => {
        return '';
    },
    getAsset: id => {
        return { display_name: null, balance: null, currency: null };
    },
    updateAsset: (id, obj) => { },
    getAssetInstitutionName: id => {
        return '';
    },
    getPlaidInstitutionName: id => {
        return '';
    },
    getPlaidAccountName: id => {
        return '';
    },
    getPlaidAccount: id => {
        return {
            treat_as_manual: false,
            currency: null,
            currency_override: null,
        };
    },
    updatePlaidAccount: (id, obj) => { },
    getCryptoSiblings: id => {
        return [];
    },
    getSyncedCryptoSiblings: id => {
        return [];
    },
    fetchAssets: (id) => __awaiter(void 0, void 0, void 0, function* () { }),
    lastUpdate: null,
    getCounts: () => {
        return {
            synced: 0,
            manual: 0,
            error: 0,
            active: 0,
            inactive: 0,
            pending: 0,
            unsupported: 0,
            closed: 0,
            credit: 0,
            investment: 0,
            loan: 0,
            'real estate': 0,
            vehicle: 0,
            cryptocurrency: 0,
            'employee compensation': 0,
            'other liability': 0,
            'other asset': 0,
            cash: 0,
        };
    },
    getTotalsByType: () => {
        return {
            // synced: 0,
            // manual: 0,
            // error: 0,
            // active: 0,
            // inactive: 0,
            // pending: 0,
            // unsupported: 0,
            // closed: 0,
            credit: [],
            investment: [],
            loan: [],
            'real estate': [],
            vehicle: [],
            cryptocurrency: [],
            'employee compensation': [],
            'other liability': [],
            'other asset': [],
            cash: [],
        };
    },
    getAssignableAccounts: () => [],
    getAccountName: (str, include) => {
        return '';
    },
});
exports.AssetsContext = AssetsContext;
const AssetsProvider = props => {
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [types, setTypes] = (0, react_1.useState)([]);
    const [subtypes, setSubtypes] = (0, react_1.useState)([]);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const listOfAssets = (0, react_1.useRef)([]);
    const listOfPlaidAccounts = (0, react_1.useRef)([]);
    const listOfCrypto = (0, react_1.useRef)([]);
    const listOfSyncedCrypto = (0, react_1.useRef)([]);
    const listOfManualCrypto = (0, react_1.useRef)([]);
    const [hasAPI, setHasAPI] = (0, react_1.useState)(false);
    const fetchedAssets = (0, react_1.useRef)(false);
    const fetchedStatic = (0, react_1.useRef)(false);
    const [lastUpdate, setLastUpdate] = (0, react_1.useState)(null);
    /** GENERAL */
    (0, react_1.useEffect)(() => {
        console.log('[Assets Provider] Loaded.');
    }, []);
    (0, react_1.useEffect)(() => {
        if (_user.hasFetchedUser &&
            _user.hasFetchedSettings &&
            _user.hasFetchedAccountSettings &&
            !isReady &&
            !fetchedAssets.current &&
            !fetchedStatic.current) {
            fetchAssets();
            fetchStatic();
        }
    }, [_user]);
    (0, react_1.useEffect)(() => {
        if (isReady) {
            // Refetch assets on primary currency change to get new to_base
            fetchAssets();
        }
    }, [_user.primaryCurrency]);
    const fetchStatic = () => __awaiter(void 0, void 0, void 0, function* () {
        fetchedStatic.current = true;
        const _types = yield (0, assets_1.getTypes)();
        setTypes(_types);
        const _subtypes = yield (0, assets_1.getAllSubtypes)();
        setSubtypes(_subtypes);
    });
    const fetchAssets = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (fetchId = null) {
        fetchedAssets.current = true;
        const results = yield (0, assets_1.getAssets)();
        setAssets(results
            .filter(o => o.source == 'manual')
            .filter(o => o.status !== 'auxiliary')
            .map(o => {
            if (!o.display_name) {
                o.display_name = [o.institution_name, o.name].join(' ').trim();
            }
            return o;
        }));
        setPlaidAccounts(results
            .filter(o => o.source == 'plaid')
            .filter(o => o.status !== 'unlinked')
            .map(o => {
            return Object.assign(Object.assign({}, o), { display_name: o.display_name || [o.institution_name, o.name].join(' ').trim(), balance_as_of: o.balance_last_update });
        }));
        setCrypto(results
            .filter(o => o.source == 'zabo')
            .map(o => {
            if (!o.display_name) {
                o.display_name = [o.institution_name, o.name].join(' ').trim();
            }
            return o;
        }));
        setSyncedCrypto(results
            .filter(o => o.source == 'synced-crypto')
            .map(o => {
            if (!o.display_name) {
                o.display_name = [o.institution_name, o.name].join(' ').trim();
            }
            return o;
        }));
        setManualCrypto(results
            .filter(o => o.source == 'manual-crypto')
            .map(o => {
            if (!o.display_name) {
                o.display_name = [o.institution_name, o.name].join(' ').trim();
            }
            return o;
        }));
        const apiResults = yield (0, api_token_1.getAccessTokens)();
        if (!results.error) {
            setHasAPI((apiResults === null || apiResults === void 0 ? void 0 : apiResults.length) > 0);
        }
        setIsReady(true);
        console.log('[Assets Provider] Done fetching assets.');
        setLastUpdate(fetchId);
    });
    const setAssets = value => {
        listOfAssets.current = value;
    };
    const setPlaidAccounts = value => {
        listOfPlaidAccounts.current = value;
    };
    const setCrypto = value => {
        listOfCrypto.current = value;
    };
    const setSyncedCrypto = value => {
        listOfSyncedCrypto.current = value;
    };
    const setManualCrypto = value => {
        listOfManualCrypto.current = value;
    };
    const addAsset = asset => {
        setAssets([...listOfAssets.current, asset]);
    };
    const updateAsset = (asset_id, update_obj) => {
        setAssets(listOfAssets.current.map(o => {
            if (o.id === asset_id) {
                return Object.assign(Object.assign({}, o), update_obj);
            }
            else {
                return o;
            }
        }));
    };
    const getAssetName = (asset_id, showCash = true) => {
        if (asset_id == 'cash' && showCash)
            return 'cash';
        if (!asset_id)
            return '';
        if (asset_id == 'cash' && !showCash)
            return '';
        if ((asset_id === null || asset_id === void 0 ? void 0 : asset_id.toString().indexOf('plaid')) > -1)
            return getPlaidAccountName(asset_id.replace('plaid_', ''));
        const found = listOfAssets.current.find(o => {
            return o.id == asset_id;
        });
        if (found) {
            return (found.display_name || `${found.institution_name || ''} ${found.name}`);
        }
        else {
            return 'asset';
        }
    };
    const getAssetInstitutionName = asset_id => {
        if (asset_id == 'cash' || !asset_id)
            return '';
        const found = listOfAssets.current.find(o => {
            return o.id == asset_id;
        });
        return (found === null || found === void 0 ? void 0 : found.institution_name) || '';
    };
    const getAsset = asset_id => {
        if (asset_id == 'cash')
            return null;
        const found = listOfAssets.current.find(o => {
            return o.id == asset_id;
        });
        if (found) {
            return found;
        }
        else {
            return null;
        }
    };
    const getPlaidAccount = plaid_account_id => {
        const found = listOfPlaidAccounts.current.find(o => {
            return o.id == plaid_account_id;
        });
        if (found) {
            return found;
        }
        else {
            return null;
        }
    };
    const updatePlaidAccount = (plaid_account_id, update_obj) => {
        setPlaidAccounts(listOfPlaidAccounts.current.map(o => {
            if (o.id === plaid_account_id) {
                return Object.assign(Object.assign({}, o), update_obj);
            }
            else {
                return o;
            }
        }));
    };
    const removeAsset = asset_id => {
        setAssets(listOfAssets.current.filter(o => o.id !== asset_id));
    };
    const updateManualCrypto = (crypto_id, update_obj) => {
        setCrypto(listOfManualCrypto.current.map(o => {
            if (o.id === crypto_id && o.currency == update_obj.currency) {
                return Object.assign(Object.assign({}, o), update_obj);
            }
            else {
                return o;
            }
        }));
    };
    const updateSyncedCrypto = (crypto_account_id, coingecko_id, update_obj) => {
        setSyncedCrypto(listOfSyncedCrypto.current.map(o => {
            if (o.id === crypto_account_id &&
                o.currency == update_obj.coingecko_id) {
                return Object.assign(Object.assign({}, o), update_obj);
            }
            else {
                return o;
            }
        }));
    };
    const updateCrypto = (crypto_id, update_obj) => {
        setCrypto(listOfCrypto.current.map(o => {
            if (o.id === crypto_id && o.currency == update_obj.currency) {
                return Object.assign(Object.assign({}, o), update_obj);
            }
            else {
                return o;
            }
        }));
    };
    const getCryptoName = (zabo_account_id, zabo_currency) => {
        const found = listOfCrypto.current.find(o => {
            return o.id == zabo_account_id && o.currency == zabo_currency;
        });
        if (found) {
            return (found.display_name || `${found.institution_name || ''} ${found.name}`);
        }
        else {
            return 'zabo';
        }
    };
    const removeManualCrypto = id => {
        setManualCrypto(listOfManualCrypto.current.filter(o => {
            return o.id !== id;
        }));
    };
    const removeCrypto = obj => {
        setCrypto(listOfCrypto.current.filter(o => {
            if (obj.institution_name) {
                return o.institution_name !== obj.institution_name;
            }
            else if (obj.id && obj.currency) {
                return !(o.id == obj.id && o.currency == obj.currency);
            }
            else if (obj.id) {
                return o.id !== obj.id;
            }
        }));
    };
    const removeSyncedCrypto = (crypto_account_id, currency) => {
        setSyncedCrypto(listOfSyncedCrypto.current.filter(o => {
            return (o.id !== crypto_account_id &&
                // Either remove just one currency, or all of them
                (currency ? o.currency !== currency : true));
        }));
    };
    const getAccountName = (string, include_mask_and_status = false) => {
        if (string == null || string == '')
            return null;
        if (include_mask_and_status) {
            let account = null;
            if ((string === null || string === void 0 ? void 0 : string.indexOf('plaid_')) == 0) {
                account = getPlaidAccount(Number(string.replace('plaid_', '')));
            }
            else if ((string === null || string === void 0 ? void 0 : string.indexOf('manual_')) == 0) {
                account = getAsset(Number(string.replace('manual_', '')));
            }
            return `${account.display_name ||
                `${account.institution_name || ''} ${account.name}`} ${account.mask ? ` (${account.mask})` : ''} - ${account.status}`;
        }
        if ((string === null || string === void 0 ? void 0 : string.indexOf('plaid_')) == 0) {
            return getPlaidAccountName(string.replace('plaid_', ''));
        }
        else if ((string === null || string === void 0 ? void 0 : string.indexOf('manual_')) == 0) {
            return getAssetName(string.replace('manual_', ''));
        }
        return null;
    };
    const getPlaidAccountName = plaid_account_id => {
        if (!plaid_account_id)
            return '';
        const found = listOfPlaidAccounts.current.find(o => {
            return o.id == plaid_account_id;
        });
        if (found) {
            return (found.display_name || `${found.institution_name || ''} ${found.name}`);
        }
        else {
            return 'plaid';
        }
    };
    const getPlaidInstitutionName = plaid_account_id => {
        if (!plaid_account_id)
            return '';
        const found = listOfPlaidAccounts.current.find(o => {
            return o.id == plaid_account_id;
        });
        return (found === null || found === void 0 ? void 0 : found.institution_name) || '';
    };
    const getCryptoSiblings = zabo_account_id => {
        return listOfCrypto.current.filter(o => {
            return o.source == 'zabo' && o.id == zabo_account_id;
        });
    };
    const getSyncedCryptoSiblings = crypto_account_id => {
        return listOfSyncedCrypto.current.filter(o => {
            return o.source == 'synced-crypto' && o.id == crypto_account_id;
        });
    };
    const getCrypto = (zabo_account_id, zabo_currency) => {
        const found = listOfCrypto.current.find(o => {
            return o.id == zabo_account_id && o.currency == zabo_currency;
        });
        if (found) {
            return found;
        }
        else {
            return null;
        }
    };
    const getSyncedCrypto = (crypto_account_id, coingecko_id) => {
        const found = listOfSyncedCrypto.current.find(o => {
            return o.id == crypto_account_id && o.coingecko_id == coingecko_id;
        });
        if (found) {
            return found;
        }
        else {
            return null;
        }
    };
    const getManualCrypto = crypto_manual_id => {
        const found = listOfManualCrypto.current.find(o => {
            return o.id == crypto_manual_id;
        });
        if (found) {
            return found;
        }
        else {
            return null;
        }
    };
    const addTempAccounts = accounts => {
        setPlaidAccounts([...listOfPlaidAccounts.current, ...accounts]);
    };
    const getCounts = () => {
        const currentAssets = [
            ...listOfAssets.current,
            ...listOfPlaidAccounts.current,
            ...listOfCrypto.current,
            ...listOfSyncedCrypto.current,
            ...listOfManualCrypto.current,
        ];
        let accountCount = {
            synced: 0,
            manual: 0,
            error: 0,
            active: 0,
            inactive: 0,
            pending: 0,
            unsupported: 0,
            closed: 0,
            credit: 0,
            investment: 0,
            loan: 0,
            'real estate': 0,
            vehicle: 0,
            cryptocurrency: 0,
            'employee compensation': 0,
            'other liability': 0,
            'other asset': 0,
            cash: 0,
        };
        // Group by type and calculate totals
        currentAssets.forEach(account => {
            if (account.source.indexOf('manual') > -1) {
                accountCount.manual++;
            }
            else {
                accountCount.synced++;
            }
            if (['error', 'relink'].indexOf(account.status) > -1) {
                accountCount.error++;
            }
            else if (account.status == 'active') {
                accountCount.active++;
            }
            else if (account.status == 'inactive') {
                accountCount.inactive++;
            }
            else if (account.status == 'not supported') {
                accountCount.unsupported++;
            }
            else if (account.status == 'syncing' || account.status == 'deleting') {
                accountCount.pending++;
            }
            else if (account.status == 'closed') {
                accountCount.closed++;
            }
            if (account.type == 'depository') {
                accountCount['cash']++;
            }
            else {
                accountCount[account.type]++;
            }
        });
        return accountCount;
    };
    const getTotalsByType = () => {
        const currentAssets = [
            ...listOfAssets.current,
            ...listOfPlaidAccounts.current,
            ...listOfCrypto.current,
            ...listOfSyncedCrypto.current,
            ...listOfManualCrypto.current,
        ];
        let totals = {
            // synced: 0,
            // manual: 0,
            // error: 0,
            // active: 0,
            // inactive: 0,
            // pending: 0,
            // unsupported: 0,
            // closed: 0,
            credit: [],
            investment: [],
            loan: [],
            'real estate': [],
            vehicle: [],
            cryptocurrency: [],
            'employee compensation': [],
            'other liability': [],
            'other asset': [],
            cash: [],
        };
        // Group by type and calculate totals
        currentAssets.forEach(account => {
            // if (account.source.indexOf('manual') > -1) {
            //   totals.manual += account.to_base
            // } else {
            //   totals.synced += account.to_base
            // }
            // if (['error', 'relink'].indexOf(account.status) > -1) {
            //   totals.error += account.to_base
            // } else if (account.status == 'active') {
            //   totals.active += account.to_base
            // } else if (account.status == 'inactive') {
            //   totals.inactive += account.to_base
            // } else if (account.status == 'not supported') {
            //   totals.unsupported += account.to_base
            // } else if (account.status == 'syncing' || account.status == 'deleting') {
            //   totals.pending += account.to_base
            // } else if (account.status == 'closed') {
            //   totals.closed += account.to_base
            // }
            if (account.status == 'active' || account.status == 'relink') {
                if (account.type == 'depository') {
                    totals['cash'].push({
                        name: account.display_name,
                        subtype: account.subtype,
                        to_base: account.to_base,
                    });
                }
                else {
                    totals[account.type].push({
                        name: account.display_name,
                        subtype: account.subtype,
                        to_base: account.to_base,
                    });
                }
            }
        });
        return totals;
    };
    const getAssignableAccounts = () => {
        const list = [...listOfAssets.current, ...listOfPlaidAccounts.current];
        return list
            .filter(o => ((o.source == 'manual' && !o.exclude_transactions) ||
            (o.source == 'plaid' && o.treat_as_manual)) && // Exclude anything that doesn't take transactions
            o.status !== 'closed' && // Exclude closed accounts
            o.status !== 'inactive')
            .sort(function (a, b) {
            return `${a.display_name || ''}`
                .trim()
                .localeCompare(`${b.display_name || ''}`.trim());
        });
    };
    return (React.createElement(AssetsContext.Provider, { value: {
            isReady,
            types,
            subtypes,
            assets: listOfAssets.current,
            plaidAccounts: listOfPlaidAccounts.current,
            crypto: listOfCrypto.current,
            syncedCrypto: listOfSyncedCrypto.current,
            manualCrypto: listOfManualCrypto.current,
            hasAPIKey: hasAPI,
            getCrypto,
            getCryptoName,
            getSyncedCrypto,
            getManualCrypto,
            addAsset,
            removeAsset,
            updateCrypto,
            updateSyncedCrypto,
            updateManualCrypto,
            removeCrypto,
            removeSyncedCrypto,
            removeManualCrypto,
            addTempAccounts,
            getAssetName,
            getAssetInstitutionName,
            getAsset,
            updateAsset,
            getCryptoSiblings,
            getSyncedCryptoSiblings,
            getPlaidAccountName,
            getPlaidInstitutionName,
            getPlaidAccount,
            getAccountName,
            updatePlaidAccount,
            fetchAssets,
            lastUpdate,
            getCounts,
            getTotalsByType,
            getAssignableAccounts,
        } }, props.children));
};
exports.AssetsProvider = AssetsProvider;
