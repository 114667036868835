"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const _ = require("lodash");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const UserProvider_1 = require("@providers/UserProvider");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const react_router_dom_2 = require("react-router-dom");
const format_1 = require("@/helpers/format");
const custom_budgets_1 = require("@/actions/custom_budgets");
const budgets_1 = require("@/actions/budgets");
const user_1 = require("@/actions/user");
const Overview_1 = require("@components/BudgetCustom/Overview");
const Loader_1 = require("@/components/global/Loader");
const NewModal_1 = require("@/components/global/NewModal");
const Sticky_1 = require("@components/elements/Sticky");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const Row_1 = require("@/components/BudgetCustom/Row");
const TransferPopup_1 = require("@/components/BudgetCustom/TransferPopup");
const EditRow_1 = require("@/components/BudgetCustom/EditRow");
const Onboarding_1 = require("@/components/BudgetCustom/Onboarding");
const ClearAllBudgetsBtn_1 = require("@/components/BudgetCustom/ClearAllBudgetsBtn");
const CopyBudgetBtn_1 = require("@/components/BudgetCustom/CopyBudgetBtn");
const OneClickBudgetBtn_1 = require("@/components/BudgetCustom/OneClickBudgetBtn");
const Help_1 = require("@/components/BudgetCustom/Help");
const Budget = ({ history, _showToast, _removeToast, _process }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [showOnboarding, setShowOnboarding] = (0, react_1.useState)(!_user.budgetPeriodSettings.budget_viewed_onboarding);
    const [showIntro, setShowIntro] = (0, react_1.useState)(localStorage.getItem('viewed_budgeting_guide_jan_4') !== 'true');
    const _collapsed = localStorage.getItem(`_lm_budgets_collapsed_obj_${_user.accountId}`);
    const [collapse, setCollapse] = (0, react_1.useState)(_collapsed ? JSON.parse(_collapsed) : {});
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const { year, month, day } = (0, react_router_dom_1.useParams)();
    const [budgets, setBudgets] = (0, react_1.useState)({
        inflow: [],
        outflow: [],
        uncategorized: { num_credit: 0, num_debit: 0 },
        budgeted_last_period: 0,
    });
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(false);
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [tableQuery, setTableQuery] = (0, react_1.useState)({});
    const [periodStart, setPeriodStart] = (0, react_1.useState)(null);
    const [periodEnd, setPeriodEnd] = (0, react_1.useState)(null);
    const [leftToBudget, setLeftToBudget] = (0, react_1.useState)(null);
    const [modalData, setModalData] = (0, react_1.useState)(null);
    const [showModal, setShowModal] = (0, react_1.useState)(null);
    const [modalView, setModalView] = (0, react_1.useState)(null);
    const [hideOccurrences, setHideOccurrences] = (0, react_1.useState)({});
    const [openTransferBudgetPopup, setOpenTransferBudgetPopup] = (0, react_1.useState)({
        category_id: null,
        start_date: null,
    });
    const [editMode, setEditMode] = (0, react_1.useState)(false);
    const [hideNoActivity, setBudgetHideNoActivity] = (0, react_1.useState)(_user.budgetPeriodSettings.hide_no_activity);
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Budget Tracker - Lunch Money';
        // If it's the first time we're landing here, make sure we're on the latest
        if (!_user.budgetPeriodSettings.budget_viewed_onboarding) {
            validatePeriod(Moment().format('YYYY-MM-DD'));
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (_user.budgetPeriodSettings.budget_viewed_onboarding) {
            validatePeriod(year && month
                ? `${year}-${month}-${day || '01'}`
                : localStorage.getItem('_lm_budget_current_period'));
            setBudgetHideNoActivity(_user.budgetPeriodSettings.hide_no_activity);
        }
    }, [year, month, day, _user.budgetPeriodSettings]);
    const onSaveBudget = (budgets) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(budgets_1.createBudget)({
            budgets: budgets.map(o => {
                return Object.assign(Object.assign(Object.assign({ category_id: o.category_id, start_date: o.start_date }, (o.hasOwnProperty('amount') ? { amount: o.amount } : {})), (o.hasOwnProperty('amount')
                    ? { currency: o.currency || _user.primaryCurrency }
                    : {})), { notes: o.notes || null });
            }),
            start_date: periodStart,
            end_date: periodEnd,
        });
        _showToast({
            type: 'success',
            message: 'Budget saved successfully',
        });
        setBudgets(results.data);
    });
    const onMoveBudget = (_a) => __awaiter(void 0, [_a], void 0, function* ({ src_category_id, dest_category_id, src_start_date, dest_start_date, amount, currency, }) {
        const results = yield _process(budgets_1.moveBudget)({
            src_category_id,
            dest_category_id,
            src_start_date,
            dest_start_date,
            amount,
            currency: currency || _user.primaryCurrency,
        });
        if (results) {
            yield fetch();
        }
    });
    const getTransferBudgetPopup = ({ category_id, group_category_id, start_date, rollover_option, available, activity, _budgets, }) => {
        return (React.createElement(semantic_ui_react_1.Popup, { wide: "very", open: openTransferBudgetPopup.category_id === category_id &&
                openTransferBudgetPopup.start_date === start_date, onClose: (e, data) => {
                if (e.type == 'click' || e['key'] == 'Escape') {
                    // Close
                    setOpenTransferBudgetPopup({
                        category_id: null,
                        start_date: null,
                    });
                }
            }, trigger: React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "exchange", className: `${openTransferBudgetPopup.category_id === category_id &&
                        openTransferBudgetPopup.start_date === start_date
                        ? ''
                        : 'show-on-hover'} clickable`, onClick: () => {
                        setOpenTransferBudgetPopup({
                            category_id: category_id,
                            start_date: start_date,
                        });
                    } }), inverted: true, size: "tiny" }, available < 0
                ? 'Move money to this category'
                : 'Move money from this category') },
            React.createElement(TransferPopup_1.default, { leftToBudget: leftToBudget, category_id: category_id, group_category_id: group_category_id, start_date: start_date, rollover_option: rollover_option, available: available, activity: activity, outflowBudgets: budgets.outflow, inflowBudgets: budgets.inflow, onMoveBudget: (obj) => __awaiter(void 0, void 0, void 0, function* () {
                    yield onMoveBudget(obj);
                    _showToast({
                        type: 'success',
                        message: 'Budget moved successfully',
                    });
                    setOpenTransferBudgetPopup({
                        category_id: null,
                        start_date: null,
                    });
                }) })));
    };
    (0, react_1.useEffect)(() => {
        if (periodStart && periodEnd) {
            fetch(true);
        }
    }, [periodStart, periodEnd]);
    const calculateTotalAvailable = budgets => {
        return budgets === null || budgets === void 0 ? void 0 : budgets.filter(o => !o.properties.category.is_group).reduce((acc, budget) => {
            return (acc +
                (budget.properties.budget_settings.rollover_option == 'same category'
                    ? budget.totals.rollover_balance || 0
                    : 0) +
                budget.totals.period_balance);
        }, 0);
    };
    const calculateTotalBudgeted = budgets => {
        return budgets === null || budgets === void 0 ? void 0 : budgets.reduce((acc, budget) => {
            if (budget.properties.historical) {
                // this makes no sense
                return acc;
            }
            if (budget.properties.category.is_child) {
                // Find the group category
                const parentIndex = _.findIndex(budgets, b => {
                    return (b.properties.category.id ==
                        budget.properties.category.group_category_id);
                });
                // Group category calculations should have come before, so its budgeted value should be set
                if (parentIndex > -1 && !!budgets[parentIndex].totals.budgeted) {
                    return acc; // Don't add to it
                }
            }
            return acc + budget.totals.budgeted;
        }, 0);
    };
    (0, react_1.useEffect)(() => {
        console.log({ budgets });
    }, [budgets]);
    const fetch = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (showLoading = false) {
        // Reset budgets
        if (showLoading) {
            setIsLoading(true);
        }
        const results = yield (0, custom_budgets_1.getCustomBudgets)({
            start_date: periodStart,
            end_date: periodEnd,
        });
        setBudgets(results);
        setIsLoading(false);
    });
    function validatePeriod(currentDate) {
        const anchorDate = _user.budgetPeriodSettings.anchor_date;
        const cadenceQuantity = _user.budgetPeriodSettings.quantity;
        const granularity = _user.budgetPeriodSettings.granularity;
        // Parse the current and anchor dates
        const current = Moment(currentDate);
        const anchor = Moment(anchorDate);
        // If the anchor date is after the current date, calculate the difference
        let diff = current.diff(anchor, granularity);
        if (diff % cadenceQuantity !== 0) {
            // If the difference is not a multiple of the cadence quantity, adjust the difference
            diff = diff - (diff % cadenceQuantity);
        }
        // Determine the starting point by adjusting based on the cadence and granularity
        let start = anchor.clone().add(diff, granularity);
        while (current.isBefore(start)) {
            // If the calculated start date is after the current date, move back by one cadence period
            start = start.subtract(cadenceQuantity, granularity);
        }
        // Calculate the end date by adding the cadence period to the start date and subtracting one day
        const end = start
            .clone()
            .add(cadenceQuantity, granularity)
            .subtract(1, 'days');
        setPeriodStart(start.format('YYYY-MM-DD'));
        setPeriodEnd(end.format('YYYY-MM-DD'));
        localStorage.setItem('_lm_budget_current_period', start.format('YYYY-MM-DD'));
        // update history
        history.replace(`/budget/${start.format(granularity == 'month' && (anchorDate === null || anchorDate === void 0 ? void 0 : anchorDate.indexOf('01-01')) > -1
            ? 'YYYY/MM'
            : 'YYYY/MM/DD')}`);
    }
    const calculatePeriod = direction => {
        var _a;
        let newPeriodStart;
        if (direction === 'prev') {
            newPeriodStart = Moment(periodStart).subtract(_user.budgetPeriodSettings.quantity, _user.budgetPeriodSettings.granularity);
        }
        else if (direction === 'next') {
            newPeriodStart = Moment(periodStart).add(_user.budgetPeriodSettings.quantity, _user.budgetPeriodSettings.granularity);
        }
        return Moment(newPeriodStart).format((_user.budgetPeriodSettings.granularity === 'month' &&
            ((_a = _user.budgetPeriodSettings.anchor_date) === null || _a === void 0 ? void 0 : _a.indexOf('01-01')) > -1) ||
            _user.budgetPeriodSettings.granularity === 'year'
            ? 'YYYY/MM'
            : 'YYYY/MM/DD');
    };
    (0, react_1.useEffect)(() => {
        setLeftToBudget(Math.round((calculateTotalAvailable(budgets.inflow) * -1 +
            (budgets['rollover_pool'] || 0) -
            calculateTotalBudgeted(budgets.outflow)) *
            100) / 100);
    }, [budgets]);
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-budget" },
        React.createElement(ContainerHeader_1.default, { backLink: {
                pathname: `/budget/${calculatePeriod('prev')}`,
            }, forwardLink: {
                pathname: `/budget/${calculatePeriod('next')}`,
            }, title: `${Moment(periodStart).format(_user.getFormat(_user.budgetPeriodSettings.granularity == 'year' ||
                !Moment(periodStart).isSame(Moment(), 'year') ||
                !Moment(periodEnd).isSame(Moment(), 'year')
                ? 'month_day_year'
                : 'month_day'))} - ${Moment(periodEnd).format(_user.getFormat(_user.budgetPeriodSettings.granularity == 'year' ||
                !Moment(periodStart).isSame(Moment(), 'year') ||
                !Moment(periodEnd).isSame(Moment(), 'year')
                ? 'month_day_year'
                : 'month_day'))}`, showBackToCurrentMonthButton: !Moment().isBetween(Moment(periodStart), Moment(periodEnd), null, '[]'), jumpBackToCurrentMonth: () => {
                history.push(`/budget/${Moment().format('YYYY/MM/DD')}`);
            }, cadence: "period" }),
        React.createElement("div", { className: "header-buttons" },
            React.createElement("div", { className: "flex--space-between" },
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button.Group, null,
                        React.createElement(semantic_ui_react_1.Button, { basic: !!editMode, color: "orange", onClick: () => {
                                setEditMode(false);
                            } }, "Budget View"),
                        React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: !editMode, onClick: () => {
                                setEditMode(true);
                            } }, "Settings View"))),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                            setShowIntro(true);
                        }, icon: true, labelPosition: "left" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "info" }),
                        React.createElement("span", { className: "position-top-1-5px" }, "Feature Guide")),
                    React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                            setModalView('EDIT_BUDGET_SETTINGS');
                            setModalData({ budgets });
                            setShowModal(true);
                        }, icon: true, labelPosition: "left" },
                        React.createElement(semantic_ui_react_1.Icon, { name: "cog" }),
                        React.createElement("span", { className: "position-top-1-5px" }, "Budget Settings"))),
                React.createElement(semantic_ui_react_1.Button, { className: "ml-1rem display-on-mobile", basic: !showSidebar, color: "orange", icon: true, onClick: () => {
                        setShowSidebar(!showSidebar);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: showSidebar ? 'angle double right' : 'angle double left' })))),
        React.createElement("div", { className: "p-content-container" },
            React.createElement("div", { className: `left`, id: "sticky-counterpart" }, [budgets === null || budgets === void 0 ? void 0 : budgets.inflow, budgets === null || budgets === void 0 ? void 0 : budgets.outflow].map((budgetGroup, index) => {
                const _budgets = _user.budgetPeriodSettings.hide_no_activity
                    ? budgetGroup.filter(o => o.totals.budgeted !== null ||
                        o.totals.activity !== 0 ||
                        o.totals.available !== 0)
                    : budgetGroup;
                const _hidden = _user.budgetPeriodSettings.hide_no_activity
                    ? budgetGroup.filter(o => o.totals.budgeted == null &&
                        o.totals.activity == 0 &&
                        o.totals.available == 0)
                    : [];
                // Calculate numbers for rollover pool, if index == 0
                let rolledOver = [];
                let lastLeftToBudget = 0;
                let totalRolledOver = { inflow: 0, outflow: 0 };
                if (index == 0 && !!budgets['rollover_pool']) {
                    rolledOver = [...budgets === null || budgets === void 0 ? void 0 : budgets.inflow, ...budgets === null || budgets === void 0 ? void 0 : budgets.outflow]
                        .map(o => {
                        if (o.properties.budget_settings.rollover_option ==
                            'available funds') {
                            const historicalIndex = _.findIndex(o.occurrences, o => !o.historical);
                            if (!!o.occurrences[historicalIndex - 1].period_balance) {
                                return {
                                    is_income: o.properties.category.is_income,
                                    category_id: o.properties.category.id,
                                    rollover: o.occurrences[historicalIndex - 1].period_balance,
                                };
                            }
                            else {
                                return null;
                            }
                        }
                        return null;
                    })
                        .filter(o => !!o);
                    totalRolledOver = {
                        inflow: rolledOver
                            .filter(o => o.is_income)
                            .reduce((acc, o) => {
                            return acc + o.rollover;
                        }, 0),
                        outflow: rolledOver
                            .filter(o => !o.is_income)
                            .reduce((acc, o) => {
                            return acc + o.rollover;
                        }, 0),
                    };
                    lastLeftToBudget =
                        budgets['rollover_pool'] - totalRolledOver.outflow;
                }
                return (React.createElement("div", { className: "table-container mb-1rem", key: `budget-table-${index}` },
                    React.createElement(semantic_ui_react_1.Table, { unstackable: true, fixed: true, selectable: true, sortable: true, celled: true, className: "p-budget-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '20px' } }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-category" }, index == 0 ? 'Inflow' : 'Outflow'),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                editMode && (React.createElement(React.Fragment, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Rollover Management"),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "One-Click Budgeting"),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }))),
                                !editMode && (React.createElement(React.Fragment, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-input" },
                                        React.createElement("span", { className: "right-align" }, index == 0 ? 'Expected' : 'Budgeted'),
                                        '  ',
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                            "This is how much you expect to",
                                            ' ',
                                            index == 0 ? 'earn' : 'spend',
                                            " in the category this period.")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-amounts-wider" },
                                        React.createElement("span", { className: "right-align" }, "Activity")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "right-align table-cell-amounts" },
                                        'Available',
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) },
                                            "This is how much is available to",
                                            ' ',
                                            index == 0 ? 'budget from' : 'spend in',
                                            " the category this period, based on current period activity, current period budgeted value and rollover settings.")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }))))),
                        React.createElement(semantic_ui_react_1.Table.Body, { className: !isLoading && (_budgets === null || _budgets === void 0 ? void 0 : _budgets.length) === 0 ? 'g-empty-body' : '' },
                            !isLoading && (_budgets === null || _budgets === void 0 ? void 0 : _budgets.length) === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: editMode ? '9' : '11', className: "center-align" },
                                    React.createElement("p", null,
                                        "You have no ",
                                        index == 0 ? 'income' : 'expense',
                                        ' ',
                                        "categories yet!"),
                                    React.createElement("p", null,
                                        "Get started by",
                                        ' ',
                                        React.createElement(react_router_dom_2.Link, { to: { pathname: '/categories' } }, "adding categories"),
                                        ' ',
                                        "or setting an existing category to",
                                        ' ',
                                        React.createElement(react_router_dom_2.Link, { to: { pathname: '/categories' } }, "Treat as Income"),
                                        ".")))),
                            isLoading && (React.createElement(Loader_1.default, { colSpan: editMode ? '8' : '11' })),
                            !editMode &&
                                index == 0 &&
                                budgets.hasOwnProperty('rollover_pool') &&
                                !!budgets['rollover_pool'] && (React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell", colSpan: 7 },
                                    "Rolled over from previous period (",
                                    Moment(periodStart)
                                        .subtract(_user.budgetPeriodSettings.quantity, _user.budgetPeriodSettings.granularity)
                                        .format(_user.getFormat('month_day')),
                                    ' ',
                                    "-",
                                    ' ',
                                    Moment(periodStart)
                                        .subtract(1, 'day')
                                        .format(_user.getFormat('month_day')),
                                    ")"),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align table-cell-amounts` },
                                    React.createElement("div", { className: "flex--space-between-flex-start" },
                                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", className: "clickable" }), size: "small" },
                                            React.createElement("div", { className: `line-item` },
                                                React.createElement("div", { className: "" }, budgets['rollover_pool'] < 0
                                                    ? 'Overbudgeted last period'
                                                    : 'Left to budget last period'),
                                                React.createElement("div", { className: `underline` }),
                                                React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(lastLeftToBudget, _user.primaryCurrency))), rolledOver === null || rolledOver === void 0 ? void 0 :
                                            rolledOver.filter(o => !o.is_income).map(o => {
                                                return (React.createElement("div", { className: `line-item` },
                                                    React.createElement("div", { className: "" }, _categories.getName(o.category_id)),
                                                    React.createElement("div", { className: `underline` }),
                                                    React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(o.rollover, _user.primaryCurrency))));
                                            }),
                                            !!totalRolledOver.outflow && (React.createElement("div", { className: `line-item` },
                                                React.createElement("div", { className: "font--bold" }, "Total Rollover"),
                                                React.createElement("div", { className: `underline` }),
                                                React.createElement("div", { className: `property monospace no-truncate` }, (0, format_1.toPrice)(budgets['rollover_pool'], _user.primaryCurrency))))),
                                        React.createElement("div", { className: `${budgets['rollover_pool'] < 0
                                                ? 'color--red'
                                                : 'color--green'}` }, (0, format_1.toPrice)(budgets['rollover_pool'], _user.primaryCurrency)))),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                                        setModalView('EDIT_ROLLOVER_POOL');
                                        setShowModal(true);
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover  color--grey` })))),
                            !isLoading &&
                                (_budgets === null || _budgets === void 0 ? void 0 : _budgets.filter(o => o.occurrences.length > 0).map((budget, index2) => {
                                    return editMode ? (React.createElement(EditRow_1.default, Object.assign({}, budget.properties, { showModal: () => {
                                            const unalignedBudgetCategoryIds = _budgets
                                                .filter(o => {
                                                return (o.properties.budget_settings
                                                    .granularity !==
                                                    budget.properties.budget_settings
                                                        .granularity ||
                                                    o.properties.budget_settings.quantity !==
                                                        budget.properties.budget_settings
                                                            .quantity ||
                                                    Moment(o.properties.budget_settings
                                                        .anchor_date).diff(Moment(budget.properties.budget_settings
                                                        .anchor_date), budget.properties.budget_settings
                                                        .granularity, true) %
                                                        budget.properties.budget_settings
                                                            .quantity !==
                                                        0);
                                            })
                                                .map(o => o.properties.category.id);
                                            setModalData(Object.assign(Object.assign({}, budget), { unalignedBudgetCategoryIds }));
                                            setModalView('EDIT_CUSTOM_BUDGET');
                                            setShowModal(true);
                                        } }))) : (React.createElement(Row_1.default, Object.assign({ key: `budget-row-${index}-${index2}`, occurrences: budget.occurrences, totals: budget.totals, recurring: budget.recurring || [], leftToBudget: leftToBudget }, budget.properties, { onSave: onSaveBudget, showTransactions: query => {
                                            setTableQuery(query);
                                            setOpenTransactionsTable(true);
                                        }, showModal: () => {
                                            const unalignedBudgetCategoryIds = _budgets
                                                .filter(o => {
                                                return (o.properties.budget_settings
                                                    .granularity !==
                                                    budget.properties.budget_settings
                                                        .granularity ||
                                                    o.properties.budget_settings.quantity !==
                                                        budget.properties.budget_settings
                                                            .quantity ||
                                                    Moment(o.properties.budget_settings
                                                        .anchor_date).diff(Moment(budget.properties.budget_settings
                                                        .anchor_date), budget.properties.budget_settings
                                                        .granularity, true) %
                                                        budget.properties.budget_settings
                                                            .quantity !==
                                                        0);
                                            })
                                                .map(o => o.properties.category.id);
                                            setModalData(Object.assign(Object.assign({}, budget), { unalignedBudgetCategoryIds }));
                                            setModalView('EDIT_CUSTOM_BUDGET');
                                            setShowModal(true);
                                        }, periodStart: periodStart, periodEnd: periodEnd, openTransferBudgetPopup: openTransferBudgetPopup, setOpenTransferBudgetPopup: opts => {
                                            setOpenTransferBudgetPopup(opts);
                                        }, getTransferBudgetPopup: ({ category_id, group_category_id, start_date, rollover_option, available, activity, }) => {
                                            return getTransferBudgetPopup({
                                                category_id,
                                                group_category_id,
                                                start_date,
                                                rollover_option,
                                                available,
                                                activity,
                                                _budgets,
                                            });
                                        }, hideOccurrences: hideOccurrences, onHideOccurrences: (category_id, value) => {
                                            setHideOccurrences(Object.assign(Object.assign({}, hideOccurrences), { [category_id]: value }));
                                        }, collapse: collapse, setCollapse: setCollapse, hasGeneralPoolRollover: budgets.outflow.filter(o => o.properties.budget_settings
                                            .rollover_option == 'available funds').length > 0 })));
                                })),
                            false &&
                                !editMode &&
                                index == 0 &&
                                !!calculateTotalBudgeted(budgets.outflow) && (React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell", colSpan: 7 }, "Budgeted This Period"),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: `padded-cell-lr right-align table-cell-amounts` },
                                    React.createElement("div", { className: `${calculateTotalBudgeted(budgets.outflow) * -1 < 0
                                            ? 'color--red'
                                            : 'color--green'}` }, (0, format_1.toPrice)(calculateTotalBudgeted(budgets.outflow) * -1, _user.primaryCurrency))),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" }))),
                            _hidden.length > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "clickable padded-cell center-align", colSpan: editMode ? '9' : '11', onClick: () => {
                                        setModalView('EDIT_BUDGET_SETTINGS');
                                        setShowModal(true);
                                    } },
                                    _hidden.length,
                                    " categor",
                                    _hidden.length > 1 ? 'ies have' : 'y has',
                                    " been hidden due to no activity and no assigned budget."))),
                            !editMode &&
                                (budgets === null || budgets === void 0 ? void 0 : budgets.uncategorized) &&
                                (budgets === null || budgets === void 0 ? void 0 : budgets.uncategorized[index == 0 ? 'num_credit' : 'num_debit']) > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "fixed-height" },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "padded-cell center-align", colSpan: editMode ? '8' : '11' },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "red" }),
                                    " You have",
                                    ' ', budgets === null || budgets === void 0 ? void 0 :
                                    budgets.uncategorized[index == 0 ? 'num_credit' : 'num_debit'],
                                    ' ',
                                    "uncategorized ",
                                    index == 0 ? 'credits' : 'expenses',
                                    ' ',
                                    "totalling",
                                    ' ',
                                    (0, format_1.toPrice)(((budgets === null || budgets === void 0 ? void 0 : budgets.uncategorized[index == 0 ? 'credit' : 'debit']) +
                                        (budgets === null || budgets === void 0 ? void 0 : budgets.uncategorized[index == 0
                                            ? 'recurring_income'
                                            : 'recurring_expenses'])) *
                                        (index == 0 ? -1 : 1), _user.primaryCurrency),
                                    ' ',
                                    "that are not counted in this budget.",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "tiny", mouseEnterDelay: 500, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", fitted: true, className: "color--grey clickable dark-grey-on-hover", style: {
                                                position: 'relative',
                                                top: '-2px',
                                            }, onClick: () => {
                                                setTableQuery(Object.assign({ start_date: periodStart, end_date: periodEnd, uncategorized: true }, (index == 0
                                                    ? { income_greater_than: 0 }
                                                    : { expense_greater_than: 0 })));
                                                setOpenTransactionsTable(true);
                                            } }) }, "View transactions (pop-up)"))))),
                        !editMode && (React.createElement(semantic_ui_react_1.Table.Footer, null,
                            React.createElement(semantic_ui_react_1.Table.Row, { className: "transaction-row" },
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "padding", colSpan: 3 },
                                    React.createElement("b", null, "Totals")),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-input right-align " }, (0, format_1.toPrice)(calculateTotalBudgeted(_budgets), _user.primaryCurrency)),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "padded-cell-lr right-align table-cell-amounts" }, (0, format_1.toPrice)((_budgets === null || _budgets === void 0 ? void 0 : _budgets.filter(o => !o.properties.category.is_child).reduce((acc, budget) => acc +
                                    budget.totals.activity +
                                    budget.totals.recurring_activity, 0)) * (index == 0 ? -1 : 1), _user.primaryCurrency)),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "padded-cell-lr right-align table-cell-amounts" }, (0, format_1.toPrice)(index == 0
                                    ? leftToBudget +
                                        calculateTotalBudgeted(budgets.outflow)
                                    : _budgets === null || _budgets === void 0 ? void 0 : _budgets.filter(o => !o.properties.category.is_group).reduce((acc, budget) => {
                                        return (acc +
                                            (budget.properties.budget_settings
                                                .rollover_option == 'same category'
                                                ? budget.totals.rollover_balance || 0
                                                : 0) +
                                            budget.totals.period_balance);
                                    }, 0), _user.primaryCurrency)),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" })))))));
            })),
            React.createElement("div", { className: `right ${showSidebar ? '' : 'hide-on-mobile'}` },
                React.createElement(Sticky_1.default, { reset: showSidebar || !isLoading ? new Date().getTime() : null, content: React.createElement(Overview_1.default, { leftToBudget: leftToBudget, totalAvailableInflow: calculateTotalAvailable(budgets.inflow), totalAvailableOutflow: calculateTotalAvailable(budgets.outflow), totalBudgeted: calculateTotalBudgeted(budgets.outflow), periodEnd: periodEnd, periodStart: periodStart, oneClickBudgetBtn: React.createElement(OneClickBudgetBtn_1.default, { budgets: budgets, onSaveBudget: onSaveBudget, periodEnd: periodEnd }), clearAllBudgetsBtn: React.createElement(ClearAllBudgetsBtn_1.default, { budgets: budgets, _showToast: _showToast, _process: _process, callback: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield fetch();
                            }) }), copyBudgetBtn: React.createElement(CopyBudgetBtn_1.default, { budgets: budgets, _showToast: _showToast, periodStart: periodStart, onSaveBudget: onSaveBudget }) }) }))),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: Object.assign(Object.assign({}, modalData), { periodStart,
                periodEnd }), utils: {
                _process: _process,
                _showToast: _showToast,
                onSaveBudget,
                onShowOccurrences: (category_id, value) => {
                    setHideOccurrences(Object.assign(Object.assign({}, hideOccurrences), { [category_id]: value }));
                },
            }, view: modalView, closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                yield fetch();
            }) })),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable, onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setOpenTransactionsTable(false);
                setTableQuery({});
            }), headerText: `Transactions`, query: Object.assign(Object.assign(Object.assign({ time: 'custom', match: 'all' }, (_user.settings['allow_recurring_categories']
                ? {}
                : { recurring_exclude: 'any' })), { status_exclude: 'pending' }), tableQuery), _process: _process, _showToast: _showToast, _removeToast: _removeToast }),
        showIntro && (React.createElement(Help_1.default, { budgets: budgets, close: () => {
                localStorage.setItem('viewed_budgeting_guide_jan_4', 'true');
                setShowIntro(false);
            } })),
        showOnboarding && (React.createElement(Onboarding_1.default, { periodStart: periodStart, closeOnboarding: () => __awaiter(void 0, void 0, void 0, function* () {
                yield (0, user_1.updateAccountPreferences)({
                    budget_viewed_onboarding: true,
                });
                yield _user.fetchAccountSettings();
                setShowOnboarding(false);
                yield fetch();
            }) }))));
};
exports.default = Budget;
