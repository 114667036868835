"use strict";
/**
 *  AddCategoryGroup.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const categories_1 = require("@actions/categories");
const react_1 = require("react");
const EditableString_1 = require("@components/elements/EditableString");
// Types
const checks_1 = require("@helpers/checks");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const AddCategoryGroup = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c;
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [categoryName, setCategoryName] = (0, react_1.useState)(null);
    const [categoryDescription, setCategoryDescription] = (0, react_1.useState)(null);
    const [categoryIsIncome, setCategoryIsIncome] = (0, react_1.useState)(false);
    const [categoryExcludeFromTotals, setCategoryExcludeFromTotals] = (0, react_1.useState)(false);
    const [categoryExcludeFromBudget, setCategoryExcludeFromBudget] = (0, react_1.useState)(false);
    const [categoryId, setCategoryId] = (0, react_1.useState)(null);
    const [selectedCategories, setSelectedCategories] = (0, react_1.useState)({});
    const [categoriesUI, setCategoriesUI] = (0, react_1.useState)([]);
    const [newCategories, setNewCategories] = (0, react_1.useState)([]);
    const [newCategoriesNames, setNewCategoriesNames] = (0, react_1.useState)({});
    const [operation, setOperation] = (0, react_1.useState)('Save Changes');
    const [enableEdit, setEnableEdit] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        setDisableOnClickOutside(false);
        if (!(0, checks_1.isNull)((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.id)) {
            // !isNull(data?.category?.children) && !
            const selected = {};
            (_c = (_b = data.category) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.forEach(cat => {
                selected[cat.id] = true;
            });
            setSelectedCategories(selected);
            setCategoryName(data.category.name);
            setCategoryDescription(data.category.description);
            setOperation('Save Changes');
            setCategoryId(data.category.id);
        }
        else {
            setSelectedCategories({});
            setCategoryName(null);
            setCategoryDescription(null);
            setOperation('Create');
            setCategoryId(null);
        }
    }, [data]);
    (0, react_1.useEffect)(() => {
        setCategoriesUI(_categories.assignable
            .filter(o => {
            // Exclude other category groups
            return !o.is_group;
        })
            .sort((a, b) => {
            // Show the current categories at the top
            if (a.group_id == categoryId && b.group_id == categoryId) {
                return a.name.localeCompare(b.name);
            }
            else if (a.group_id == categoryId) {
                return -1;
            }
            else if (b.group_id == categoryId) {
                return 1;
            }
            // Then go by alphabetical order
            return a.name.localeCompare(b.name);
        })
            .map((category, index) => {
            // Includes individual categories and other categories already in groups
            return (React.createElement("div", { key: `category-child-${index}`, className: `setup-category ${selectedCategories[category.id] ? 'added' : ''}`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (selectedCategories[category.id]) {
                        const updateObj = Object.assign(Object.assign({}, selectedCategories), { [category.id]: null });
                        setSelectedCategories(updateObj);
                        setDisableOnClickOutside(true);
                        setEnableEdit(true);
                    }
                    else {
                        const updateObj = Object.assign(Object.assign({}, selectedCategories), { [category.id]: true });
                        setSelectedCategories(updateObj);
                        setDisableOnClickOutside(true);
                        setEnableEdit(true);
                    }
                }) },
                React.createElement("div", null,
                    React.createElement("span", { className: "category-payee" }, category.name),
                    category.group_id && category.group_id !== categoryId && (React.createElement("span", { className: "category-metadata" },
                        "Currently in group: ",
                        _categories.getName(category.group_id)))),
                selectedCategories[category.id] && React.createElement(semantic_ui_react_1.Icon, { name: "check circle" }),
                !selectedCategories[category.id] && (React.createElement(semantic_ui_react_1.Icon, { name: "circle outline" }))));
        }));
    }, [categoryId, selectedCategories]);
    // Setup
    (0, react_1.useEffect)(() => {
        var _a;
        useModal({
            header: (React.createElement("div", null,
                operation === 'Save Changes' && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        switchView('EDIT_CATEGORY');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " Category Details")),
                React.createElement("span", { className: "header-text-large" },
                    operation === 'Save Changes'
                        ? `Edit ${(_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.name}`
                        : 'Create Category Group',
                    ' ',
                    categoryName))),
            leftButton: React.createElement(React.Fragment, null),
            middleButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false, true);
                } }, "Cancel")),
        });
    }, [data, operation]);
    const validNewCategoryName = o => !(0, checks_1.isNull)(o) && !(0, checks_1.isEmptyString)(o) && o !== '--------deleted--------';
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const numValidCategories = [
            ...(_a = Object.keys(selectedCategories)) === null || _a === void 0 ? void 0 : _a.filter(o => !!selectedCategories[o]),
            ...(_b = Object.values(newCategoriesNames)) === null || _b === void 0 ? void 0 : _b.filter(validNewCategoryName),
        ].length;
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: numValidCategories < 1 ||
                    (operation === 'Create' && (0, checks_1.isNullOrEmpty)(categoryName)) ||
                    (operation === 'Save Changes' && !enableEdit), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (operation === 'Create') {
                        const results = yield utils._process(categories_1.createCategoryGroup)({
                            name: categoryName,
                            description: categoryDescription,
                            is_income: categoryIsIncome,
                            exclude_from_budget: categoryExcludeFromBudget,
                            exclude_from_totals: categoryExcludeFromTotals,
                            category_ids: Object.keys(selectedCategories).filter(o => !!selectedCategories[o]),
                            new_categories: Object.values(newCategoriesNames).filter(validNewCategoryName),
                        });
                        if (!results.error) {
                            // Should refetch categories
                            yield _categories.fetchCategories();
                            utils._showToast({
                                message: 'Successfully created category group',
                                type: 'success',
                            });
                            setVisibility(false);
                        }
                    }
                    else if (operation === 'Save Changes') {
                        const results = yield utils._process(categories_1.editCategoryGroup)(categoryId, {
                            name: categoryName,
                            description: categoryDescription,
                            is_income: categoryIsIncome,
                            exclude_from_budget: categoryExcludeFromBudget,
                            exclude_from_totals: categoryExcludeFromTotals,
                            category_ids: Object.keys(selectedCategories).filter(o => !!selectedCategories[o]),
                            new_categories: Object.values(newCategoriesNames).filter(validNewCategoryName),
                        });
                        if (!results.error) {
                            // Should refetch categories
                            yield _categories.fetchCategories();
                            utils._showToast({
                                message: 'Successfully edited category group',
                                type: 'success',
                            });
                            setVisibility(false);
                        }
                    }
                }) },
                operation,
                " ",
                operation === 'Create' && React.createElement(React.Fragment, null,
                    "(",
                    numValidCategories,
                    ")"))),
        });
    }, [
        selectedCategories,
        categoryName,
        categoryDescription,
        categoryIsIncome,
        categoryExcludeFromBudget,
        categoryExcludeFromTotals,
        categoryId,
        operation,
        newCategoriesNames,
    ]);
    return (React.createElement("div", { className: "g-categories-setup" },
        operation === 'Create' && (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Message, { info: true },
                "Use groups to organize your categories. You cannot set a transaction's category to be a category group, but you can set a budget for category groups.",
                ' ',
                React.createElement(semantic_ui_react_1.Popup, { position: "bottom right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), inverted: true, size: "small" },
                    React.createElement("p", null, "For example, use category groups to group together all your Food categories (Restaurants, Groceries, Coffee Shops) while still tracking how much you spend within each category."))),
            React.createElement("div", { id: "transactions-modal-detail-view", className: "mb-0" },
                React.createElement("div", { className: "transaction-details" },
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Category group name"),
                        React.createElement(EditableString_1.default, { state: 'Editing', location: 'modal', identifier: 'category-group-name', firstValue: categoryName, placeholder: "e.g. Food", shouldSaveOnChange: true, autoFocus: true, onSave: name => {
                                setCategoryName(name);
                                setDisableOnClickOutside(true);
                            } })),
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null, "Category group description"),
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.TextArea, { rows: 2, placeholder: 'Optional', value: categoryDescription || '', onChange: (e, { value }) => {
                                            setCategoryDescription(value);
                                            setDisableOnClickOutside(true);
                                        } }))))),
                    React.createElement("div", { className: "transaction-detail" },
                        React.createElement("label", null,
                            "Category group properties",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: 'top right', size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }) }, "Categories in this group will inherit these properties.")),
                        React.createElement(semantic_ui_react_1.Form, { className: "mt-05rem" },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryIsIncome, disabled: !!((_a = data === null || data === void 0 ? void 0 : data.category) === null || _a === void 0 ? void 0 : _a.group_id), onChange: (e, { checked }) => {
                                            setCategoryIsIncome(checked);
                                            setDisableOnClickOutside(true);
                                        }, label: "Treat as income" }),
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "question circle" }) },
                                        React.createElement("p", null, "Check this to treat this category as income. Transactions in these categories will be totaled to represent your income throughout Lunch Money, such as in monthly summaries and the Overview page's charts."),
                                        React.createElement("p", null,
                                            React.createElement("b", null, "Examples:"),
                                            " income from work, interest income, side project income, bank bonuses, etc.",
                                            ' ')))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryExcludeFromBudget, disabled: !!((_b = data === null || data === void 0 ? void 0 : data.category) === null || _b === void 0 ? void 0 : _b.group_id), onChange: (e, { checked }) => {
                                            setCategoryExcludeFromBudget(checked);
                                            setDisableOnClickOutside(true);
                                        }, label: "Exclude from budget" }),
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "question circle" }) },
                                        React.createElement("p", null, "Check this to exclude this category from the budget. This means that it will not show up as a category in the Budget view."),
                                        React.createElement("p", null,
                                            React.createElement("b", null, "Examples:"),
                                            " emergencies, medical bills, reimbursements, etc.")))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: categoryExcludeFromTotals, disabled: !!((_c = data === null || data === void 0 ? void 0 : data.category) === null || _c === void 0 ? void 0 : _c.group_id), onChange: (e, { checked }) => {
                                            setCategoryExcludeFromTotals(checked);
                                            setDisableOnClickOutside(true);
                                        }, label: "Exclude from totals" }),
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "color--grey", name: "question circle" }) },
                                        React.createElement("p", null, "Check this to exclude this category from any expenses or income totals in summaries. This means that transactions in these categories will not be reflected in the total spending or income for that month."),
                                        React.createElement("p", null,
                                            React.createElement("b", null, "Examples:"),
                                            " reimbursements, certain income, etc.")))))))))),
        React.createElement("div", { className: "category-content" },
            operation === 'Create' && (React.createElement("h2", { className: "center-align" }, "Categories to include")),
            React.createElement("div", { key: `category-create-new`, className: `setup-category`, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setNewCategories(newCategories.concat([null]));
                }) },
                React.createElement("span", { className: "category-payee" }, "Create new category"),
                React.createElement(semantic_ui_react_1.Icon, { name: "add circle" })),
            newCategories.map((category, index) => {
                return newCategoriesNames[index] === '--------deleted--------' ? (React.createElement("div", { key: `null-${index}` })) : (React.createElement("div", { key: `category-new-${index}`, className: `setup-category added`, onClick: () => __awaiter(void 0, void 0, void 0, function* () { }) },
                    React.createElement(EditableString_1.default, { identifier: `new-category-${index}`, location: 'inline', firstValue: category, autoFocus: true, state: 'Editing', shouldSaveOnBlur: false, shouldSaveOnEnter: false, shouldSaveOnChange: true, shouldSelectOnFocus: false, onSave: name => {
                            setEnableEdit(true);
                            setNewCategoriesNames(Object.assign(Object.assign({}, newCategoriesNames), { [index]: name }));
                        } }),
                    React.createElement(semantic_ui_react_1.Icon, { name: "times circle", style: { marginTop: '3px' }, color: "red", onClick: () => {
                            setNewCategoriesNames(Object.assign(Object.assign({}, newCategoriesNames), { [index]: '--------deleted--------' }));
                        } })));
            }),
            categoriesUI)));
};
exports.default = AddCategoryGroup;
