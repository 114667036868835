"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpandedBudget = exports.removeBudgets = exports.setToGroupSum = exports.unsetMonthBudget = exports.copyBudget = exports.createMultipleBudgets = exports.updateBudgetConfig = exports.moveBudget = exports.createBudget = exports.getBudget = exports.getBudgetsPage = void 0;
const common_1 = require("./common");
/**
 *
 *
 */
// DEPRECATE
const getBudgetsPage = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, common_1.get)('snapshot/budgets_page', opts);
    return response;
});
exports.getBudgetsPage = getBudgetsPage;
/**
 *
 *
 */
const getBudget = (params) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`budgets`, params);
});
exports.getBudget = getBudget;
/**
 *
 *
 */
const createBudget = (budget) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets`, budget);
});
exports.createBudget = createBudget;
/**
 *
 *
 */
const moveBudget = (budget) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/move`, budget);
});
exports.moveBudget = moveBudget;
/**
 *
 *
 */
const updateBudgetConfig = (config) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/config`, config);
});
exports.updateBudgetConfig = updateBudgetConfig;
/**
 *
 *
 */
const createMultipleBudgets = (budget) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/multiple`, budget);
});
exports.createMultipleBudgets = createMultipleBudgets;
/**
 *
 *
 */
// DEPRECATE
const copyBudget = (destination_date) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/copy`, { destination_date });
});
exports.copyBudget = copyBudget;
/**
 *
 *
 */
const unsetMonthBudget = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/unset`, obj);
});
exports.unsetMonthBudget = unsetMonthBudget;
// DEPRECATE
const setToGroupSum = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('budgets/set_to_group_sum', opts);
});
exports.setToGroupSum = setToGroupSum;
const removeBudgets = (budget) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`budgets/remove`, budget);
});
exports.removeBudgets = removeBudgets;
const getExpandedBudget = (budget) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`budgets/expanded`, budget);
});
exports.getExpandedBudget = getExpandedBudget;
