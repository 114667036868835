"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDOM = require("react-dom");
const react_router_dom_1 = require("react-router-dom");
const Routes_1 = require("./Routes");
require("./assets/styles/index.scss");
require("./semantic-ui/semantic.less");
const Layout_1 = require("./containers/Layout");
const AuthProvider_1 = require("@providers/AuthProvider");
// import { createTheme } from '@material-ui/core/styles'
// import { ThemeProvider } from '@material-ui/core'
// Environments
let environment = 'development';
if (window.location.href.indexOf('staging.lunchmoney') >= 0) {
    environment = 'staging';
}
else if (window.location.href.indexOf('beta.lunchmoney') >= 0) {
    environment = 'beta';
}
else if (window.location.href.indexOf('lunchmoney') >= 0) {
    environment = 'production';
}
// Sentry
const Sentry = require("@sentry/react");
Sentry.init({
    environment,
    dsn: 'https://741875eed94862df116e4ecc01aa902f@o4507064740216832.ingest.us.sentry.io/4507109716459520',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/(api\-staging\.|api\-beta\.|api\.|)lunchmoney\.app/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// MUI
// import { LicenseInfo } from '@mui/x-data-grid-pro'
// LicenseInfo.setLicenseKey(
//   '257ebedb3ea9559c763cd0dc54164df7T1JERVI6MzkzOTksRVhQSVJZPTE2Nzg1MTEzNTUwMDAsS0VZVkVSU0lPTj0x',
// )
// Styles
// import './semantic/dist/semantic.min.css'
// Providers
const GlobalProvider_1 = require("./providers/GlobalProvider");
const react_toast_notifications_1 = require("react-toast-notifications");
const UserProvider_1 = require("./providers/UserProvider");
const StaticProvider_1 = require("./providers/StaticProvider");
const BillingProvider_1 = require("./providers/BillingProvider");
const CategoriesProvider_1 = require("./providers/CategoriesProvider");
const TagsProvider_1 = require("./providers/TagsProvider");
const AssetsProvider_1 = require("./providers/AssetsProvider");
const RecurringProvider_1 = require("./providers/RecurringProvider");
// We have this so on refresh, it stays dark
// This goes off of localStorage
if (localStorage.getItem('_lm_dark_mode_setting') == 'never') {
    // Never dark mode
    document.body.classList.add('no-dark');
}
else if (window.matchMedia('(prefers-color-scheme: dark)').matches ||
    localStorage.getItem('_lm_dark_mode_setting') == 'dark') {
    // Always dark mode or currently is dark mode (browser setting)
    // For charts
    document.body.classList.add('dark');
    if (localStorage.getItem('_lm_dark_mode_setting') == 'dark') {
        // For CSS
        document.body.classList.add('dark-mode');
    }
}
else {
    // Browser setting and not currently dark mode
    document.body.classList.remove('dark');
    document.body.classList.remove('dark-mode');
}
// Prevent drag/drop
window.addEventListener('dragover', function (e) {
    e.preventDefault();
}, false);
window.addEventListener('drop', function (e) {
    e.preventDefault();
}, false);
// Auto-track for Plausible
const plausible_1 = require("@helpers/plausible");
(0, plausible_1.enableAutoPageviews)();
ReactDOM.render(React.createElement(react_router_dom_1.BrowserRouter, null,
    React.createElement(StaticProvider_1.StaticProvider, null,
        React.createElement(AuthProvider_1.AuthProvider, null,
            React.createElement(BillingProvider_1.BillingProvider, null,
                React.createElement(UserProvider_1.UserProvider, null,
                    React.createElement(CategoriesProvider_1.CategoriesProvider, null,
                        React.createElement(TagsProvider_1.TagsProvider, null,
                            React.createElement(AssetsProvider_1.AssetsProvider, null,
                                React.createElement(RecurringProvider_1.RecurringProvider, null,
                                    React.createElement(react_toast_notifications_1.ToastProvider, { autoDismiss: true, autoDismissTimeout: 6000, placement: "bottom-left" },
                                        React.createElement(GlobalProvider_1.default, null,
                                            React.createElement(Layout_1.default, null,
                                                React.createElement(Routes_1.default, null))))))))))))), document.getElementById('root'));
