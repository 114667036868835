"use strict";
/**
 *  Transactions.tsx
 *  Container for the Transactions page
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const EditableString_1 = require("@components/elements/EditableString");
const react_1 = require("react");
const categories_1 = require("../actions/categories");
// Semantic UI
const semantic_ui_react_1 = require("semantic-ui-react");
// Components
const react_beautiful_dnd_1 = require("react-beautiful-dnd");
const NewModal_1 = require("@components/global/NewModal");
const CategoryRow_1 = require("../components/Categories/CategoryRow");
const NewCategoryRow_1 = require("../components/Categories/NewCategoryRow");
const ContainerHeader_1 = require("../components/elements/ContainerHeader");
const Loader_1 = require("../components/global/Loader");
const ColumnResizer_1 = require("@components/elements/ColumnResizer");
const react_router_dom_1 = require("react-router-dom");
const CategoriesProvider_1 = require("@providers/CategoriesProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const PopupTransactions_1 = require("@/components/Transactions/PopupTransactions");
const Categories = ({ _process, _removeToast, _showToast }) => {
    var _a;
    const visibleCategories = (0, react_1.useRef)([]);
    const history = (0, react_router_dom_1.useHistory)();
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [dragDescriptor, setDragDescriptor] = (0, react_1.useState)(null);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [modalData, setModalData] = (0, react_1.useState)(null);
    const [modalView, setModalView] = (0, react_1.useState)(null);
    const [archived, setArchived] = (0, react_1.useState)(_categories.flattened.filter(o => o.archived));
    const [showArchived, setShowArchived] = (0, react_1.useState)(localStorage.getItem('_lm_categories_show_archived') === 'true');
    const [dismissIncomeWarning, setDismissIncomeWarning] = (0, react_1.useState)(localStorage.getItem('_lm_dismissed_income_category_warning') === 'true');
    const [collapsed, setCollapsed] = (0, react_1.useState)({});
    const [categoryRows, setCategoryRows] = (0, react_1.useState)(null);
    const [showNewCategory, setShowNewCategory] = (0, react_1.useState)(null);
    const savingCategory = (0, react_1.useRef)(null);
    const [orderedCategories, setOrderedCategories] = (0, react_1.useState)(_categories.flattened.filter(o => !o.archived));
    const [openTransactionsTable, setOpenTransactionsTable] = (0, react_1.useState)(false);
    const [selectedCategory, setSelectedCategory] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        savingCategory.current = null;
        setOrderedCategories(_categories.flattened.filter(o => !o.archived));
        setArchived(_categories.flattened.filter(o => o.archived));
    }, [_categories.flattened]);
    let displayNameWidth = localStorage.getItem(`categories-display-name`);
    let descriptionWidth = localStorage.getItem(`categories-description`);
    // Insurance against absurdly large tables on small screens
    if (displayNameWidth > descriptionWidth) {
        displayNameWidth = null;
    }
    else {
        descriptionWidth = null;
    }
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Categories - Lunch Money';
        // Set event listeners for key presses
        document.addEventListener('keydown', handleKeyDown, false);
        refreshCategories();
    }, []);
    /**
     * Key listener on body
     */
    const handleKeyDown = event => {
        if (event.keyCode === 27) {
            // ESC exits new transaction
            setShowNewCategory(null);
        }
    };
    const refreshCategories = () => __awaiter(void 0, void 0, void 0, function* () {
        const _collapsed = {};
        _categories.flattened
            .filter(o => o.collapsed && o.is_group)
            .forEach(o => {
            _collapsed[o.id] = true;
        });
        setCollapsed(_collapsed);
        setShowNewCategory(null);
        setIsLoading(false);
    });
    const _updateCategory = (id_1, body_1, ...args_1) => __awaiter(void 0, [id_1, body_1, ...args_1], void 0, function* (id, body, showSuccess = true) {
        const results = yield _process(categories_1.updateCategory)(id, Object.assign({}, body));
        if (!results.error) {
            _categories.update(id, body);
            if (showSuccess) {
                // Show success message
                _showToast({
                    message: 'Successfully updated category',
                    type: 'success',
                });
            }
            return true;
        }
    });
    const _createCategory = (body) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield _process(categories_1.createCategory)(Object.assign(Object.assign({ order: 0 }, (showNewCategory == 'group' ? { is_group: true } : {})), body));
        if (!results.error) {
            _categories.add(results.data);
            setShowNewCategory(null);
            // Show success message
            _showToast({
                message: `Successfully created category ${showNewCategory == 'group' ? 'group' : ''}`,
                type: 'success',
            });
        }
    });
    (0, react_1.useEffect)(() => {
        const rows = [];
        let index = 0;
        // reset visibleCategories
        visibleCategories.current = [];
        orderedCategories.forEach(category => {
            var _a, _b;
            if (!category.archived) {
                // If it's not in a group OR its parent is not collapsed
                if (!category.group_id || !collapsed[category.group_id]) {
                    visibleCategories.current.push({
                        category: category,
                        id: category.id,
                        is_group: category.is_group,
                        group_id: category.group_id,
                        name: category.name,
                        index,
                    });
                    rows.push(React.createElement(CategoryRow_1.default, { key: `category-row-${category.id}`, update: _updateCategory, category: category, draggableIndex: index, dragDescriptor: dragDescriptor, isSaving: category.id == savingCategory.current, isCollapsed: collapsed[category.id], numChildren: ((_b = (_a = category === null || category === void 0 ? void 0 : category.children) === null || _a === void 0 ? void 0 : _a.filter(o => !o.archived)) === null || _b === void 0 ? void 0 : _b.length) || 0, setCollapsed: id => {
                            const newValue = Object.assign(Object.assign({}, collapsed), { [id]: !collapsed[id] });
                            _updateCategory(category.id, { collapsed: !collapsed[id] }, false);
                            setCollapsed(newValue);
                        }, openTable: category => {
                            setSelectedCategory(category);
                            setOpenTransactionsTable(true);
                        }, onOpenModal: category => {
                            setModalData(category);
                            setShowModal(true);
                            setModalView('EDIT_CATEGORY');
                        } }));
                    index++;
                }
            }
        });
        setCategoryRows(rows.filter(o => !!o));
    }, [collapsed, orderedCategories, dragDescriptor]);
    const handleDrag = (dragResults, type) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h;
        if (!dragResults.destination)
            return;
        let sourceIdx = (_b = dragResults.source) === null || _b === void 0 ? void 0 : _b.index;
        let destIdx = (_c = dragResults.destination) === null || _c === void 0 ? void 0 : _c.index;
        if (((_d = dragResults.source) === null || _d === void 0 ? void 0 : _d.index) > ((_e = dragResults.destination) === null || _e === void 0 ? void 0 : _e.index)) {
            destIdx--;
        }
        const source = visibleCategories.current[sourceIdx];
        const dest = visibleCategories.current[destIdx];
        const changes = [];
        const notifications = {};
        const categoryUpdate = {};
        if (sourceIdx == destIdx || source.id == (dest === null || dest === void 0 ? void 0 : dest.group_id)) {
            // changes.push('No change')
            if (type == 'onDragEnd') {
                return;
            }
        }
        else if (!source.is_group) {
            /**
             * 1. Source is not a category group
             */
            /**
             * a. Reordering
             */
            if (destIdx == -1) {
                changes.push('Move to the top');
            }
            else {
                if (!dest.is_group) {
                    changes.push(`Reorder to after '${_categories.getName(dest === null || dest === void 0 ? void 0 : dest.id)}'`);
                }
            }
            /**
             * b. Removing from group if:
             *    - destination is in a different group
             *    - destination is not associated with any group
             */
            if (source.group_id && source.group_id !== ((dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.id))) {
                changes.push(`Remove from group '${_categories.getName(source.group_id)}'`);
                notifications['remove'] = _categories.getName(source.group_id);
                if (type == 'onDragEnd') {
                    categoryUpdate['group_id'] = null;
                }
            }
            /**
             * c. Adding to group
             */
            if (destIdx !== -1) {
                if (
                // Destination is associated with a category group
                ((dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.is_group)) &&
                    // Source group is different from destination group
                    source.group_id !== ((dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.id)) &&
                    // Destination is not the last element OR it is and it's an empty group
                    (destIdx !== visibleCategories.current.length - 1 || dest.is_group) &&
                    // Destination is not the last individual category in the group
                    ((dest === null || dest === void 0 ? void 0 : dest.is_group) || visibleCategories.current[destIdx + 1].group_id)) {
                    notifications['add'] = _categories.getName((dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.id));
                    changes.push(`Move to group '${_categories.getName((dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.id))}'`);
                    if (type == 'onDragEnd') {
                        categoryUpdate['group_id'] = (dest === null || dest === void 0 ? void 0 : dest.group_id) || (dest === null || dest === void 0 ? void 0 : dest.id);
                    }
                }
            }
        }
        else {
            /**
             * 2. Source is a category group
             */
            if (destIdx == -1) {
                // changes.push('Move to the top')
            }
            else if (
            // Do not allow moving a category group if:
            // 1. Moving into the same group
            source.id == dest.group_id ||
                // 2. Moving after a category group that has children (and it's not at the end)
                ((dest === null || dest === void 0 ? void 0 : dest.is_group) &&
                    destIdx !== visibleCategories.current.length - 1 &&
                    visibleCategories.current[destIdx + 1].group_id == dest.id) ||
                // 3. Moving after a category in the middle of a group (and it's not at the end)
                ((dest === null || dest === void 0 ? void 0 : dest.group_id) &&
                    destIdx !== visibleCategories.current.length - 1 &&
                    visibleCategories.current[destIdx + 1].group_id == dest.group_id)) {
                changes.push(`Not allowed to move category group into another group`);
                notifications['not_allowed'] = true;
                if (type == 'onDragEnd') {
                    return;
                }
            }
            else {
                if (!dest.is_group ||
                    (dest.is_group &&
                        destIdx !== visibleCategories.current.length - 1 &&
                        visibleCategories.current[destIdx + 1].is_group) ||
                    (dest.is_group &&
                        destIdx !== visibleCategories.current.length - 1 &&
                        visibleCategories.current[destIdx + 1].group_id !== dest.id)) {
                    changes.push(`Reorder to after '${_categories.getName(dest === null || dest === void 0 ? void 0 : dest.id)}'`);
                }
            }
        }
        if (type == 'onDragEnd') {
            // Reorder categoryRows
            const _rows = [...visibleCategories.current];
            const selected = [visibleCategories.current[sourceIdx]];
            // reset
            if (((_f = dragResults.source) === null || _f === void 0 ? void 0 : _f.index) > ((_g = dragResults.destination) === null || _g === void 0 ? void 0 : _g.index)) {
                destIdx++;
            }
            if (selected[0].is_group) {
                // Include all the children categories, too
                let idx = 1;
                while (((_h = visibleCategories.current[sourceIdx + idx]) === null || _h === void 0 ? void 0 : _h.group_id) ===
                    selected[0].id) {
                    selected.push(visibleCategories.current[sourceIdx + idx]);
                    idx++;
                }
            }
            if (destIdx > sourceIdx) {
                _rows.splice(sourceIdx, selected.length);
                _rows.splice(destIdx - selected.length + 1, 0, ...selected);
            }
            else {
                _rows.splice(destIdx, 0, ...selected);
                _rows.splice(sourceIdx + selected.length, selected.length);
            }
            // For temp
            savingCategory.current = source.id;
            // Need to re-map orderedCategories from new visibleCategories (_rows)
            const _orderedCategories = [];
            _rows.forEach(o => {
                _orderedCategories.push(o.category);
                if (o.is_group && collapsed[o.id]) {
                    _orderedCategories.push(...orderedCategories.filter(p => p.group_id == o.id));
                }
            });
            setOrderedCategories(_orderedCategories);
            // Process a group update
            if (categoryUpdate.hasOwnProperty('group_id')) {
                yield _process(categories_1.updateCategory)(source.id, {
                    group_id: categoryUpdate['group_id'],
                });
            }
            // Process a re-order
            yield _process(categories_1.updateOrder)({
                order: _orderedCategories.map(o => o.id),
            });
            yield _categories.fetchCategories(); // Refresh it
            _showToast({
                message: 'Successfully moved category',
                type: 'success',
            });
        }
        let descriptor = null;
        if (notifications['not_allowed']) {
            descriptor = `Not allowed to move category group to another category group`;
        }
        else if (notifications['add']) {
            descriptor = `Move to group '${notifications['add']}'`;
        }
        else {
            if (notifications['remove']) {
                descriptor = `Remove from group '${notifications['remove']}'`;
            }
        }
        setDragDescriptor(descriptor);
        // setDragDescriptor(changes.join(', '))
    });
    return (React.createElement(semantic_ui_react_1.Container, { className: "g-categories" },
        React.createElement(ContainerHeader_1.default, { title: "Categories" }),
        _categories.flattened.filter(o => o.is_income && !o.archived).length ==
            0 &&
            !dismissIncomeWarning && (React.createElement(semantic_ui_react_1.Message, { warning: true, onDismiss: () => {
                setDismissIncomeWarning(true);
                localStorage.setItem('_lm_dismissed_category_income_warning', 'true');
            } },
            React.createElement(semantic_ui_react_1.Message.Header, null, "You have no income categories set up."),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement("p", null, "Throughout Lunch Money, your transactions are distinguished as being income or non-income. This is done through category properties. By denoting a category as 'Treat as Income', Lunch Money will separate them appropriately from expenses."),
                React.createElement("p", null, "To fix this issue, update at least one of your categories to be 'Treat as Income'."),
                React.createElement(semantic_ui_react_1.Button, { size: "mini", icon: true, labelPosition: "left", onClick: () => {
                        window.open('https://support.lunchmoney.app/setup/categories#category-properties');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                    "Read more about Category Properties")))),
        React.createElement("div", { className: "width-100 flex--space-between-flex-end" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowNewCategory('individual');
                    } }, "Add new category"),
                React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                        setShowModal(true);
                        setModalView('ADD_CATEGORY_GROUP');
                    } }, "Add category group")),
            ((_a = _assets.plaidAccounts.filter(o => o.status == 'active' || o.status == 'relink')) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "right", onClick: () => {
                    history.push('/categories/auto');
                } },
                "Set autocategorizations",
                React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))),
        React.createElement("div", { className: "table-container mt-1rem" },
            React.createElement(react_beautiful_dnd_1.DragDropContext, { onDragUpdate: results => {
                    return handleDrag(results, 'onDragUpdate');
                }, onDragEnd: results => {
                    return handleDrag(results, 'onDragEnd');
                } },
                React.createElement(semantic_ui_react_1.Table, { fixed: true, selectable: true, celled: true, unstackable: true, className: "p-categories-table" },
                    React.createElement(semantic_ui_react_1.Table.Header, { className: "sticky" },
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-name", style: {
                                    width: displayNameWidth ? `${displayNameWidth}px` : 'auto',
                                } }, "Display Name"),
                            React.createElement(ColumnResizer_1.default, { className: "columnResizer", tableId: 'categories', prevCol: 'display-name', nextCol: 'description' }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-description", style: {
                                    width: descriptionWidth ? `${descriptionWidth}px` : 'auto',
                                } }, "Description"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align table-cell-matches" }, "Treat as income"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align table-cell-matches" }, "Exclude from budget"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align table-cell-matches" }, "Exclude from totals"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "td-resize no-hover" }),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "table-cell-settings" }))),
                    React.createElement(semantic_ui_react_1.Table.Body, null,
                        !!showNewCategory && (React.createElement(NewCategoryRow_1.default, { onSave: _createCategory, showNewCategory: showNewCategory })),
                        !isLoading && categoryRows.length === 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "g-empty-row no-hover-tr" },
                            React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 15, className: "center-align" },
                                React.createElement("div", { className: "empty-mascot" }),
                                React.createElement("p", null, "You have no categories yet!"),
                                React.createElement("p", null,
                                    "Get started by clicking",
                                    ' ',
                                    React.createElement(semantic_ui_react_1.Button, { size: "mini", className: "border-button", onClick: () => {
                                            setShowNewCategory('individual');
                                        } }, "Add a new category"),
                                    ' ',
                                    "above ",
                                    React.createElement("br", null),
                                    "or by typing into any category dropdown!")))),
                        isLoading && React.createElement(Loader_1.default, { colSpan: 15 })),
                    React.createElement(react_beautiful_dnd_1.Droppable, { droppableId: "table" }, (provided, snapshot) => {
                        return (React.createElement(semantic_ui_react_1.Ref, { innerRef: provided.innerRef },
                            React.createElement(semantic_ui_react_1.Table.Body, Object.assign({}, provided.droppableProps, { className: !isLoading && categoryRows.length === 0
                                    ? 'g-empty-body'
                                    : '' }),
                                categoryRows,
                                " ",
                                provided.placeholder)));
                    }),
                    React.createElement(semantic_ui_react_1.Table.Body, null,
                        !isLoading && archived.length > 0 && (React.createElement(semantic_ui_react_1.Table.Row, { className: "no-hover-tr" },
                            React.createElement(semantic_ui_react_1.Table.Cell, { style: { borderTop: '1px solid #dcdcdc' }, colSpan: 15, className: "row-padding center-align clickable color--grey", onClick: () => {
                                    setShowArchived(!showArchived);
                                } }, showArchived ? (React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "caret up" }),
                                " Hide archived categories")) : (React.createElement("span", null,
                                React.createElement(semantic_ui_react_1.Icon, { name: "caret right" }),
                                " Show archived categories (",
                                archived.length,
                                ")"))))),
                        showArchived &&
                            archived.map((category, index) => {
                                const IS_PART_OF_GROUP = !!category.group_id;
                                const PARENT_IS_ARCHIVED = !!archived.find(o => o.id == category.group_id);
                                return (React.createElement(semantic_ui_react_1.Table.Row, { key: `archived-${index}` },
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-name` },
                                        React.createElement("div", { className: category.is_group ||
                                                (IS_PART_OF_GROUP && PARENT_IS_ARCHIVED)
                                                ? `category-row-has-icon`
                                                : '' },
                                            IS_PART_OF_GROUP && PARENT_IS_ARCHIVED && (React.createElement("span", { className: "hierarchy-line-icon" })),
                                            category.is_group && React.createElement(semantic_ui_react_1.Icon, { name: 'table' }),
                                            React.createElement(EditableString_1.default, { identifier: `category-${category.id}`, location: 'inline', firstValue: `${category.name} ${IS_PART_OF_GROUP && !PARENT_IS_ARCHIVED
                                                    ? `(${_categories.getName(category.group_id)})`
                                                    : ''}`, state: 'ReadOnly' }))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: `table-cell-description hover-toggle` },
                                        React.createElement(EditableString_1.default, { identifier: `category-description-${category.id}`, location: 'inline', firstValue: category.description, state: 'ReadOnly' })),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                    category.group_id ? (React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 5 },
                                        React.createElement("span", { className: "inherited" }, "Inherited from group"))) : (React.createElement(React.Fragment, null,
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches" }, category.is_income && React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" })),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches" }, category.exclude_from_budget && (React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }))),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-matches" }, category.exclude_from_totals && (React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }))))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 3, className: "center-align" }, category.is_group ||
                                        !category.group_id ||
                                        (IS_PART_OF_GROUP && !PARENT_IS_ARCHIVED) ? (React.createElement(semantic_ui_react_1.Button, { size: "tiny", color: "yellow", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield _process(categories_1.updateCategory)(category.id, {
                                                archived: false,
                                                archived_on: null,
                                            });
                                            yield _categories.fetchCategories(); // Refresh it
                                            _showToast({
                                                message: `Successfully un-archived category ${category.is_group ? 'group' : ''}`,
                                                type: 'success',
                                            });
                                        }) }, "Unarchive")) : (React.createElement(React.Fragment, null))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "td-divider" }),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align clickable", onClick: () => {
                                            setModalData(category);
                                            setShowModal(true);
                                            setModalView('EDIT_CATEGORY');
                                        } },
                                        React.createElement(semantic_ui_react_1.Icon, { fitted: true, name: "angle right", className: `clickable green-on-hover color--grey` }))));
                            }))))),
        showModal && (React.createElement(NewModal_1.default, { show: showModal, setShow: (show) => {
                setShowModal(show);
            }, data: {
                category: modalData,
            }, utils: {
                _process: _process,
                _showToast: _showToast,
            }, view: modalView, closeCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                setModalData(null);
            }) })),
        React.createElement(PopupTransactions_1.default, { openTransactionsTable: openTransactionsTable && (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id), onClose: () => __awaiter(void 0, void 0, void 0, function* () {
                setSelectedCategory(null);
                setOpenTransactionsTable(false);
            }), headerText: `All transactions categorized ${selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.name}`, query: {
                time: 'all',
                match: 'all',
                category: selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id,
            }, _process: _process, _showToast: _showToast, _removeToast: _removeToast })));
};
exports.default = Categories;
