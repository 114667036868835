"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  Connect.tsx
 */
/* cspell:ignore cryptocurrenc */
const React = require("react");
const _ = require("lodash");
const EditableString_1 = require("@components/elements/EditableString");
const react_1 = require("react");
const crypto_1 = require("@actions/crypto");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const Connect = ({ _process, _showToast, defaultName = null, show, closeModal, }) => {
    // Step 1: Selected provider
    const [selected, setSelected] = (0, react_1.useState)(null);
    // Step 2: Authentication keys
    const [authKeys, setAuthKeys] = (0, react_1.useState)({});
    // Step 3: Results from server
    const [initializeResults, setInitializeResults] = (0, react_1.useState)(null);
    const newCryptoAccountId = (0, react_1.useRef)({});
    // Step 4: Selected excluded currencies
    const [excluded, setExcluded] = (0, react_1.useState)({});
    // View settings
    const [showZeroBalances, setShowZeroBalances] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    // Merging with existing
    const currentZaboAccounts = (0, react_1.useRef)({});
    const [selectedZaboAccount, setSelectedZaboAccount] = (0, react_1.useState)(null);
    const [unmatchedZabo, setUnmatchedZabo] = (0, react_1.useState)(null);
    //
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const generateCurrentZaboAccounts = () => {
        const mapping = {};
        // Create a mapping of existing Zabo accounts
        _assets.crypto.reverse().forEach(account => {
            if (!mapping.hasOwnProperty(account.institution_name)) {
                mapping[account.institution_name] = {};
            }
            if (!mapping[account.institution_name].hasOwnProperty(account.id)) {
                mapping[account.institution_name][account.id] = [];
            }
            mapping[account.institution_name][account.id].push({
                provider: account.institution_name,
                linked_by_name: account.linked_by_name,
                zabo_account_id: account.id,
                balance: account.balance,
                currency: account.currency.toUpperCase(),
                display_name: account.display_name,
            });
        });
        return mapping;
    };
    (0, react_1.useEffect)(() => {
        currentZaboAccounts.current = generateCurrentZaboAccounts();
    }, []);
    (0, react_1.useEffect)(() => {
        if (defaultName) {
            setSelected(INTEGRATIONS.find(o => o.name == defaultName));
        }
    }, [defaultName]);
    const INTEGRATIONS = [
        {
            name: 'Coinbase',
            id: 'coinbase',
            image: 'crypto-coinbase.png',
            instructions: (React.createElement("p", null,
                React.createElement("ol", null,
                    React.createElement("li", null,
                        "In Coinbase, navigate to",
                        ' ',
                        React.createElement("a", { href: "https://www.coinbase.com/settings/api", target: "_blank" }, "API keys"),
                        ' ',
                        "via Settings."),
                    React.createElement("li", null, "Click on \"+ New API Key\" at the top right corner"),
                    React.createElement("li", null, "Select the account(s) you want tracked. You can also select \"all\" and Lunch Money will automatically filter out zero-balance accounts."),
                    React.createElement("li", null,
                        "Under \"API v2 permissions\", select only",
                        ' ',
                        React.createElement("b", null, "wallet:accounts:read"),
                        ". This gives permission to read your account details to import into Lunch Money.")))),
            fields: [
                { label: 'API Key', key: 'apiKey' },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    protected: true,
                },
            ],
        },
        {
            name: 'Coinbase Pro',
            id: 'coinbase-pro',
            image: 'crypto-coinbase-pro.png',
            instructions: (React.createElement("p", null,
                React.createElement("ol", null,
                    React.createElement("li", null,
                        "In Coinbase Pro, navigate to",
                        ' ',
                        React.createElement("a", { href: "https://pro.coinbase.com/profile/api", target: "_blank" }, "API"),
                        ' ',
                        "by clicking your account picture"),
                    React.createElement("li", null, "Click on \"+ New API Key\""),
                    React.createElement("li", null, "Type in a label for your API Key (for example, Lunch Money)"),
                    React.createElement("li", null,
                        "Under \"Permissions\", select only ",
                        React.createElement("b", null, "View"),
                        ". This gives permission to read your account details to import into Lunch Money."),
                    React.createElement("li", null, "Record your passphrase - you won\u2019t see it again"),
                    React.createElement("li", null, "Leave IP Whitelist blank"),
                    React.createElement("li", null, "Click \"Create API Key\"")))),
            fields: [
                { label: 'API Key', key: 'apiKey' },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    protected: true,
                },
                {
                    label: 'Passphrase',
                    key: 'passphrase',
                    protected: true,
                },
            ],
        },
        {
            name: 'Ethereum',
            id: 'ethereum',
            image: 'crypto-ethereum.png',
            fields: [
                {
                    label: 'Wallet address',
                    key: 'walletAddress',
                },
            ],
        },
        {
            name: 'Kraken',
            id: 'kraken',
            image: 'crypto-kraken.png',
            fields: [
                { label: 'API Key', key: 'apiKey' },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    protected: true,
                },
            ],
            instructions: (React.createElement("p", null,
                React.createElement("ol", null,
                    React.createElement("li", null,
                        "In Kraken, navigate to",
                        ' ',
                        React.createElement("a", { href: "https://www.kraken.com/u/security/api", target: "_blank" }, "API"),
                        ' ',
                        "via Account, Security."),
                    React.createElement("li", null, "Click on \"Add key\" at the top right corner."),
                    React.createElement("li", null,
                        "Select only ",
                        React.createElement("b", null, "Query Funds"),
                        " under Key Permissions and click \"Generate key\".")))),
        },
        {
            name: 'Binance',
            id: 'binance',
            image: 'crypto-binance.png',
            instructions: (React.createElement("p", null,
                React.createElement("ol", null,
                    React.createElement("li", null,
                        "In Binance, navigate to",
                        ' ',
                        React.createElement("a", { href: "https://www.binance.com/en/my/settings/api-management", target: "_blank" }, "API Management"),
                        ' ',
                        "via Settings."),
                    React.createElement("li", null, "Type in a label for your API Key (for example, Lunch Money) and click \"Create API\""),
                    React.createElement("li", null,
                        "Under API restrictions, ensure only ",
                        React.createElement("b", null, "Enable Reading"),
                        " is checked.")))),
            fields: [
                { label: 'API Key', key: 'apiKey' },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    protected: true,
                },
            ],
        },
    ];
    // Returns 1. the list of newBalances hydrated with a match and 2. list of
    // currencies that were unmatched
    const detectPairs = (newBalances, zaboBalances = null) => {
        // If empty zaboBalances is passed in, then we are going to try to make a
        // best guess of which Zabo Account is associated
        if (zaboBalances == null) {
            if (!currentZaboAccounts.current.hasOwnProperty(selected.name)) {
                // There isn't currently a Zabo account with the same provider,
                // so just exit with no matches
                setSelectedZaboAccount(null);
                return newBalances;
            }
            // Get all current Zabo connections with the same provider
            const currentConnections = Object.values(currentZaboAccounts.current[selected.name]);
            if (currentConnections.length == 1) {
                // If there is only one Zabo connection with this provider, then
                // automatically select this if there are currency matches.
                const numMatch = _.intersection(Object.keys(_.groupBy(newBalances, 'currency')), Object.keys(_.groupBy(currentConnections[0], 'currency'))).length;
                if (numMatch > 1) {
                    setSelectedZaboAccount(currentConnections[0][0]['zabo_account_id']);
                    zaboBalances = currentConnections[0];
                }
            }
            else {
                // If there is more than Zabo connection, then try to make a best guess
                // based on intersection of currencies
                let bestMatch = { count: 0, id: null, connections: null };
                const newByCurrency = _.groupBy(newBalances, 'currency');
                // Go through each provider connection
                currentConnections.forEach(connections => {
                    const zaboByCurrency = _.groupBy(connections, 'currency');
                    const numMatch = _.intersection(Object.keys(newByCurrency), Object.keys(zaboByCurrency)).length;
                    if (numMatch > bestMatch.count) {
                        bestMatch = {
                            count: numMatch,
                            id: connections[0].zabo_account_id,
                            connections,
                        };
                    }
                });
                if (bestMatch.id) {
                    setSelectedZaboAccount(bestMatch.id);
                    zaboBalances = bestMatch.connections;
                }
                else {
                    // More than one connection, but nothing matches setSelectedZaboAccount(null)
                    setSelectedZaboAccount(null);
                    return newBalances;
                }
            }
        }
        // Match new balance with Zabo balance
        const zaboByCurrency = _.groupBy(zaboBalances, 'currency');
        const matched = newBalances.map(newBalance => {
            if (zaboByCurrency[newBalance.currency.toUpperCase()]) {
                newBalance.match = zaboByCurrency[newBalance.currency.toUpperCase()][0];
                delete zaboByCurrency[newBalance.currency.toUpperCase()];
            }
            else {
                newBalance.match = null;
            }
            return newBalance;
        });
        setUnmatchedZabo(Object.values(zaboByCurrency).map(o => o[0]));
        return matched;
    };
    const reset = (close = true) => {
        if (close) {
            closeModal();
        }
        setInitializeResults(null);
        setExcluded({});
        setAuthKeys({});
        setSelected(null);
        setShowZeroBalances(false);
        setSelectedZaboAccount(null);
        setUnmatchedZabo(null);
        newCryptoAccountId.current = null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Modal, { open: show, size: "small" },
            React.createElement(semantic_ui_react_1.Modal.Header, null, defaultName && selected
                ? `Migrate my ${selected === null || selected === void 0 ? void 0 : selected.name} connection`
                : 'Connect my crypto'),
            !initializeResults ? (React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { id: "g-integrations" },
                    React.createElement(semantic_ui_react_1.Message, { info: true }, defaultName && selected ? (React.createElement("p", null,
                        "We are in the process of migrating our crypto connections. To read more about why,",
                        ' ',
                        React.createElement("a", { href: "https://eomail5.com/web-version?p=d9fd1f9f-fb99-11eb-96e5-06b4694bee2a&pt=campaign&t=1628795735&s=3e141db83fa4cea08a716070a5b304ba06365bce5e2a240e49531b217b297d78", target: "_blank", className: "link clickable" }, "click here"),
                        ". Migrate your ", selected === null || selected === void 0 ? void 0 :
                        selected.name,
                        " connection before",
                        ' ',
                        React.createElement("b", null, "September 10, 2021"),
                        " to avoid disruption in service.")) : ('By connecting your wallets and accounts, we will continuously track your cryptocurrency holdings and show their balances as part of your net worth. At this time, we are not importing transactions and we are working on supporting more integrations.')),
                    React.createElement("div", { className: "integrations-container" }, defaultName && selected ? (React.createElement("div", { className: `crypto-integration-box selected` },
                        React.createElement("img", { src: `/public/images/${selected === null || selected === void 0 ? void 0 : selected.image}` }))) : (INTEGRATIONS.map(integration => {
                        return (React.createElement("div", { key: `crypto-integration-${integration.id}`, className: `crypto-integration-box ${selected == null
                                ? ''
                                : (selected === null || selected === void 0 ? void 0 : selected.id) == integration.id
                                    ? 'selected'
                                    : 'not-selected'} ${(integration === null || integration === void 0 ? void 0 : integration.fields) ? '' : 'disabled'}`, onClick: () => {
                                if (INTEGRATIONS.find(o => o.id == integration.id)
                                    .fields) {
                                    setAuthKeys({}); // Reset
                                    setSelected(integration.id == (selected === null || selected === void 0 ? void 0 : selected.id)
                                        ? null
                                        : INTEGRATIONS.find(o => o.id == integration.id));
                                }
                            } },
                            React.createElement("img", { src: `/public/images/${integration.image}` }),
                            Object.keys(currentZaboAccounts.current).indexOf(integration.name) > -1 && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, position: "bottom left", size: "small", trigger: React.createElement("div", { className: "recommended" },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "exclamation circle", color: "yellow" })) },
                                "You currently have a ",
                                integration.name,
                                " connection that needs to be migrated."))));
                    }))),
                    selected && (React.createElement(React.Fragment, null,
                        selected.instructions && (React.createElement(semantic_ui_react_1.Message, { warning: true }, selected.instructions)),
                        React.createElement(semantic_ui_react_1.Form, { autocomplete: "off" },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" }, selected.fields.map(field => {
                                return (React.createElement(semantic_ui_react_1.Form.Field, { key: field.key },
                                    React.createElement("label", null, field.label),
                                    React.createElement(EditableString_1.default, { identifier: field.key, isPassword: field.protected, firstValue: authKeys[field.key], shouldSaveOnChange: true, state: 'Editing', autoComplete: false, location: 'modal', onSave: value => {
                                            setAuthKeys(Object.assign(Object.assign({}, authKeys), { [field.key]: value }));
                                        } })));
                            })))))))) : (React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("div", { className: "content" },
                    React.createElement("h3", null, "Which cryptocurrency balances should we import?"),
                    React.createElement("p", null,
                        "We identified ",
                        initializeResults.length,
                        " total cryptocurrenc",
                        initializeResults.length == 1 ? 'y' : 'ies',
                        " in this",
                        ' ', selected === null || selected === void 0 ? void 0 :
                        selected.name,
                        " account:"), initializeResults === null || initializeResults === void 0 ? void 0 :
                    initializeResults.filter(o => {
                        return o.balance != 0;
                    }).sort((a, b) => {
                        return Number(b.balance) - Number(a.balance);
                    }).map(balance => {
                        return (React.createElement("div", { className: "display--flex", key: `checkbox-${balance.currency}` },
                            React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !excluded[balance.currency], onChange: (e, { checked }) => {
                                    setExcluded(Object.assign(Object.assign({}, excluded), { [balance.currency]: !checked }));
                                }, label: (0, format_1.toCrypto)(balance.balance, balance.currency) }),
                            !!balance.match && (React.createElement("div", { className: "ml-05rem" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green", fitted: true })))));
                    }),
                    !!initializeResults.find(o => o.balance == 0) && (React.createElement("p", { className: "clickable link mt-1rem", onClick: () => {
                            setShowZeroBalances(!showZeroBalances);
                        } },
                        showZeroBalances ? 'Hide' : 'Show',
                        " cryptocurrencies with zero balances (", initializeResults === null || initializeResults === void 0 ? void 0 :
                        initializeResults.filter(o => {
                            return o.balance == 0;
                        }).length,
                        ")")),
                    showZeroBalances &&
                        (initializeResults === null || initializeResults === void 0 ? void 0 : initializeResults.filter(o => {
                            return o.balance == 0;
                        }).sort((a, b) => {
                            return a.currency.localeCompare(b.currency);
                        }).map(balance => {
                            return (React.createElement("div", { key: `checkbox-${balance.currency}` },
                                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: !excluded[balance.currency], onChange: (e, { checked }) => {
                                        setExcluded(Object.assign(Object.assign({}, excluded), { [balance.currency]: !checked }));
                                    }, label: (0, format_1.toCrypto)(balance.balance, balance.currency) })));
                        })),
                    selectedZaboAccount && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-1rem" },
                            React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green", fitted: true }),
                            '  ',
                            "We detected an existing ",
                            selected.name,
                            " crypto connection that can be merged with this one."),
                        React.createElement(semantic_ui_react_1.Message.Content, null,
                            React.createElement("p", null,
                                "Display names and balance histories will be automatically merged and the previous crypto connection will be removed.",
                                !(unmatchedZabo === null || unmatchedZabo === void 0 ? void 0 : unmatchedZabo.length) &&
                                    ' No further action is needed.'),
                            !!(unmatchedZabo === null || unmatchedZabo === void 0 ? void 0 : unmatchedZabo.length) && (React.createElement("p", null, "However, we could not find a match for the following cryptocurrencies which means we won't be able to sync new balances, though old balance history will be saved. Did you miss permissions? Click 'Back' to start over.")), unmatchedZabo === null || unmatchedZabo === void 0 ? void 0 :
                            unmatchedZabo.sort((a, b) => {
                                return a.currency.localeCompare(b.currency);
                            }).map(balance => {
                                return (React.createElement("li", { key: `unmatched-${balance.currency}` }, (0, format_1.toCrypto)(balance.balance, balance.currency)));
                            }),
                            React.createElement("p", { className: "link clickable color--red mt-1rem", onClick: () => {
                                    setSelectedZaboAccount(null);
                                    setUnmatchedZabo(null);
                                } },
                                React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "yellow", fitted: true }),
                                '  ',
                                "Not the same ",
                                selected.name,
                                " account? Click here to skip merging."))))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                !initializeResults ? (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        reset();
                    }) }, "Close")) : (React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        yield _process(crypto_1.removeSynced)(newCryptoAccountId.current, {
                            keep_history: false,
                        });
                        setIsLoading(false);
                        reset(false);
                    }) }, "Back")),
                selected && !initializeResults && (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: isLoading ||
                        Object.values(authKeys).length < selected.fields.length, content: `Connect to ${selected.name}`, icon: "right arrow", loading: isLoading, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        const results = yield _process(crypto_1.initialize)({
                            authKeys,
                            provider: selected.id,
                        });
                        if (results.data.results) {
                            setInitializeResults(detectPairs(results.data.results.balances));
                            newCryptoAccountId.current =
                                results.data.results.crypto_account_id;
                            // Are any of the balances 0?
                            const initialExcluded = {};
                            results.data.results.balances.forEach(balance => {
                                if (balance.balance == 0) {
                                    initialExcluded[balance.currency] = true;
                                }
                            });
                            setExcluded(initialExcluded);
                            setIsLoading(false);
                        }
                        else {
                            _showToast({
                                message: results.data.error_message ||
                                    'Missing data, please try again.',
                                type: 'error',
                            });
                            setIsLoading(false);
                        }
                    }) })),
                initializeResults && (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setIsLoading(true);
                        // Merge with Zabo account
                        if (selectedZaboAccount) {
                            yield _process(crypto_1.mergeWithZabo)(newCryptoAccountId.current, selectedZaboAccount);
                        }
                        // Save exclusions
                        const exclude = [...(unmatchedZabo || []).map(o => o.currency)];
                        Object.keys(excluded).forEach(currency => {
                            if (excluded[currency]) {
                                exclude.push(currency);
                            }
                        });
                        if (!!exclude.length) {
                            yield _process(crypto_1.updateSynced)(newCryptoAccountId.current, {
                                excluded_currencies: exclude,
                            });
                        }
                        // Get latest balances
                        yield _process(crypto_1.refreshSynced)(newCryptoAccountId.current);
                        _showToast({
                            message: `Import successful. If you don't see your new accounts right away, hit the refresh button.`,
                            type: 'success',
                        });
                        setIsLoading(false);
                        reset();
                    }) }, "Start Importing"))))));
};
exports.default = Connect;
